<template>
    <div class="container-fluid archives-container">
        <div class="row mt-5 mb-2" v-if="news.length > 0">
            <div class="col">
                <h3>Bevorstehende Livestreams</h3>
            </div>
        </div>
        <div class="row mb-5" v-if="news.length > 0">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6 upcoming-livestream mt-2 p-2" v-for="item in news" :key="item.id">
                <div class="d-flex justify-content-between px-2">
                    <div class="livestream-info">
                        <h4>
                            {{ item.title }}
                        </h4>
                        <p v-html="item.description"></p>
                    </div>
                    <div class="livestream-date">
                        <p>
                            {{ formatDateTime(item.live_at) }}
                        </p>
                    </div>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <input-component
                    name="search"
                    id="search"
                    label=""
                    placeholder="Suchbegriff eingeben"
                    type="text"
                    :value="input.search"
                    v-model="input.search"
                    @input="loadArchivesBySearch"
                />

            </div>
            <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <br/>
                <select-component
                    :options="input.selectTopicOptions"
                    :showLabel="false"
                    :value="input.selectedTopic"
                    v-model="input.selectedTopic"
                    label=""
                />
            </div>
        </div>

        <br/><br/>
        <div class="row">
            <template v-for="archive in archives">
                <video-component :title="archive.title"
                                 :image="archive.thumbnail === '' ? 'https://www.pharmanavi.de/assets/images/live_stream_placeholder.jpg' : 'https://assets.pharmanavi.de/assets/uploads/Streaming/' + archive.thumbnail"
                                 :description="archive.description"
                                 :link="'/archive/' + archive.id"
                                 :itemsCount="archives.length"
                />
            </template>
        </div>

        <div class="row">
            <div class="col-lg-6 previous-btn">
                <button v-if="input.skip > 0" v-on:click="previousArchives" class="btn btn-secondary-outline"><i class="fa-solid fa-arrow-left"></i> Neuer</button>
            </div>
            <div class="col-lg-6 next-btn">
                <button v-if="archives.length === 12" class="btn btn-secondary-outline" v-on:click="nextArchives">Älter <i class="fa-solid fa-arrow-right"></i></button>
            </div>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import InputComponent from '@client/components/InputComponent.vue';
import SelectComponent from '@client/components/SelectComponent.vue';
import VideoComponent from '@client/components/VideoComponent.vue';
import AccordionComponent from '@client/components/AccordionComponent.vue';

export default {
    name: "Community",
    data() {
        return {
            archives: [],
            news: [],
            input: {
                search: "",
                selectTopicOptions: [
                    {
                        text: "Filter nach Thema aufgeschlüsselt",
                        value: 0,
                    }
                ],
                selectedTopic: 0,
                skip: 0
            }
        }
    },
    beforeMount() {
        this.loadArchiveTopic();
        this.loadUpcomingNews();
        this.loadArchives();
    },
    methods: {
        loadArchiveTopic() {
            axios.get(`${API_URL}archives/get_archive_topics`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        response.data.topics.forEach((topic) => {
                            this.input.selectTopicOptions.push({
                                value: topic.id,
                                text: topic.wording
                            });
                        });
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        loadArchives() {
            var searchString = this.input.search === "" ? "no_search" : this.input.search;
            axios.get(`${API_URL}archives/search/${this.input.selectedTopic}/${this.input.skip}/${searchString}`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.archives = response.data.archives;
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        loadArchivesBySearch() {
            this.input.skip = 0;
            this.loadArchives();
        },
        loadArchivesByTopic() {
            this.input.skip = 0;
            this.loadArchives();
        },
        previousArchives() {
            if (this.input.skip === 0)
                return;

            this.input.skip -= 12;

            this.loadArchives();
        },
        nextArchives() {
            this.input.skip += 12;

            this.loadArchives();
        },
        loadUpcomingNews() {
            axios.get(`${API_URL}news/upcoming`, {'headers': authHeader()})
            .then(response => {
                if (response.status === 200) {
                    this.news = response.data.news
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        formatDateTime(dateString) {
            const date = new Date(dateString);
            const timeFormat = date.toLocaleTimeString('de-DE', {
                hour: '2-digit',
                minute: '2-digit',
            });

            // Combine formatted date and time, separated by a space
            const germanFormat = date.toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }) + ' ' + timeFormat;

            return germanFormat
        },
    },
    watch: {
        'input.selectedTopic': function (newTopic, oldTopic) {
            this.loadArchivesByTopic();
        }
    },
    components: {
        InputComponent,
        SelectComponent,
        VideoComponent,
        AccordionComponent,
    }
}
</script>

<style scoped>

</style>
