<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col">
        <h1>Statistic</h1>
      </div>
    </div>
    <div class="row mx-5">
      <div class="col-2">
        <stats-box :current-value="42" stat-label="Insgesamt" />
      </div>
      <div class="col-2">
        <stats-box :current-value="63.3" stat-label="Thema" />
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <select-component
          :options="durations"
          label="Zeitrum"
          :value="selectedDuration"
          v-model="selectedDuration"
        />
      </div>
    </div>
    <div class="row">
      <div class="col" id="chart"></div>
    </div>
  </div>
</template>

<script>

import SelectComponent from '@client/components/SelectComponent.vue';
import StatsBox from '@client/components/StatsBox.vue'
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import * as d3 from "d3";
export default {
  name: 'stats',
  data() {
    return {
      values: 30,
      stats: {},
      durations: [
        {
          text: 'Letzte sieben Tage',
          value: 7,
        },
        {
          text: 'Letzte 30 Tage',
          value: 30,
        },
        {
          text: 'Seit Beginn',
          value: 0,
        }
      ],
      selectedDuration: 7,
    }
  },
  mounted() {
    this.generateChart();
  },
  methods: {
    generateChart() {
      
      // set the dimensions and margins of the graph
      var margin = {top: 40, right: 30, bottom: 30, left: 40},
        width = 800 - margin.left - margin.right,
        height = 600 - margin.top - margin.bottom;

      // append the svg object to the body of the page
      var svg = d3.select("#chart")
        .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
        .append("g")
          .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");

      axios.get(`${API_URL}stats`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          const statistics = response.data.stats

          const groupedStats =  d3.group(statistics, d => d.scope) 

          // Add the X axis --> It is a day format
          const x = d3.scaleBand()
            .domain(statistics.map( (d) => d.day ))
            .rangeRound([0, width])
            .paddingInner(0.05)
            .paddingOuter(0.05)
            .align(0.1);
          svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(d3.axisBottom(x));

          // Add the Y axis
          const y =d3.scaleLinear()
            .domain([0, d3.max(statistics, (d) => +d.answered )])
            .range([ height, 0]);
          svg.append("g")
            .call(d3.axisLeft(y));

          const color = d3.scaleOrdinal()
            .range(['#CB4099','#891E35']);
          
          // Draw the lines
          svg.selectAll(".line")
              .data(groupedStats)
              .join("path")
                .attr("fill", "none")
                .attr("stroke", d => color(d[0]))
                .attr("stroke-width", 1.5)
                .attr("d", function(d){
                  return d3.line()
                    .curve(d3.curveBasis)
                    .x(function(d) { return d.day == 'Mo' ? x(d.day) : x(d.day) + (x.bandwidth()/2); })
                    .y(function(d) { return y(+d.answered); })
                    .defined(d => d.answered !== null)
                    (d[1])
                });

          const barWidth = 20
          // Create the bars
          svg.selectAll('.bar')
            .data(groupedStats.get('selected'))
            .enter().append('rect')
            .attr('class', 'bar')
            .attr('x', function(d){return x(d.day) + x.bandwidth()/2 - (barWidth/2); })
            .attr('width', barWidth)
            .attr('y', function(d){return y(d.correct); })
            .attr("height", function(d) { return height - y(d.correct); })
            .attr("title", function(d) { return `Select Topics Performance Answered: ${d.answered} Correct: ${d.answered}`; })
            .attr('fill', d => color(d.scope));

          const legend = svg.append('g')
            .attr('transform', `translate(0, -20)`);

          legend.selectAll('rect')
            .data(groupedStats)
            .enter().append('rect')
              .attr('x', 10)
              .attr('y', (d, i) => i * 20)
              .attr('width', 10)
              .attr('height', 10)
              .attr('fill', d => color(d[0]));

          legend.selectAll('text')
            .data(groupedStats)
            .enter().append('text')
              .attr('x', 30)
              .attr('y', function(d, i){ console.log(i); return i > 0 ?  i * 30 : 10; })
              .text(d => d[0] == 'selected' ? "Questions from selected topics" : "All questions answered");

          legend.append("text")
            .text("Bars: Correctly answered from selected topics")
            .attr("x", 10)
            .attr("y", 50)

        }
      })
      .catch(error => {
        console.log(error)
      });
    }
  },
  components: {
    SelectComponent,
    StatsBox,
  }
}
</script>