<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="plant_family.name !== undefined && plant_family.name !== null && plant_family.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{plant_family.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_family.latin_name !== undefined && plant_family.latin_name !== null && plant_family.latin_name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">lateinischer Name</h6>
                    </div>
                    <div><p>{{ plant_family.latin_name }}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_family.description !== undefined && plant_family.description !== null && plant_family.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="plant_family.description"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_family.pictures !== undefined && plant_family.pictures !== null && plant_family.pictures !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <component :is="pictures"></component>
<!--                    <div @click="handleImageClick" v-html="plant_family.pictures"></div>-->
                    <br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';

export default {
    name: "PlantFamily",
    data() {
        return {
            plant_family: {
                id: 0,
                name: '',
                latin_name: '',
                pictures: '',
                description: '',
            },
        }
    },
    mounted() {
        this.loadPlantFamily();
    },
    methods: {
        clearData() {
            this.plant_family.id = 0;
            this.plant_family.name = "";
            this.plant_family.latin_name = "";
            this.plant_family.description = "";
            this.plant_family.pictures = '';
        },
        loadPlantFamily() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}plant_families/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setPlantFamilyData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setPlantFamilyData(data) {
            this.plant_family.id = data.id;
            this.plant_family.name = data.name;
            this.plant_family.latin_name = data.latin_name;
            this.plant_family.description = data.description;
            this.plant_family.pictures = data.pictures;
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        pictures() {
            return {
                template: '<div>'+ this.addImageZoom(this.plant_family.pictures) + '</div>'
            }
        },
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.plant_family.description) + '</div>'
            }
        }
    },
    components: {
        ImageComponent
    }
}
</script>

<style scoped>

</style>
