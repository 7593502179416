<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="plant_wirkstoff.name !== undefined && plant_wirkstoff.name !== null && plant_wirkstoff.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{plant_wirkstoff.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_wirkstoff.description !== undefined && plant_wirkstoff.description !== null && plant_wirkstoff.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="plant_wirkstoff.description"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_wirkstoff.formula !== undefined && plant_wirkstoff.formula !== null && plant_wirkstoff.formula !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Summenformel</h6>
                    </div>
                    <component :is="formula"></component>
<!--                    <div @click="handleImageClick" v-html="plant_wirkstoff.formula"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_wirkstoff.molecular_weight !== undefined && plant_wirkstoff.molecular_weight !== null && plant_wirkstoff.molecular_weight !== '' && parseFloat(plant_wirkstoff.molecular_weight) !== 0">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Molekulargewicht</h6>
                    </div>
                    <div><p>{{ this.formatNumber(plant_wirkstoff.molecular_weight) }} {{plant_wirkstoff.molecular_weight_unit}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_wirkstoff.warnings.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Warnstufen</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="warning in plant_wirkstoff.warnings"
                                     :key="'warning'+ warning.warning.id"
                                     :to="{ name: 'Warning', params: {id: warning.warning.id} }">{{ warning.warning.name }}
                        </router-link>
                    </div>
                    <br/><br/>
                </div>

                <div class="row" v-if="plant_wirkstoff.protections.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte H+P-Sätze</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="protection in plant_wirkstoff.protections"
                                     :key="'protection'+ protection.protection.id"
                                     :to="{ name: 'Protection', params: {id: protection.protection.id} }">{{ protection.protection.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';

export default {
    name: "PlantWirkstoff",
    data() {
        return {
            plant_wirkstoff: {
                id: 0,
                name: '',
                formula: '',
                description: '',
                warnings: [],
                protections: [],
                molecular_weight: 0.0,
                molecular_weight_unit: ''
            }
        }
    },
    mounted() {
        this.loadPlantWirkstoff();
    },
    methods: {
        clearData() {
            this.plant_wirkstoff.id = 0;
            this.plant_wirkstoff.name = "";
            this.plant_wirkstoff.description = "";
            this.plant_wirkstoff.formula = '';
            this.plant_wirkstoff.protections = [];
            this.plant_wirkstoff.warnings = [];
        },
        loadPlantWirkstoff() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}plant_wirkstoffs/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setPlantWirkstoffData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setPlantWirkstoffData(data) {
            this.plant_wirkstoff.id = data.id;
            this.plant_wirkstoff.name = data.name;
            this.plant_wirkstoff.description = data.description;
            this.plant_wirkstoff.formula = data.formula;
            this.plant_wirkstoff.molecular_weight = data.molecular_weight;
            this.plant_wirkstoff.molecular_weight_unit = data.molecular_weight_u === null ? "" : data.molecular_weight_u.value;
            this.plant_wirkstoff.protections = data.protections;
            this.plant_wirkstoff.warnings = data.warnings;
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.plant_wirkstoff.description) + '</div>'
            }
        },
        formula() {
            return {
                template: '<div>'+ this.addImageZoom(this.plant_wirkstoff.formula) + '</div>'
            }
        }
    },
    components: {
        ImageComponent
    }
}
</script>

<style scoped>

</style>
