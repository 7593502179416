<template>
  <div style="padding: 10px">
    <!-- <h4 class="text-center text-danger">
      Hier kannst du demnächst die Schwarmintelligenz aller PharmaNavi-User anzapfen. Du kommst mit einem Problem einfach gerade nicht weiter? In einer Zusammenfassung oder Frage bei uns findest du etwas komisch? Poste es hier und wir können es demnächst alle gemeinsam diskutieren.
      <br/><br/>
      Beta-Version dieses Feature ist leider noch nicht verfügbar.
    </h4> -->
    <feedback />
  </div>
</template>

<script>

import Feedback from '@client/views/Feedback.vue';

export default {
  name: "Forum",
  components: {
    Feedback,
    Feedback
}
}
</script>
