import { createWebHistory, createRouter } from "vue-router";
import Dashboard from "@client/views/Dashboard.vue";
import Settings from "@client/components/Settings.vue";
import Feedback from "@client/views/Feedback.vue";
import Search from "@client/views/Search.vue";
import Stats from "@client/views/Stats.vue";
import Game from "@client/views/Game.vue";
import Videos from "@client/views/Videos.vue";
import NotificationSettings from "@client/components/NotificationSettings.vue";
import Quiz from "@client/views/Quiz.vue";
import UserQuiz from "@client/views/UserQuiz.vue";
import Topics from "@client/views/Topics.vue";
import PinnedTopics from "@client/views/PinnedTopics.vue";
import Login from "@client/views/Login.vue";
import Register from "@client/views/Register.vue";
import ForgotPassword from "@client/views/ForgotPassword.vue";
import ResetPassword from "@client/views/ResetPassword.vue";
import VerifyStudent from "@client/views/VerifyStudent.vue";
import Community from "@client/views/Community.vue";
import Archive from "@client/views/Archive.vue";
import Forum from "@client/views/Forum.vue";
import Profile from "@client/views/Profile.vue";
import Faq from "@client/views/Faq.vue";
import Summaries from "@client/views/Summaries.vue";
import Summary from "@client/views/Summary.vue";
import Chemical from "@client/views/labs/Chemical.vue";
import Drug from "@client/views/labs/Drug.vue";
import Experiment from "@client/views/labs/Experiment.vue";
import Material from "@client/views/labs/Material.vue";
import PlantFamily from "@client/views/labs/PlantFamily.vue";
import PlantWirkstoff from "@client/views/labs/PlantWirkstoff.vue";
import Plant from "@client/views/labs/Plant.vue";
import Protection from "@client/views/labs/Protection.vue";
import Teemischungen from "@client/views/labs/Teemischungen.vue";
import Warning from "@client/views/labs/Warning.vue";
import LearningUnit from "@client/views/LearningUnit.vue";
import CleverReach from "@client/views/CleverReach.vue";
import CleverReachOptOut from "@client/views/CleverReachOptOut.vue";
import LearningUnits from "@client/views/LearningUnits.vue";
import Experiments from "@client/views/Experiments.vue";
import Collections from "@client/views/Collections.vue";
import Subscription from "@client/views/Subscription.vue";
import SavedQuestions from "@client/views/SavedQuestions.vue";
import EmailUpdate from "@client/views/EmailUpdate.vue";
import {API_URL} from "@/conf";

const routes = [
    {
        path: '/',
        name: 'landing',
        redirect: {name: 'dashboard'},
		meta: {
			title: "Pharmanavi | Landing"
		}
    },
    {
        path: "/login/:email?",
        name: 'login',
        component: Login,
        meta: {
            title: "Pharmanavi | Login"
        }
    },
    {
        path: "/register",
        name: 'register',
        component: Register,
        meta: {
            title: "Pharmanavi | Register"
        }
    },
    {
        path: "/forgot-password",
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {
            title: "Pharmanavi | Forgot Password"
        }
    },
    {
        path: "/reset-password/:code(.*)",
        name: 'reset-password',
        component: ResetPassword,
        meta: {
            title: "Pharmanavi | Reset Password"
        }
    },
    {
        path: "/verify-student-email/:token(.*)",
        name: 'verify-student',
        component: VerifyStudent,
        meta: {
            title: "Pharmanavi | Verify Student"
        }
    },
    {
        path: "/dashboard",
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: checkAuth,
		meta: {
			title: "Pharmanavi | Dashboard"
		}
    },
	{
		path: "/settings",
        name: 'settings',
        component: Settings,
        beforeEnter: checkAuth,
		meta: {
			title: "Pharmanavi | Settings"
		}
	},
	{
		path: "/feedback",
        name: 'feedback',
        component: Feedback,
        beforeEnter: checkAuth,
		meta: {
			title: "Pharmanavi | Feedback"
		}
	},
    {
        path: "/topics",
        name: 'topics',
        component: Topics,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Topics",
            navigation: 'Zurück',
        }
    },
    {
        path: '/pinned_topics',
        name: 'topics.pinned',
        component: PinnedTopics,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Pinned Topics",
            navigation: 'Zurück',
        }
    },
	{
		path: "/settings/notifications",
		name: 'settings.notifications',
		component: NotificationSettings,
		beforeEnter: checkAuth,
		meta: {
			title: "Pharmanavi | Settings | Notifications"
		}
	},
	{
		path: "/search",
        name: 'search',
        component: Search,
        beforeEnter: checkAuth,
		meta: {
			title: "Pharmanavi | Search"
		}
	},
    {
        path: "/forum",
        name: 'forum',
        component: Forum,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Forum"
        }
    },
    {
        path: "/profile",
        name: 'profile',
        component: Profile,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Profile"
        }
    },
    {
        path: "/faq",
        name: 'faq',
        component: Faq,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Faq",
            navigation: 'FAQ'
        }
    },
    {
        path: "/stats",
        name: 'stats',
        component: Stats,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Statistics"
        }
    },
    {
        path: "/community",
        name: 'community',
        component: Community,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Community",
            navigation: 'On demand',
        }
    },
    {
        path: "/archive/:id",
        name: 'archive',
        component: Archive,
        beforeEnter: checkAuth,
        meta: {
            title: "Pharmanavi | Archive",
            navigation: 'On demand'
        }
    },
    {
        path: "/game",
        name: 'game',
        component: Game,
        meta: {
            title: "Pharmanavi | Game"
        }
    },
    {
        path: "/videos",
        name: 'videos',
        component: Videos,
        meta: {
            title: "Pharmanavi | Videos"
        }
    },
    {
        path: "/quiz/:topic?/:quiz?/:collection?",
        name: 'topics.quiz',
        component: Quiz,
        props: true,
        meta: {
            title: "Pharmanavi | Quiz"
        }
    },
    {
        path: "/user_quiz",
        name: 'user.quiz',
        component: UserQuiz,
        props: true,
        meta: {
            title: "Pharmanavi | Wichtige Fragen"
        }
    },
    {
        path: "/summary/:topic",
        name: 'topics.summary',
        component: Summary,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Zusammenfassung',
            title: "Pharmanavi | Zusammenfassung"
        }
    },
    {
        path: '/subscribe/:pkg',
        name: 'profile.subscribe',
        component: Subscription,
        beforeEnter: checkAuth,
        meta: {
            title: 'Pharmanavi | Subscription'
        }
    },
    {
        path: '/update_mail',
        name: 'profile.update_email',
        component: EmailUpdate,
        beforeEnter: checkAuth,
        meta: {
            title: 'Phamanavi | Update Email'
        }
    },
    {
        path: "/summaries/:topic",
        name: 'topics.summaries',
        component: Summaries,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Zusammenfassung',
            title: "Pharmanavi | Zusammenfassung"
        }
    },
    {
        path: "/learning_units/:topic",
        name: 'learning.units',
        component: LearningUnits,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Lerneinheit',
            title: "Pharmanavi | Lerneinheit"
        }
    },
    {
        path: "/experiments/:topic",
        name: 'experiments',
        component: Experiments,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Experimente',
            title: "Pharmanavi | Experimente"
        }
    },
    {
        path: "/collections/:topic",
        name: 'collections',
        component: Collections,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Übungsklausur',
            title: "Pharmanavi | Übungsklausur"
        }
    },
    {
        path: "/chemical/:id",
        name: 'Chemical',
        component: Chemical,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Chemikalien',
            title: "Pharmanavi | Chemikalien"
        }
    },
    {
        path: "/drug/:id",
        name: 'Drug',
        component: Drug,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Drogen',
            title: "Pharmanavi | Drogen"
        }
    },
    {
        path: "/experiment/:id",
        name: 'Experiment',
        component: Experiment,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Experimente',
            title: "Pharmanavi | Experimente"
        }
    },
    {
        path: "/material/:id",
        name: 'Material',
        component: Material,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Materialien',
            title: "Pharmanavi | Materialien"
        }
    },
    {
        path: "/plant_family/:id",
        name: 'PlantFamily',
        component: PlantFamily,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Pflanzenfamilien',
            title: "Pharmanavi | Pflanzenfamilien"
        }
    },
    {
        path: "/plant_wirkstoff/:id",
        name: 'PlantWirkstoff',
        component: PlantWirkstoff,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Pflanzliche Wirkstoffe',
            title: "Pharmanavi | Pflanzliche Wirkstoffe"
        }
    },
    {
        path: "/plant/:id",
        name: 'Plant',
        component: Plant,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Pflanzen',
            title: "Pharmanavi | Pflanzen"
        }
    },
    {
        path: "/protection/:id",
        name: 'Protection',
        component: Protection,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'H+P-Sätze',
            title: "Pharmanavi | H+P-Sätze"
        }
    },
    {
        path: "/teemischungen/:id",
        name: 'Teemischungen',
        component: Teemischungen,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Teemischungen',
            title: "Pharmanavi | Teemischungen"
        }
    },
    {
        path: "/warning/:id",
        name: 'Warning',
        component: Warning,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Warnstufen',
            title: "Pharmanavi | Warnstufen"
        }
    },
    {
        path: "/learning_unit/:id",
        name: 'LearningUnit',
        component: LearningUnit,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Lernheiten',
            title: "Pharmanavi | Lernheiten"
        }
    },
    {
        path: "/agree_newsletter",
        name: 'CleverReach',
        component: CleverReach,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Pharmanavi Newsletter',
            title: "Pharmanavi | Newsletter"
        }
    },
    {
        path: "/cancel_newsletter",
        name: 'CleverReachOptOut',
        component: CleverReachOptOut,
        beforeEnter: checkAuth,
        meta: {
            navigation: 'Pharmanavi Newsletter',
            title: "Pharmanavi | Newsletter"
        }
    },
    {
        path: "/saved_questions",
        name: "SavedQuestions",
        component: SavedQuestions,
        beforeEnter: checkAuth,
        meta: {
            navigation: "Wichtige Fragen",
            title: "Pharmanavi | gespeicherte Fragen"
        }
    },
    {
        path: '/:catchAll(.*)',
        redirect: { name: 'dashboard' }
    }
];

const router = createRouter({
	history: createWebHistory(),
	routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
});

function checkAuth(to, from) {
    return !(localStorage.getItem('token') === undefined || localStorage.getItem('token') === "" || localStorage.getItem('token') === null);
}

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {

    // If is login, check to see no current session exists first
    if ((routeTo.name === 'login' || routeTo.name === 'register') && checkAuth(routeTo, routeFrom)) {
        return next({name: 'dashboard'});
    }

    const authRequired = routeTo.matched.some((route) => route.beforeEnter)

    if (!authRequired) {
		return next()
	}

    if (localStorage.getItem('token') === null || localStorage.getItem('token')  === undefined) {
        await router.replace({path: '/login'})
    }

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token') // for all requests

    await axios.get(API_URL + 'check_auth').then((response) => {
        if (response.status === 200) {
            next()
        } else {
            // Reset the tracked user id
            localStorage.clear();
            router.go({
                name: 'login',
                force: true,
            });
        }

    }).catch((error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            router.go({
                name: 'login',
                force: true,
            });
        } else {
            localStorage.clear();
            router.go({
                name: 'login',
                force: true,
            });
        }
    });
});

export default router
