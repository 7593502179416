<template>
  <div class="a-z-index">
    <div class="index-list" ref="index-list">
      <span
        :class="{'selected': range === selectedRange, 'index-item': true, 'index-list': true}"
        v-for="range in characterRanges" :key="range"
      >
        <button
         :class="{'btn': true, 'btn-primary': range === selectedRange, 'btn-secondary': range !== selectedRange}"
         @click.prevent="selectRange(range)"
        >
          {{ range }}
        </button>
      </span>
    </div>
  </div>
</template>

<docs>
```vue
  <template>
    <div class="row my-5">
      <div class="col-4">
        <h1>A-Z Index</h1>
        <a-z-index-component />
      </div>
      <div class="row my-5">
        <div class="col-sm-12">
            <a-z-index-component :selected-range="selectedRange" @rangeSelected="rangeSelected"/>
        </div>
      </div>
    </div>
  </template>
```
</docs>

<script>

  /**
   * This is the A-Z index component for selecting ranges.
   * @displayName AZIndexComponent
   */
  export default {
    name: 'a-z-index-component',
    props: {
      /**
       * The current value of the progress circle
       * @type {String}
       * @default '0-9'
       */
      selectedRange: {
        type: String,
        default: '0-9'
      }
    },
    data() {
      return {

      }
    },
    mounted() {
      this.$nextTick(() => {
        const container = this.$refs['index-list']
        if (container.offsetWidth < container.scrollWidth) {
          container.style.overflowX = 'scroll'
        }
      });
    },
    computed: {
      characterRanges() {
        let ranges = []
          // add range 0-9
          ranges.push("0-9");
        for (let i = 65; i <= 90; i += 2) {
          ranges.push(`${String.fromCharCode(i)}-${String.fromCharCode(i+1)}`)
        }
        return ranges
      },
    },
    methods: {
      selectRange(range) {
        /**
         * This event is emitted when a button is clicked to signal the parent component which range is now selected.
         * @property {String} range The new range value
         */
        this.$emit('rangeSelected', range)
      }
    }
  }
</script>
