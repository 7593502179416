<script>
import { mapState } from 'vuex';
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import SavedQuestionQuizComponent from '@client/components/SavedQuestionQuizComponent.vue';
import SavedQuestionOverviewComponent from '@client/components/SavedQuestionOverviewComponent.vue';
export default {
    name: "SavedQuestions",
    data() {
        return {
            saved_questions: {
                questions: [],
                answered: 0,
                correct: 0
            },
        }
    },
    mounted() {
        this.loadQuestions();
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        loadQuestions() {
            if (this.user === null){
                return;
            }

            axios.get(`${API_URL}question/get_bookmarked/${this.user.id}`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.saved_questions.questions = response.data.questions;
                        this.saved_questions.answered = response.data.answered;
                        this.saved_questions.correct = response.data.correct;
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        removeQuestionFromList(question_id) {
            this.saved_questions.questions.forEach((item, index) => {
                if (question_id === item.question.id) {
                    this.saved_questions.questions.splice(index, 1);
                }
            });
        }
    },
    components: {
        SavedQuestionQuizComponent,
        SavedQuestionOverviewComponent
    }
}
</script>

<template>
    <div class="topics-container mt-5">
        <div class="container">
            <div class="row mt-3">
                <saved-question-quiz-component
                    icon="https://assets.pharmanavi.de/assets/uploads/app_icons/icons/topic_icons/Wichtige%20Fragen.svg"
                    :totalQuestions="saved_questions.questions.length"
                    :answered="saved_questions.answered"
                    :correct="saved_questions.correct"
                />
            </div>

            <div class="row">
                <div class="col">
                    <saved-question-overview-component
                        :overview="saved_questions"
                        @unbookmark-change="removeQuestionFromList"
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>

</style>
