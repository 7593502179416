<template>
    <div class="container">
        <div class="row mt-4">
            <div class="col offset-md-3">
                <h3>Dein Profil</h3>
            </div>
        </div>

        <div class="settings-forms">
            <div class="row my-4">
                <div class="col-12 col-md-4 offset-md-3">

                    <cropper
                        ref="cropper"
                        class="upload-example-cropper"
                        :src="image.src"
                        :style="{
                            width: '100vw'
                        }"
                        :stencil-component="stencil"
                    />

                    <br/><br/>

                    <div class="form-field mb-3">
                        <label for="profileimage">Profilbild</label>
                        <input type="file" class="form-control" id="profileimage" name="profileimage" ref="profileimage"
                               data-content="Datei auswählen" data-image=""
                               @change="loadImage($event)" accept="image/*">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3">
                    <input-component
                        name="username"
                        id="username"
                        label="Nutzername"
                        placeholder="Nutzername"
                        type="text"
                        :value="input.username"
                        v-model="input.username"
                        :has-error="true"
                    />
                </div>
            </div>

            <br/>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3">
                    <input-component
                        name="first_name"
                        id="first_name"
                        label="Vorname"
                        placeholder="Vorname"
                        type="text"
                        :value="input.first_name"
                        v-model="input.first_name"
                        :has-error="true"
                    />
                </div>
            </div>

            <br/>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3">
                    <input-component
                        name="last_name"
                        id="last_name"
                        label="Nachname"
                        placeholder="Nachname"
                        type="text"
                        :value="input.last_name"
                        v-model="input.last_name"
                        :has-error="true"
                    />
                </div>
            </div>

            <br/>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3">
                    <select-component
                        :key="this.input.semester_id"
                        :label="'Fachsemester'"
                        :options="semesters"
                        :value="input.semester_id"
                        v-model="input.semester_id"
                    />
                </div>
            </div>

            <br/>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3">
                    <select-component
                        :key="this.input.university_id"
                        :label="'Universität'"
                        :options="universities"
                        :value="input.university_id"
                        v-model="input.university_id"
                    />
                </div>
            </div>

            <br/>

            <div class="row mt-3 notifications" v-if="notificationStatus && notificationMsg.trim().length > 0">
                <div class="col-12 col-md-4 offset-md-3">
                    <notification-component @alert-closed="resetNotification" :status="notificationStatus"
                                            :message="notificationMsg"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3 text-center">
                    <button class="btn btn-primary w-100" v-on:click="updateProfile">
                        Änderungen speichern
                    </button>
                </div>
            </div>

            <br/>

            <div class="row mt-3 notifications"
                 v-if="notificationPasswordStatus && notificationPasswordMsg.trim().length > 0">
                <div class="col-12 col-md-4 offset-md-3">
                    <notification-component @alert-closed="resetNotification" :status="notificationPasswordStatus"
                                            :message="notificationPasswordMsg"/>
                </div>
            </div>

            <div class="row mb-2 payment-profile-div" v-if="hideSubscription === false">
                <div class="col-12 col-md-4 offset-md-3">
                    <h3 class="mb-4">Mitgliedschaft</h3>
                    <div class="row" v-if="user.package">
                        <div class="col">
                            <p class="sub-heading-3">
                                Zurzeit hast du folgende Pakete gebucht:
                                &nbsp; {{ user.package.name }}
                            </p>
                        </div>
                    </div>
                    <template v-if="user.active_license">
                        <div class="row">
                            <div class="col">
                                <p>Aktivierungsdatum</p>
                            </div>
                            <div class="col">
                                <p>{{ formatDate(user.active_license.created_at) }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col" v-if="!user.active_subscription">
                                <p>Ablaufdatum</p>
                            </div>
                            <div class="col" v-else>
                                <p>nächster Zahlungstermin</p>
                            </div>
                            <div class="col">
                                <p>{{ formatDate(user.active_license.next_pay_date) }}</p>
                            </div>
                        </div>
                        <div class="row mt-4 mb-4" v-if="user.active_subscription">
                            <div class="col">
                                <button class="btn btn-secondary w-100" @click.prevent="cancelSubscription"
                                        :disabled="cancellingSubscription">
                  <span v-if="cancellingSubscription">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Bitte warten...
                  </span>
                                    <span v-else>
                    Mitgliedschaft kündigen
                  </span>
                                </button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <section class="payment-profile-div" v-if="hideSubscription === false">
                <div class="row mb-2" v-if="!user.active_license && subscriptionPackages.length > 0">
                    <div class="col-12 col-md-4 offset-md-3">
                        <h4 class="mb-4">Du möchtest mehr Lerninhalte nutzen? Hier kannst du für dich passende Pakete
                            auswählen und abonnieren:</h4>
                        <router-link
                            :to="{ name: 'profile.subscribe', params: { pkg: pkg.id } }"
                            class="btn btn-secondary-outline w-100 mb-2"
                            v-for="pkg in subscriptionPackages"
                            :key="`pkg-subscribe-${pkg.id}`"
                        >
                            {{ pkg.name }}
                        </router-link>
                    </div>
                </div>
            </section>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3">
                    <h3>E-Mail Benachrichtigungen</h3>
                    <p>Du möchtest immer mit den aktuellen Neuigkeiten von PharmaNavi versorgt werden und keine Infos
                        verpassen? Dann melde dich hier für unseren Newsletter an</p>


                    <button class="btn btn-primary w-100" v-on:click="newsLetter">
                        Newsletter
                    </button>
                </div>
            </div>

            <br/>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3">
                    <h3>Passwort</h3>
                    <button class="btn btn-primary w-100" v-bind:class="{ disabled: changePasswordClicked}"
                            v-on:click="changePassword">
                        Passwort ändern
                    </button>
                </div>
            </div>

            <br/>

            <div class="row mt-3 notifications"
                 v-if="verificationEmailStatus.trim().length > 0 && verificationEmailMsg.trim().length > 0">
                <div class="col-12 col-md-4 offset-md-3">
                    <notification-component @alert-closed="resetNotification" :status="verificationEmailStatus"
                                            :message="verificationEmailMsg"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 offset-md-3" v-if="profile !== null">
                    <h3>Verifikation des Studierendenstatus</h3>
                    <template v-if="profile.user_verification === null">
                        <h6>Verifikation mit Uni-Mailadresse</h6>

                        <input-component
                            name="verification_email"
                            id="verification_email"
                            label="Email"
                            placeholder="Email"
                            type="email"
                            :value="input.verification_email"
                            v-model="input.verification_email"
                            :has-error="true"
                        />

                        <br/>

                        <h6>Verifikation mit Studienbescheinigung</h6>
                        <input-component
                            name="verification_document"
                            id="verification_document"
                            label="File"
                            placeholder="File"
                            type="file"
                            :value="input.verification_document"
                            v-model="input.verification_document"
                            :has-error="true"
                            :accept="'.pdf,.png,.jpg,.jpeg'"
                        />

                        <br/>

                        <button class="btn btn-primary w-100" v-bind:class="{ disabled: verificationEmailSubmitted}"
                                v-on:click="submitVerificationData">
                            Verifikation anfordern
                        </button>
                    </template>
                    <template v-else>
                        <p v-if="profile.user_verification.status === 1">Studierendenstatus verifiziert bis Semesterende</p>
                        <p style="color: #891e35 !important;" v-if="profile.user_verification.declined === 1">Verifikation nicht erfolgreich, wende dich bei Fragen bitte an info@pharmanavi.de </p>
                    </template>
                </div>
            </div>

            <div class="row mt-3 notifications"
                 v-if="notificationDeletionStatus && notificationDeletionMsg.trim().length > 0">
                <div class="col-12 col-md-4 offset-md-3">
                    <notification-component @alert-closed="resetNotification" :status="notificationDeletionStatus"
                                            :message="notificationDeletionMsg"/>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-4 offset-md-3">
                    <h3>Konto löschen</h3>
                    <button class="btn btn-secondary-outline w-100" style="padding-top: 0px;"
                            v-bind:class="{ disabled: processingDeleteAccount}" v-on:click="deleteAccount">
                        Konto löschen
                    </button>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-12 col-md-4 offset-md-3">
                    <h3>E-Mail-Adresse</h3>
                    <router-link
                        :to="{ name: 'profile.update_email'}"
                        class="btn btn-secondary-outline w-100 mb-2"
                    >
                        E-Mail-Adresse ändern
                    </router-link>
                </div>
            </div>

        </div>


        <div class="row mt-5">
            <div class="col-12 col-md-4 offset-md-3 text-center">
                <button class="btn btn-secondary btn-block" v-on:click="logout">Abmelden</button>
            </div>
        </div>
    </div>
</template>

<script>
import useTracker from "@client/composables/useTracker";
import InputComponent from '@client/components/InputComponent.vue';
import SelectComponent from '@client/components/SelectComponent.vue';
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import NotificationComponent from "@client/components/NotificationComponent.vue";
import router from "../router";
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import CircleStencil from '@client/components/CircleStencil.vue';

// This function is used to detect the actual image type,
function getMimeType(file, fallback = null) {
    const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }

    switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
            return "image/jpg"
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
    name: "Profile",
    setup() {
        const {resetTrackedUserId} = useTracker();
        const logout = () => {

            // Reset the tracked user id
            resetTrackedUserId();
            localStorage.clear();

            const appEnv = process.env.MIX_APP_ENV

            if (appEnv === 'production')
                return window.location.replace('https://pharmanavi.de/');
            else
                return window.location.replace('https://beta.pharmanavi.de/');
        };

        return {
            logout,
        };
    },
    data() {
        return {
            profileImg: null,
            cancellingSubscription: false,
            user: {},
            image: {
                src: null,
                type: null
            },
            stencil: CircleStencil,
            input: {
                username: "",
                first_name: "",
                last_name: "",
                semester_id: 0,
                university_id: 0,
                verification_email: "",
                verification_document: ""
            },
            universities: [
                {
                    text: "Bitte wählen...",
                    value: 0,
                }
            ],
            semesters: [
                {
                    text: "Bitte wählen...",
                    value: 0,
                }
            ],
            subscriptionPackages: [],
            notificationStatus: false,
            notificationMsg: '',
            notificationPasswordStatus: false,
            notificationPasswordMsg: '',
            notificationDeletionStatus: false,
            notificationDeletionMsg: '',
            changePasswordClicked: false,
            processingDeleteAccount: false,
            verificationEmailSubmitted: false,
            verificationEmailStatus: "",
            verificationEmailMsg: "",
            hideSubscription: false
        }
    },
    mounted() {
        const fileInput = document.getElementById('profileimage');

        fileInput.addEventListener('change', () => {
            if (fileInput.value === '') {
                fileInput.setAttribute('data-content', 'Datei auswählen');
                fileInput.setAttribute('data-image', '');
            } else {
                const realPathArray = fileInput.value.split('\\');
                fileInput.setAttribute('data-content', realPathArray[realPathArray.length - 1]);
                fileInput.setAttribute('data-image', realPathArray[realPathArray.length - 1]);
            }
        });

        const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith("native_app="))
            ?.split("=")[1];

        if (cookieValue === "yes") {
            this.hideSubscription = true;
            /*const paymentProfileDivs = document.getElementsByClassName('payment-profile-div');

            for (var i = 0; i < paymentProfileDivs.length; i++) {
                paymentProfileDivs[i].style.display = "none";
            }*/
        }

    },
    beforeMount() {
        this.loadUniversities();
        this.loadSemesters();
        this.loadSubscriptionPackages();

        this.loadUserData(localStorage.getItem("email")).then((response) => {
            if (response.status === 200) {
                this.user = response.data.user;

                /**
                if (this.user.avatar !== null && this.user.avatar.trim().length > 0 && this.user.avatar !== '0') {
                    this.image.src = 'https://assets.pharmanavi.de/assets/uploads/avatars/' + this.user.avatar;

                    var imageData = this.user.avatar.split(".");
                    if (imageData.length === 1 || (imageData[0] === "" && imageData.length === 2)) {
                        imageData = "";
                    }

                    if (imageData !== "") {
                        this.image.type = 'image/' + imageData.pop();
                    }
                }
                **/

                this.input.username = this.user.username;
                this.input.first_name = this.user.first_name;
                this.input.last_name = this.user.last_name;
                this.input.university_id = this.user.university_id;
                this.input.semester_id = this.user.semester_id;
            } else {
                this.notificationStatus = 'danger'
                this.notificationMsg = 'Fehler beim Laden von Benutzerdaten!';
            }

        });
    },
    methods: {
        loadUserData(email) {
            return this.$store.dispatch("getUserData", email);
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const germanFormat = date.toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return germanFormat
        },
        loadUniversities() {
            axios.get(`${API_URL}user/get_all_universities`)
                .then(response => {
                    if (response.status === 200) {
                        response.data.forEach((university) => {
                            this.universities.push({
                                value: university.id,
                                text: university.name
                            });
                        });
                    }
                }).catch(error => {
                this.notificationStatus = 'danger'
                this.notificationMsg = 'Failed to load universities.';
                this.changePasswordClicked = false;
            });
        },
        loadSemesters() {
            axios.get(`${API_URL}user/get_all_semesters`)
                .then(response => {
                    if (response.status === 200) {
                        response.data.forEach((semester) => {
                            this.semesters.push({
                                value: semester.id,
                                text: semester.name
                            });
                        });
                    }
                }).catch(error => {
                this.notificationStatus = 'danger'
                this.notificationMsg = 'Failed to load semesters.';
                this.changePasswordClicked = false;
            });
        },
        loadSubscriptionPackages() {
            axios.get(`${API_URL}packages/`)
                .then(response => {
                    if (response.status === 200) {
                        this.subscriptionPackages = response.data.packages
                        this.subscriptionPackages = this.subscriptionPackages.filter(pkg => pkg.is_default !== 1)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        resetNotification() {
            this.notificationStatus = false
            this.notificationMsg = ''
        },
        handleProfileImage(event) {
            const file = event.target.files[0];
            if (this.isValidImage(file)) {
                this.profileImg = file;
            } else {
                // Reset the file input field
                this.resetFileInput();
                alert('Please upload valid image!')
            }
        },
        isValidImage(file) {
            const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg'];

            return file && acceptedImageTypes.includes(file.type);
        },
        resetFileInput() {
            // Clear the file input field
            this.$refs['profileimage'].value = '';
        },
        updateProfile() {

            if (!this.validateInput())
                return;

            // Proceed with the upload logic
            var formData = new FormData();
            formData.append('username', this.input.username)
            formData.append('first_name', this.input.first_name)
            formData.append('last_name', this.input.last_name)
            formData.append('semester_id', this.input.semester_id)
            formData.append('university_id', this.input.university_id)

            if (this.$refs.profileimage.dataset.image !== "") {

                const {canvas} = this.$refs.cropper.getResult();
                if (canvas) {
                    canvas.toBlob(blob => {
                        formData.append('picture', blob);
                        // You can use axios, superagent and other libraries instead here
                        let headers = authHeader();
                        headers['Content-Type'] = 'multipart/form-data'

                        axios.post(`${API_URL}user/update_profile`, formData, {'headers': headers})
                            .then(response => {
                                if (response.status === 201) {
                                    // Reset the user data

                                    this.notificationStatus = 'success'
                                    this.notificationMsg = 'Erfolgreich aktualisiert'

                                    this.loadUserData(this.user.email).then((response) => {
                                        if (response.status === 200) {
                                            this.user = response.data.user;
                                            this.input.username = this.user.username;
                                            this.input.first_name = this.user.first_name;
                                            this.input.last_name = this.user.last_name;
                                            this.input.university_id = this.user.university_id;
                                            this.input.semester_id = this.user.semester_id;
                                        } else {
                                            this.notificationStatus = 'danger'
                                            this.notificationMsg = 'Fehler beim Laden von Benutzerdaten!';
                                        }

                                    });
                                } else {
                                    this.notificationStatus = 'danger'
                                    this.notificationMsg = 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut!';
                                }
                            })
                            .catch(error => {
                                this.notificationStatus = 'danger'
                                this.notificationMsg = 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut!';
                                console.log(error)
                            });

                        // Reset the file input field after successful upload
                        this.resetFileInput();
                        this.profileImg = null;
                        // Perhaps you should add the setting appropriate file format here
                    }, this.image.type);
                }

                return;
            }

            let headers = authHeader();
            headers['Content-Type'] = 'multipart/form-data'

            axios.post(`${API_URL}user/update_profile`, formData, {'headers': headers})
                .then(response => {
                    if (response.status === 201) {
                        // Reset the user data

                        this.notificationStatus = 'success'
                        this.notificationMsg = 'Erfolgreich aktualisiert'

                        this.loadUserData(this.user.email).then((response) => {
                            if (response.status === 200) {
                                this.user = response.data.user;
                                this.input.username = this.user.username;
                                this.input.first_name = this.user.first_name;
                                this.input.last_name = this.user.last_name;
                                this.input.university_id = this.user.university_id;
                                this.input.semester_id = this.user.semester_id;
                            } else {
                                this.notificationStatus = 'danger'
                                this.notificationMsg = 'Fehler beim Laden von Benutzerdaten!';
                            }

                        });
                    } else {
                        this.notificationStatus = 'danger'
                        this.notificationMsg = 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut!';
                    }
                })
                .catch(error => {
                    this.notificationStatus = 'danger'
                    this.notificationMsg = 'Etwas ist schief gelaufen, bitte versuchen Sie es erneut!';
                    console.log(error)
                });

            // Reset the file input field after successful upload
            this.resetFileInput();
            this.profileImg = null;
        },
        cancelSubscription() {
            const activeSubscription = this.user.active_subscription;
            if (!activeSubscription)
                return

            this.$swal({
                text: "Bist du dir sicher, dass du kündigen möchtest?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Ja!',
                cancelButtonText: 'Nein',
            })
                .then((willcancel) => {
                    if (willcancel.isConfirmed) {
                        this.cancellingSubscription = true;
                        axios.patch(`${API_URL}subscriptions/${activeSubscription.subscription}/cancel_subscription`, {}, {'headers': authHeader()})
                            .then(response => {
                                if (response.status === 200 && response.data.message === 'cancelled') {
                                    // Update the user subscription
                                    this.cancellingSubscription = false
                                    this.user.active_subscription = null;
                                }
                            })
                            .catch(error => {
                                this.cancellingSubscription = false
                                console.log(error)
                                this.processingActions.splice(this.processingActions.indexOf(id), 1);
                            });
                    }
                });
        },
        validateInput() {
            if (this.input.username.trim().length <= 0) {
                this.notificationStatus = 'warning'
                this.notificationMsg = 'Benutzername ist erforderlich!'

                return false
            }

            if (this.input.username.length > 10) {
                this.notificationStatus = 'warning'
                this.notificationMsg = 'Der Benutzername darf nicht länger als 10 Zeichen sein'

                return false
            }

            return true
        },
        changePassword() {
            this.changePasswordClicked = true;

            axios.post(`${API_URL}forgotten_password`, {
                email: this.user.email
            })
                .then(response => {
                    if (response.status === 200) {
                        this.notificationPasswordStatus = 'success'
                        this.notificationPasswordMsg = response.data.message;
                        this.input.email = "";
                    } else {
                        this.notificationStatus = 'danger'
                        this.notificationPasswordMsg = response.data.message;
                    }

                    this.changePasswordClicked = false;
                }).catch(error => {
                this.notificationPasswordStatus = 'danger'
                this.notificationPasswordMsg = 'Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!';
                this.changePasswordClicked = false;
            });
        },
        newsLetter() {
            if (this.user.subscribed_clever_reach === false)
                router.replace({path: '/agree_newsletter'});
            else
                router.replace({path: '/cancel_newsletter'});
        },
        deleteAccount() {
            this.$swal({
                text: "Bist du sicher, dass du dein Konto deaktivieren möchtest?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Ja!',
                cancelButtonText: 'Nein',
            })
                .then((willcancel) => {
                    if (willcancel.isConfirmed) {
                        this.processingDeleteAccount = true;
                        axios.patch(`${API_URL}user/delete_account`, {}, {'headers': authHeader()})
                            .then(response => {
                                this.processingDeleteAccount = false;
                                if (response.status === 200) {
                                    this.notificationDeletionStatus = response.data.status
                                    this.notificationDeletionMsg = response.data.message
                                } else {
                                    this.notificationStatus = 'danger'
                                    this.notificationPasswordMsg = response.data.message
                                }
                            })
                            .catch(error => {
                                this.processingDeleteAccount = false
                                this.notificationPasswordStatus = 'danger'
                                this.notificationPasswordMsg = 'Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!';
                            });
                    }
                });
        },
        submitVerificationData() {
            if (this.input.verification_email !== "") {
                this.verificationEmailSubmitted = true;

                axios.post(`${API_URL}user/send_student_verification_email`, {
                    email: this.input.verification_email
                }, {'headers': authHeader()})
                    .then(response => {
                        if (response.status === 200) {
                            this.verificationEmailStatus = "success";
                            this.verificationEmailMsg = response.data.message;
                        } else {
                            this.verificationEmailStatus = 'danger'
                            this.verificationEmailMsg = response.data.message;
                        }

                        this.verificationEmailSubmitted = false;

                    })
                    .catch(error => {
                        this.verificationEmailSubmitted = false;

                        this.verificationEmailStatus = 'danger'
                        this.verificationEmailMsg = error.response.data.message;
                    });
            }

            if (this.input.verification_document !== "") {
                this.verificationEmailSubmitted = true;

                const doc = document.querySelector("#verification_document")

                var formData = new FormData();
                formData.append("document", doc.files[0]);

                axios.post(`${API_URL}user/send_student_verification_document`, formData, {'headers': authHeader()})
                    .then(response => {
                        if (response.status === 200) {
                            this.verificationEmailStatus = "success";
                            this.verificationEmailMsg = response.data.message;
                        } else {
                            this.verificationEmailStatus = 'danger'
                            this.verificationEmailMsg = response.data.message;
                        }

                        this.verificationEmailSubmitted = false;

                    })
                    .catch(error => {
                        this.verificationEmailSubmitted = false;

                        this.verificationEmailStatus = 'danger'
                        this.verificationEmailMsg = error.response.data.message;
                    });
            }

        },
        crop() {
            const {canvas} = this.$refs.cropper.getResult();
            canvas.toBlob((blob) => {
                // Do something with blob: upload to a server, download and etc.
            }, this.image.type);
        },
        reset() {
            this.image = {
                src: null,
                type: null
            }
        },
        loadImage(event) {
            // Reference to the DOM input element
            const {files} = event.target;
            // Ensure that you have a file before attempting to read it
            if (files && files[0]) {
                // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src)
                }
                // 2. Create the blob link to the file to optimize performance:
                const blob = URL.createObjectURL(files[0]);

                // 3. The steps below are designated to determine a file mime type to use it during the
                // getting of a cropped image from the canvas. You can replace it them by the following string,
                // but the type will be derived from the extension and it can lead to an incorrect result:
                //
                // this.image = {
                //    src: blob;
                //    type: files[0].type
                // }

                // Create a new FileReader to read this image binary data
                const reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                    this.image = {
                        // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                        src: blob,
                        // Determine the image type to preserve it during the extracting the image from canvas:
                        type: files[0].type
                        //type: getMimeType(e.target.result, files[0].type),
                    };
                };
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsArrayBuffer(files[0]);
            }
        },
    },
    destroyed() {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
            URL.revokeObjectURL(this.image.src)
        }
    },
    components: {
        InputComponent,
        NotificationComponent,
        SelectComponent,
        Cropper
    },
    computed: {
        profile() {
            return this.$store.state.user
        }
    }
}
</script>


<style scoped>

input[name="profileimage"] {
    color: transparent;
    padding-top: 12px;

    &::-webkit-file-upload-button {
        display: none;
    }

    &::file-selector-button {
        display: none;
    }
}

input[type='file']::before {
    content: attr(data-content);
    color: black;
}

</style>
