<template>
    <div class="col-sm-6 col-md-4">
        <div class="topic-component" @click.prevent="loadSubtopics">
            <div class="row topic d-flex justify-content-center align-items-center">
                <div class="col-sm-12 col-md-4 col-lg-4 icon">
                    <img v-if="iconExists" :src="iconLink" alt="">
                    <img v-else :src="icon" :alt="title"/>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8 title">
                    <h4 class="text-center" :title="title">{{title}}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">
            <topic-component :title="'Title'" :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"/>
            <topic-component :title="'Title'" :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"/>
            <topic-component :title="'Title'" :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"/>
        </div>
    </div>
</template>
```
</docs>

<script>
/**
 * For displaying topics and sub topics
 * @displayName TopicComponent
 */
export default {
    name: "TopicComponent",
    props: {
        /**
         * The link to the topic icon. Sub topics will use main topic link.
         * @values url
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Name/Title of topic or subtopic
         * @values string
         */
        title: {
            type: String,
            required: true
        },
        topic: {
            type: Object,
            default: {}
        }
    },
    methods: {
        loadSubtopics() {
            this.$emit('load-hierarchy', this.topic)
        }
    },
    computed: {
        async iconExists() {
            try {
                const response = await fetch(this.iconLink, { method: 'HEAD' });
                // Check if the response status is in the 200 range
                return response.ok

            } catch (error) {
                return false
            }
        },
        iconLink() {
            const iconsUrl = 'https://assets.pharmanavi.de/assets/uploads/app_icons/icons/topic_icons/';
            return iconsUrl + this.title.replace(/ /g, '%20') + '.svg'
        }
    },
}
</script>

<style scoped>

</style>
