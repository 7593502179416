<script>
import {defineComponent} from 'vue'
import InputComponent from '@client/components/InputComponent.vue';
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import router from "../router";

export default defineComponent({
    name: "CleverReach",
    data() {
        return {
            input: {
                email: "",
                first_name: "",
                last_name: "",
                von: false,
                terms: false
            },
            formSubmitted: false
        }
    },
    methods: {
        submitNewsLetter() {
            axios.post(`${API_URL}subscribe_news_letter`, {
                status: this.input.von,
                email: this.input.email
            })
                .then(response => {
                    this.formSubmitted = false;

                    if (response.status === 200) {
                        document.getElementById('clever-reach-form').submit();
                        router.replace({path: '/dashboard'})
                    } else {
                        this.$swal("Info", response.data.message, "info");
                    }
                }).catch(error => {
                if (error.response.data.errors !== undefined) {
                    if (error.response.data.errors.email !== undefined) {
                        this.$swal("Error", "Die Emailadresse ist nicht möglich!", "error");
                        this.formSubmitted = false;
                    }
                    return;
                }

                this.$swal("Error", "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!", "error");
                this.formSubmitted = false;
            });
        }
    },
    components: {
        InputComponent
    }
})
</script>

<template>
    <div class="container">
        <div class="clever-reach-form">


            <form style="display: none" id="clever-reach-form" action="https://seu1.cleverreach.com/f/258367-233664/wcs/" method="post" target="_blank">
                <input name="1255262" v-model="input.first_name">
                <input name="1255263" v-model="input.last_name">
                <input name="email" v-model="input.email">
                <input  type="checkbox" name="1257953[]" value="zu Aktionen von PharmaNavi" v-model="input.von">
                <input  type="checkbox" name="1257953[]" value="zu Produkten des Deutschen Apotheker Verlags" v-model="input.terms">
            </form>

            <form>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-3">
                        <input-component
                            name="first_name"
                            id="first_name"
                            label="Vorname"
                            placeholder="Vorname"
                            type="text"
                            :value="input.first_name"
                            v-model="input.first_name"
                        />
                    </div>
                </div>

                <br/>

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-3">
                        <input-component
                            name="last_name"
                            id="last_name"
                            label="Nachname"
                            placeholder="Nachname"
                            type="text"
                            :value="input.last_name"
                            v-model="input.last_name"
                        />
                    </div>
                </div>

                <br/>

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-3">
                        <input-component
                            name="email"
                            id="email"
                            label="Email *"
                            placeholder="Emailadresse eingeben"
                            type="email"
                            :value="input.email"
                            v-model="input.email"
                        />
                    </div>
                </div>

                <br/>

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-3">
                        <h6>Zu welchen speziellen Themen möchtest du Infos erhalten?</h6>
                    </div>
                </div>

                <br/>

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-3">
                        <div
                            class="form-check terms-container"
                        >
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="terms-check"
                                :value="input.von"
                                v-model="input.von"
                            >
                            <label
                                class="form-check-label" for="terms-check">
                                <span>zu Aktionen von PharmaNavi</span>
                            </label>

                            <br/><br/>

                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="news-letter-check"
                                :value="input.terms"
                                v-model="input.terms"
                            >
                            <label
                                class="form-check-label" for="news-letter-check">
                            <span>
                                zu Produkten des Deutschen Apotheker Verlags
                            </span>
                            </label>
                        </div>
                    </div>
                </div>

                <br/>

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-3">
                        <div class="text-center">
                            <button class="btn btn-primary" v-bind:class="{ disabled: formSubmitted}" type="button" v-on:click="submitNewsLetter">
                                Anmelden
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
