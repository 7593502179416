<template>
  <div class="mobile-bottom-nav d-lg-none mt-4" id="mobile-bottom-nav">
    <nav class="navbar fixed-bottom bg-light">
      <div class="container-fluid">
        <ul class="nav justify-content-between w-100">
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="{active: pageName === 'dashboard'}"
              :to="{ name: 'dashboard' }"
            >
              <i class="fa fa-house"></i>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              :class="{active: pageName === 'search'}"
              :to="{ name: 'search' }"
            >
              <i class="fa fa-magnifying-glass"></i>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="nav-btn d-flex justify-content-center align-items-center"
              type="button"
              :class="{active: pageName === 'topics'}"
              :to="{ name: 'topics' }"
            >
              <i class="fa fa-book"></i>
          </router-link>
          </li>
          <li class="nav-item">
              <router-link
                  class="nav-link"
                  :class="{active: pageName === 'forum'}"
                  :to="{ name: 'forum' }"
              >
                  <i class="fa fa-comments"></i>
              </router-link>
          </li>
          <li class="nav-item">
              <router-link
                  class="nav-link"
                  :class="{active: pageName === 'profile'}"
                  :to="{ name: 'profile' }"
              >
                  <i class="fa fa-user"></i>
              </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
  export default {
    name: 'mobile-navigation',
      mounted() {

          $(document).ready(function() {
              var $window = $(window);
              var navbarDiv = $('#mobile-bottom-nav');
              var lastScrollTop = 0;

              $window.on('scroll', function() {
                  var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
                  if (st > lastScrollTop) {
                      // downscroll code
                      navbarDiv.hide(500);
                  } else if (st < lastScrollTop) {
                      // upscroll code
                      navbarDiv.show(500);
                  }
                  // else was horizontal scroll
                  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
              });
          });
      },
      computed: {
      pageName() {
        return this.$route.name;
      },
      profile() {
        return this.$store.state.user
      }
    },
  }
</script>
