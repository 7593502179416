<template>
  <div class="question-container image-responsive">
    <div class="row">
      <div class="col-12 col-md-6 mb-5" v-if="quizOrExamMode">
        <progress-component
          :min-value="0"
          :max-value="100"
          :current-value="questionNumberPercentage"
        />
      </div>
    </div>
    <div v-if="Object.entries(question).length > 0" class="question">
      <div class="row">
        <div
          class="col question-wording"
          v-html="decodeHtml(question.wording)"
        ></div>
      </div>
      <div class="row question-hint-tag-wrapper" v-if="isMultipleChoice">
        <div class="col">
          <span
            v-if="question.has_order"
            class="badge bg-primary question-hint-tag"
          >
            Bringe die Elemente in die richtige Reihenfolge
          </span>
          <span v-else class="badge bg-primary question-hint-tag"
            >Mehrfachauswahl möglich</span
          >
        </div>
      </div>
      <div class="row">
        <div
          class="col-12 question-answers"
          :class="{ 'col-lg-8': evaluationMode }"
        >
          <form @submit.prevent v-if="!question.has_order">
            <div
              class="form-check answer-container"
              :class="{
                'evaluation-mode': evaluationMode,
                wa:
                  evaluationMode &&
                  !isCorrectAnswer(answer.id) &&
                  isSelectedAnswer(answer.id),
                ra: evaluationMode && isCorrectAnswer(answer.id),
              }"
              v-for="answer in question.answers"
              :key="answer.id"
              v-if="isMultipleChoice"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :id="`${question.id}-${answer.id}`"
                :value="answer.id"
                v-model="selectedAnswers"
              />
              <label
                class="form-check-label"
                :for="`${question.id}-${answer.id}`"
                v-html="decodeHtml(answer.wording)"
              >
              </label>
            </div>
            <div
              class="form-check answer-container"
              :class="{
                'evaluation-mode': evaluationMode,
                wa:
                  evaluationMode &&
                  !isCorrectAnswer(answer.id) &&
                  isSelectedAnswer(answer.id),
                ra: evaluationMode && isCorrectAnswer(answer.id),
              }"
              v-for="answer in question.answers"
              v-else
            >
              <input
                class="form-check-input"
                type="radio"
                :id="`${question.id}-${answer.id}`"
                :value="answer.id"
                v-model="selectedAnswers"
              />
              <label
                class="form-check-label radio-label"
                :for="`${question.id}-${answer.id}`"
                v-html="decodeHtml(answer.wording)"
              >
              </label>
            </div>
          </form>
          <template v-else-if="question.has_order">
            <div>
              <div class="row order-answers">
                <div class="col">
                  <draggable
                    class="drag-container selected-list"
                    :class="{
                      'evaluation-mode': evaluationMode,
                      wa: evaluationMode && !evaluationResult,
                      ra: evaluationMode && evaluationResult,
                    }"
                    :list="selectedAnswers"
                    :component-data="{
                      tag: 'div',
                      name: 'flip-list',
                      type: 'transition',
                    }"
                    itemKey="id"
                  >
                    <template #item="{ element }">
                      <div
                        class="answer-container drag-element d-flex align-items-center justify-content-between"
                      >
                        <div
                          v-html="decodeHtml(element.wording)"
                          class="flex-grow-1"
                        ></div>
                        <span class="badge badge-primary" v-if="evaluationMode">
                          {{ element.order }}</span
                        >
                      </div>
                    </template>
                  </draggable>
                </div>
              </div>
            </div>
          </template>
        </div>
        <Transition name="slide-fade">
          <div
            v-if="evaluationMode"
            class="col-12 col-lg-4 question-explanation image-responsive"
          >
            <div class="d-flex flex-column">
              <question-explanation-component
                :explanation="questionExplanation"
                :correct="evaluationResult"
              />
            </div>
          </div>
        </Transition>
      </div>
      <div class="row" v-if="!overviewMode">
        <div class="col">
          <div
            class="d-grid gap-5 d-flex justify-content-around justify-content-md-end mt-4"
          >
            <button class="btn btn-secondary">Frage: {{ question.id }}</button>
            <template v-if="examMode">
              <button
                class="btn btn-primary"
                type="button"
                @click.prevent="checkAnswer"
              >
                Weiter
              </button>
            </template>
            <template v-else>
              <button
                class="btn btn-secondary"
                type="button"
                v-if="questionNumber > 1"
                @click.prevent="previousQuestion"
              >
                <i class="fa-solid fa-arrow-left"></i>
                Zurück
              </button>
              <button
                v-if="evaluationMode && quizOrExamMode"
                class="btn btn-primary"
                type="button"
                @click.prevent="nextQuestion"
              >
                Fortsetzen
                <i class="fa-solid fa-arrow-right"></i>
              </button>
              <button
                v-else
                class="btn btn-primary"
                type="button"
                @click.prevent="checkAnswer"
              >
                überprüfen
              </button>
            </template>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <i class="fa-regular fa-comment" v-on:click="showComments"></i>
            <span style="padding: 5px"> </span>
            <span class="bookmark">
              <i
                @click.prevent="bookmarkQuestion"
                class="fa-bookmark"
                :class="userQuestion !== null ? 'fa-solid' : 'fa-regular'"
              ></i>
            </span>
          </div>
        </div>
        <div class="row" v-show="commentVisible">
          <div class="col">
            <h1>Frage kommentieren</h1>
            <textarea
              class="form-control comment-texteditor texteditor"
              name="comment"
              id="comment"
            ></textarea>
            <br />
            <div class="text-right">
              <button
                class="btn btn-primary"
                v-bind:class="{ disabled: !canSubmitComment }"
                type="button"
                @click.prevent="createComment"
              >
                Absenden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <div
              class="spinner-grow"
              style="width: 3rem; height: 3rem"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import draggable from "vuedraggable";
import ProgressComponent from "@client/components/ProgressComponent.vue";
import QuestionExplanationComponent from "@client/components/QuestionExplanationComponent.vue";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";

import "katex/dist/katex.css";
import katex from "katex";
window.katex = katex;

import { textArea } from "@/textarea.js";
import { mapState } from "vuex";

export default {
  name: "question-component",
  props: {
    question: {
      type: Object,
      default: {},
    },
    questionNumber: {
      type: Number,
      default: 1,
    },
    totalQuestions: {
      type: Number,
      default: 1,
    },
    quizOrExamMode: {
      type: Boolean,
      default: true,
    },
    examMode: {
      type: Boolean,
      default: false,
    },
    quizId: {
      type: Number,
      default: 0,
    },
    overviewMode: {
      type: Boolean,
      default: false,
    },
    overviewSelectedAnswers: {
      type: Array,
      default: [],
    },
    previousQuestionMode: {
      type: Boolean,
      default: false,
    },
    userQuestion: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      selectedAnswers: [],
      evaluationMode: false,
      evaluationResult: false,
      comments: [],
      commentVisible: false,
      comment: "",
      canSubmitComment: false,
      orderQuestionEvaluationModeAnswers: [],
    };
  },
  mounted() {
    (($) => {
      textArea();

      $("#comment").on("summernote.change", () => {
        this.comment = $("#comment").summernote("code");

        if (this.comment !== "") {
          this.canSubmitComment = true;
        } else {
          this.canSubmitComment = false;
        }
      });
    })(jQuery);
  },
  methods: {
    nextQuestion() {
      this.evaluationMode = false;
      this.evaluationResult = false;
      this.selectedAnswers = [];
      this.$emit("next-question");
    },
    previousQuestion() {
      this.evaluationMode = false;
      this.evaluationResult = false;
      this.selectedAnswers = [];
      this.$emit("previous-question");
    },
    checkAnswer() {
      const headers = authHeader();

      let selected_answers = this.selectedAnswers;
      // First consider the order questions since the vuedraggable@next library adds answer object in it's entirity to the selectAnswers array.
      // We need to extract only the answer ids in their given order.
      if (this.question.has_order) {
        // extract only the answer ids.
        selected_answers = this.selectedAnswers.map((obj) => obj.id);
      }

      // while creating input, we should consider the behavior of radio buttons using single value instead of declared array.
      // Even for radio buttons,if no value is selected, the modelled data is unchanged.
      else if (!this.isMultipleChoice) {
        // Single choice, uses radio buttons
        selected_answers = Array.isArray(this.selectedAnswers)
          ? this.selectedAnswers
          : [this.selectedAnswers];
      }

      const input = {
        question_id: this.question.id,
        selected_answers: selected_answers,
      };
      const instance = this;

      const quiz_id =
        parseInt(this.quizId) > 0 ? `/${parseInt(this.quizId)}` : "";

      axios
        .post(`${API_URL}quiz/check${quiz_id}`, input, {
          headers: headers,
        })
        .then((response) => {
          if (response.status === 200) {
            if (!this.examMode) {
              this.evaluationMode = true;
              this.evaluationResult = response.data.result;

              // Order the question answers for correct order display in the case of order questions.
              /* if (this.question.has_order) {
                this.orderQuestionEvaluationModeAnswers = [...this.questions.answers].sort((a, b) => a.order - b.order);
              } */
            }

            this.$emit(
              "track-success-rate",
              this.evaluationResult === true ? "Correct" : "Wrong",
            );
            if (this.examMode) {
              this.nextQuestion();
            }
          } else {
            // alert('Ein technischer Fehler ist aufgetreten, bitte versuche es erneut.');
            this.submitting = false;
            setTimeout(() => {
              instance.error_message = "";
            }, 10000);
          }
        })
        .catch((error) => {
          console.log(error);
          alert(
            "Ein technischer Fehler ist aufgetreten, bitte versuche es erneut.",
          );
          this.submitting = false;
          setTimeout(() => {
            instance.error_message = "";
          }, 10000);
        });
    },
    isSelectedAnswer(id) {
      return this.isMultipleChoice
        ? this.selectedAnswers.includes(id)
        : this.selectedAnswers == id;
    },
    isCorrectAnswer(id) {
      const question_answers = this.question.questions_answers;

      let answer = question_answers.find((item) => item.answer_id === id);

      return answer.correct === 1;
    },
    loadComments() {
      const headers = authHeader();

      axios
        .get(`${API_URL}`, {
          headers: headers,
        })
        .then((response) => {})
        .catch((error) => {
          this.$swal("Error", "Comment not created", "error");
        });
    },
    createComment() {
      if (this.comment === "") {
        return;
      }

      this.canSubmitComment = false;

      const headers = authHeader();

      axios
        .post(
          `${API_URL}comments/create`,
          {
            user_id: this.user.id,
            question_id: this.question.id,
            comment: this.comment,
          },
          {
            headers: headers,
          },
        )
        .then((response) => {
          if (response.status === 200) {
            $("#comment").summernote("reset");
            this.comment = "";
            this.canSubmitComment = false;
            this.$swal("Success", "Kommentar gespeichert", "success");
          } else {
            this.canSubmitComment = true;
            this.$swal(
              "Info",
              "Kommentar konnte nicht gespeichert werden",
              "info",
            );
          }
        })
        .catch((error) => {
          this.canSubmitComment = true;
          this.$swal(
            "Error",
            "Kommentar konnte nicht gespeichert werden",
            "error",
          );
        });
    },
    showComments() {
      this.commentVisible = !this.commentVisible;
      window.scrollTo(0, document.body.scrollHeight);
    },
    bookmarkQuestion() {
      const headers = authHeader();

      axios
        .post(
          `${API_URL}question/bookmark`,
          {
            user_id: this.user.id,
            question_id: this.question.id,
          },
          {
            headers: headers,
          },
        )
        .then((response) => {
          if (response.status === 200) {
            this.$emit("bookmark-change", true);
          }
        })
        .catch((error) => {});
    },
  },
  watch: {
    overviewMode: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // Set evaluation mode
          this.evaluationMode = true;
          this.evaluationResult =
            parseInt(this.question.pivot.status) == 2 ? true : false;
        }
      },
    },
    overviewSelectedAnswers: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && this.overviewMode) {
          if (!this.question.has_order) {
            this.selectedAnswers = this.isMultipleChoice
              ? Array.from(newVal)
              : newVal[0];
          } else if (this.question.has_order) {
            this.selectedAnswers = newVal.map((selected_answer) => {
              const answer = this.question.answers.find((a) => a.id === selected_answer);
              const questionAnswer = this.question.questions_answers.find((qa) => qa.answer_id === selected_answer);
              return {
                ...answer,
                order: questionAnswer ? questionAnswer.order : null,
              }
            });
          }
        }
      },
    },
    question: {
      immediate: true,
      handler(val) {
        if (this.overviewMode) return; // This should be evaluated under the overviewSelectedAnswers watcher.

        if (val !== null && this.previousQuestionMode) {
          this.evaluationMode = true;
          this.evaluationResult =
            parseInt(this.question.pivot.status) == 2 ? true : false;

          if (val.has_order) {
            this.selectedAnswers = val.selected_answers.map((selected_answer) => {
              const answer = val.answers.find((a) => a.id === selected_answer);
              const questionAnswer = val.questions_answers.find((qa) => qa.answer_id === selected_answer);
              return {
                ...answer,
                order: questionAnswer ? questionAnswer.order : null,
              }
            });
          } else {
            this.selectedAnswers = this.isMultipleChoice
              ? Array.from(this.question.selected_answers)
              : this.question.selected_answers[0];
          }
        } else if (val !== null && val.has_order) {
          // Order question, use availableDndAnswers to prevent mutating question props
          this.selectedAnswers = val.answers.map((answer) => {
            const questionAnswer = val.questions_answers.find(
              (qa) => qa.answer_id === answer.id,
            );
            return {
              ...answer,
              order: questionAnswer ? questionAnswer.order : null,
            };
          });
        }
      },
    },
  },
  computed: {
    ...mapState(["isAuthenticated", "user"]),
    questionNumberPercentage() {
      return (this.questionNumber / this.totalQuestions) * 100;
    },
    isMultipleChoice() {
      const answers = this.question.questions_answers;
      let expectedCount = answers.filter(
        (answer) => answer.correct === true || answer.correct === 1,
      ).length;
      return expectedCount > 1 ? true : false;
    },
    questionExplanation() {
      if (!this.question.has_order) return this.question.explanation;

      return `<p><b>Die Zahlen in den Elementen geben dir die richtige Reihenfolge an.</b></p><br>${this.question.explanation}`;
    },
  },
  components: {
    ProgressComponent,
    QuestionExplanationComponent,
    draggable,
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
