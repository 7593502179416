<template>
    <div class="topic-component-minimal d-flex flex-column justify-content-center align-items-center">
        <div class="icon">
            <img v-if="iconExists" :src="iconLink" alt="" />
            <img v-else src="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg" alt="" />
        </div>
        <h4 class="sub-heading-4 mt-2 text-center">
            {{ topicName }}
        </h4>
    </div>
</template>
<script>
export default {
    name: 'topic-component-minimal',
    props: {
        topicName: {
            type: String,
            default: "",
        }
    },
    computed: {
        async iconExists() {
            try {
                const response = await fetch(this.iconLink, { method: 'HEAD' });
                // Check if the response status is in the 200 range
                return response.ok
            } catch (error) {
                return false
            }
        },
        iconLink() {
            const iconsUrl = 'https://assets.pharmanavi.de/assets/uploads/app_icons/icons/topic_icons/';
            return iconsUrl + this.topicName.replace(/ /g, '%20') + '.svg'

        }
    },
}
</script>
