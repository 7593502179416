<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="experiment.name !== undefined && experiment.name !== null && experiment.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{experiment.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="experiment.description !== undefined && experiment.description !== null && experiment.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="experiment.description"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="experiment.image_title !== undefined && experiment.image_title !== null && experiment.image_title !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Bildunterschrift</h6>
                    </div>
                    <div><p>{{experiment.image_title}}</p></div>
                    <br/><br/>
                </div>

                <br/>

                <div class="row" v-if="experiment.image !== null && experiment.image !== ''">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <div class="col-lg-12">
                        <image-zoom-component :imageSrc="experiment.image" :imageAlt="experiment.image_subtitle"></image-zoom-component>
                    </div>
                    <br/>  <br/>
                </div>

                <div class="row" v-if="experiment.chemicals.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Chemikalien</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="chemical in experiment.chemicals"
                                     :key="'chemical'+ chemical.chemical.id"
                                     :to="{ name: 'Chemical', params: {id: chemical.chemical.id} }">{{ chemical.chemical.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

                <div class="row" v-if="experiment.materials.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Materialien</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="material in experiment.materials"
                                     :key="'material'+ material.material.id"
                                     :to="{ name: 'Material', params: {id: material.material.id} }">{{ material.material.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

                <div class="row" v-if="experiment.experiments.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Experimente</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="exp in experiment.experiments"
                                     :key="'experiment'+ exp.experiment.id"
                                     :to="{ name: 'Experiment', params: {id: exp.experiment.id} }">{{ exp.experiment.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';
import ImageZoomComponent from "@client/components/ImageZoomComponent.vue";

export default {
    name: "Experiment",
    data() {
        return {
            experiment: {
                id: 0,
                name: '',
                description: '',
                image_title: '',
                image: '',
                chemicals: [],
                experiments: [],
                materials: []
            }
        }
    },
    mounted() {
        this.loadExperiment();
    },
    methods: {
        clearData() {
            this.experiment.id = 0;
            this.experiment.name = "";
            this.experiment.description = "";
            this.experiment.image_title = "";
            this.experiment.image = "";
            this.experiment.chemicals = [];
            this.experiment.experiments = [];
            this.experiment.materials = [];
        },
        loadExperiment() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}experiments/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setExperimentData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setExperimentData(data) {
            this.experiment.id =  data.id;
            this.experiment.name = data.name;
            this.experiment.description = data.description;
            this.experiment.image_title = data.image_title;
            this.experiment.image = data.image;
            this.experiment.experiments = data.connected_experiment;
            this.experiment.chemicals = data.experiments_chemical;
            this.experiment.materials = data.experiments_material;
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.experiment.description) + '</div>'
            }
        }
    },
    components: {
        ImageZoomComponent,
        ImageComponent
    }
}
</script>

<style scoped>

</style>
