<template>
    <div class="col-sm-4 col-md-4 col-lg-4">
        <router-link
            class="button"
            :to="{ name: 'topics.summary', params: { topic: topicId } }"
        >
        <div class="topic-component">
            <div class="row topic d-flex justify-content-center align-items-center">
                <div class="col-sm-12 col-md-4 col-lg-4 icon">
                    <img src="https://assets.pharmanavi.de/assets/uploads/app_icons/icons/topic_icons/Zusammenfassung.svg" alt="">
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8 title">
                    <h4 class="text-center">{{title}}</h4>
                </div>
            </div>
        </div>
        </router-link>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">
            <summary-component :title="'Title'" :topic-id="1"/>
            <summary-component :title="'Title'" :topic-id="1"/>
            <summary-component :title="'Title'" :topic-id="1"/>
        </div>
    </div>
</template>
```
</docs>

<script>
/**
 * For displaying topics and sub topics
 * @displayName SummaryComponent
 */
export default {
    name: "SummaryComponent",
    props: {
        /**
         * Name/Title of topic or subtopic
         * @values string
         */
        title: {
            type: String,
            required: true
        },
        topicId: {
            type: Number,
            required: true
        }
    },
}
</script>

<style scoped>

</style>
