<script>
import {mapState} from "vuex";

import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import router from "../router";

export default {
    name: "VoucherComponent",
    data() {
        return {
            sendingEmail: false
        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user'])
    },
    methods: {
        sendVoucherCode() {
            this.sendingEmail = true;

            axios.put(`${API_URL}user/send_voucher_code`, {}, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.user.voucher_sent = true;
                    } else {
                        this.$swal("Geschafft!", response.data.message, "error");
                    }

                    this.sendingEmail = false;
                })
                .catch(error => {
                    this.sendingEmail = false;
                    this.$swal("Geschafft!", error.response.data.message, "error");
                });
        }
    }
}
</script>

<template>
    <div class="voucher-box text-center" v-if="user !== null">
        <div class="title">
            <template v-if="user.voucher_sent === true">
                <h6>Wir sehen uns auf der <a target="_blank" href="https://www.interpharm.de/">INTERPHARM 2024!</a></h6>
                <p>Wir haben dir deinen Gutscheincode per Mail geschickt und freuen uns darauf dich auf der <a target="_blank" href="https://www.interpharm.de/">INTERPHARM 2024</a> im Rosengarten in Mannheim zu treffen.</p>
            </template>
            <template v-if="user.voucher_sent === false && user.user_verification !== null && user.user_verification.status === 1">
                <h6>Komm zur <a target="_blank" href="https://www.interpharm.de/">INTERPHARM 2024!</a></h6>
                <p>PharmaNavi-Abonnenten erhalten kostenlos Zugang zur <a target="_blank" href="https://www.interpharm.de/">INTERPHARM 2024!</a> im Mannheimer Rosengarten am 12. und 13. April.</p>
                <button class="btn btn-primary" v-on:click="sendVoucherCode">Gutscheincode anfordern</button>
            </template>
            <template v-if="user.user_verification === null">
                <h6>Komm zur <a target="_blank" href="https://www.interpharm.de/">INTERPHARM 2024!</a></h6>
                <p>PharmaNavi-Abonnenten erhalten kostenlos Zugang zur <a target="_blank" href="https://www.interpharm.de/">INTERPHARM 2024</a> im Mannheimer Rosengarten am 12. und 13. April. Du musst nur deinen <router-link :to="{ name: 'profile' }">Studierenden-Status verifizieren</router-link>.</p>
            </template>
          </div>
    </div>
</template>

<style scoped>

</style>
