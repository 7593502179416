<template>
    <div class="col-sm-12 col-md-6 col-xl-4">
        <div class="topic-quiz-component">
            <div class="topic">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4 icon d-flex justify-content-center align-content-center">
                        <img src="https://assets.pharmanavi.de/assets/uploads/app_icons/icons/topic_icons/Zusammenfassung.svg" alt="">
                    </div>
                    <div class="title col-sm-8 col-md-8 col-lg-8">
                        <h4 class="" title="Zusammenfassung">Zusammenfassung</h4>
                    </div>
                </div>
                <br/>

                <div class="row stats">
                    <div class="col-12">
                        <span class="questions">{{summaryCount}} Zusammenfassung</span>
                    </div>
                </div>

                <br/>
                <br/>

                <div class="row links">
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                        <router-link
                            class="button"
                            :to="{ name: 'topics.summaries', params: { topic: summary.topic_id } }"
                        >
                            Unterthemen
                        </router-link>
                    </div>
                    <div class="col-6 col-sm-6 col-md-6 col-lg-6 right">
                        <router-link
                            class="button"
                            :to="{ name: 'topics.summary', params: { topic: summary.topic_id } }"
                        >
                            Zusammenfassung öffnen
                            <i class="fa-solid fa-arrow-right solid"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">

        </div>
    </div>
</template>
```
</docs>

<script>

/**
 * For displaying summary topics
 * @displayName TopicSummaryComponent
 */
export default {
    name: "TopicSummaryComponent",
    props: {
        summary: {
            type: Object,
            default: {},
        },
        summaryCount : {
            type: Number,
            default: 0
        }
    },
    computed: {

    },
    methods: {
        loadSummaries() {
            this.$emit('load-summaries', this.summary.topic_id)
        }
    }
}
</script>

<style scoped>

</style>
