<template>
    <div>
        <div>
            <button v-if="isAuthenticated" @click="logout">Log Out</button>
            <button v-if="isAuthenticated" @click="this.logToken">Token</button>
        </div>
        <h1>This is the notification settings page</h1>
        <h2 v-if="isAuthenticated">Your email is {{user.email}}</h2>

		<div>
            <router-link :to="{ name: 'dashboard' }">Home</router-link> |
			<router-link :to="{ name: 'settings' }">Settings</router-link>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import useTracker from "@client/composables/useTracker";
import router from "../router";

export default{
	name: "notification-settings",
    data() {
        return {

        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user'])
    },
    setup() {
        const { resetTrackedUserId } = useTracker();
        const logout = () => {

            // Reset the tracked user id
            resetTrackedUserId();

            localStorage.clear();

            window.location.reload();
        };

        return {
            logout,
        };
    },
    mounted() {

    },
    beforeMount() {

    },
    methods: {
        logToken () {
            console.log(localStorage.getItem("token"));
        }
    },
}
</script>

<style scoped>

</style>
