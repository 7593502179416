<template>
    <div class="topics-container mt-5">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h3>Fächer</h3>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="breadcrumb">
                        <div class="crumbs">
                            <span
                                class="crumb"
                                :class="{active: hierarchy.length <= 0}"
                                @click="setHierarchy"
                            >
                                Themen
                            </span>
                            <template v-for="level in hierarchy">
                                &nbsp;
                                <i class="fa fa-arrow-right"></i>
                                &nbsp;
                                <span
                                    class="crumb"
                                    :class="{active: isActiveHierarchy(level)}"
                                    @click="setHierarchy(level.order)"
                                >
                                    {{ level.topic.wording }}
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="isLoading">
                <preloader/>
            </div>
            <template v-else-if="isTopicDetail">
                <div class="row">
                    <template v-for="topic in topicDetails" :key="`topic-${topic.id}`">
                        <div class="col-sm-12 col-md-6 col-xl-4">
                            <topic-quiz-component
                                :title="topic.wording"
                                icon="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg"
                                :totalQuestions="topic.questions_count"
                                :premium-questions="topic.premium_questions_count !== undefined ? topic.premium_questions_count : null"
                                :answered="topic.user_performance.attempted"
                                :correct="topic.user_performance.correct"
                                :topic="topic"
                                :has-children="topic.children.length > 0"
                                :has-summaries="false"
                                :isTopicDetail="isTopicDetail"
                                @load-hierarchy="loadTopicHierarchy"
                                :is-sub-topic="topic.level > 3"
                                @load-topic-details="loadTopicDetails"
                                @bookmark-topic="bookmarkTopic"
                            />
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-8">
                            <div class="row">
                                <div class="col-12 col-xl-6" v-if="topicDetails[0].summary !== null || (topicDetails[0].summary_count !== null && topicDetails[0].summary_count > 0)">
                                    <topic-connections-component
                                        :title="'Zusammenfassung'"
                                        icon="Zusammenfassung"
                                        :routeName="'topics.summaries'"
                                        :topicId="selectedTopicId"
                                    />
                                </div>
                                <div class="col-12 col-xl-6" v-if="topicDetails[0] !== undefined && topicDetails[0].learning_units_count > 0">
                                    <topic-connections-component
                                        :title="'Lerneinheit'"
                                        icon="Lerneinheit"
                                        :routeName="'learning.units'"
                                        :topicId="selectedTopicId"
                                    />
                                </div>
                                <div class="col-12 col-xl-6" v-if="topicDetails[0] !== undefined && topicDetails[0].experiments_count > 0">
                                    <topic-connections-component
                                        :title="'Experimente'"
                                        icon="Experimente"
                                        :routeName="'experiments'"
                                        :topicId="selectedTopicId"
                                    />
                                </div>
                                <div class="col-12 col-xl-6" v-if="topicDetails[0] !== undefined && topicDetails[0].collections_count > 0">
                                    <topic-connections-component
                                        :title="'Übungsklausur'"
                                        icon="Übungsklausuren"
                                        :routeName="'collections'"
                                        :topicId="selectedTopicId"
                                    />
                                </div>
                            </div>
                            <!--  -->
                        </div>

                        <!-- <topic-summary-component v-if="topic.summary !== null" :summary="topic.summary" :summaryCount="topic.summary_count"
                                                 @load-summaries="loadTopicSummaries"/> -->
                    </template>
                </div>

                <!-- <div class="row">
                    <topic-connections-component
                        v-if="topicDetails[0] !== undefined && topicDetails[0].learning_units_count > 0"
                        :title="'Lerneinheit'"
                        :icon="require('@icons/icons/60x60/Lerneinheit.svg').default"
                        :routeName="'learning.units'"
                        :topicId="selectedTopicId"
                    />

                    <topic-connections-component
                        v-if="topicDetails[0] !== undefined && topicDetails[0].experiments_count > 0"
                        :title="'Experimente'"
                        :icon="require('@icons/icons/60x60/Experimente.svg').default"
                        :routeName="'experiments'"
                        :topicId="selectedTopicId"
                    />

                    <topic-connections-component
                        v-if="topicDetails[0] !== undefined && topicDetails[0].collections_count > 0"
                        :title="'Übungsklausur'"
                        :icon="require('@icons/icons/60x60/Übungsklausuren.svg').default"
                        :routeName="'collections'"
                        :topicId="selectedTopicId"
                    />
                </div> -->
            </template>
            <div class="row mt-3" v-else>
                <template v-for="topic in topics" :key="topic.id" v-if="hierarchy.length <= 1">
                    <topic-component
                        :title="topic.wording"
                        icon="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg"
                        :topic="topic"
                        @load-hierarchy="loadTopicHierarchy"
                    />
                </template>
                <template v-else v-for="topic in topics" :key="`topic-${topic.id}`">
                    <div class="col-sm-12 col-md-6 col-xl-4">
                        <topic-quiz-component
                            :title="topic.wording"
                            icon="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg"
                            :totalQuestions="topic.questions_count"
                            :premium-questions="topic.premium_questions_count !== undefined ? topic.premium_questions_count : null"
                            :answered="topic.user_performance.attempted"
                            :correct="topic.user_performance.correct"
                            :topic="topic"
                            :has-children="topic.children.length > 0"
                            :is-sub-topic="topic.level > 3"
                            :has-summaries="topic.published_summary !== null"
                            :bookmarked="topic.is_bookmarked"
                            @load-hierarchy="loadTopicHierarchy"
                            @load-topic-details="loadTopicDetails"
                            @bookmark-topic="bookmarkTopic"
                        />
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import TopicComponent from '@client/components/TopicComponent.vue';
import TopicQuizComponent from '@client/components/TopicQuizComponent.vue';
import TopicSummaryComponent from '@client/components/TopicSummaryComponent.vue';
import TopicConnectionsComponent from '@client/components/TopicConnectionsComponent.vue';
import SummaryComponent from '@client/components/SummaryComponent.vue';
import Preloader from "@client/components/Preloader.vue";

export default {
    name: 'topics',
    data() {
        return {
            topic: {},
            topics: [],
            topicDetails: [],
            selectedTopicId: 0,
            hierarchy: [],
            topicSummaries: [],
            isLoading: false,
            isTopicDetail: false,
            isTopicSummaries: false
        }
    },
    beforeMount() {
        if (localStorage.getItem('topics.hierarchy'))
            this.hierarchy = JSON.parse(localStorage.getItem('topics.hierarchy'))
    },
    mounted() {
        if (this.$route.hash !== undefined && this.$route.hash !== "") {
            var topicId = this.$route.hash.slice(1);
            this.getTopicById(topicId);
        } else if (this.hierarchy.length > 0 && this.hierarchy[this.hierarchy.length - 1].topicDetails == true) {
            this.getTopicById(this.hierarchy[this.hierarchy.length - 1].topic.id)
        } else {
            this.loadTopics()
        }
    },
    methods: {
        loadTopics() {
            this.isLoading = true
            this.topics = []

            // First determine the hierarchy
            let hierarchyParam = ''
            if (this.hierarchy.length > 0) {
                let topic = this.hierarchy[this.hierarchy.length - 1].topic;
                hierarchyParam = topic.id
            }

            axios.get(`${API_URL}topic/topics/${hierarchyParam}`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.topics = response.data
                        this.isLoading = false
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        loadTopicHierarchy(topic) {
            let hierarchyOrder = {
                order: this.hierarchy.length,
                topic: topic,
                topicDetails: false,
            };
            this.hierarchy.push(hierarchyOrder);
            localStorage.setItem('topics.hierarchy', JSON.stringify(this.hierarchy))

            this.isTopicDetail = false;
            this.topicDetails = [];
            this.isTopicSummaries = false;
            this.loadTopics();
        },
        setHierarchy(hierarchy_order) {
            this.isTopicDetail = false;
            this.topicDetails = [];
            this.isTopicSummaries = false;
            if (!hierarchy_order) this.hierarchy = [];
            else {
                let index = this.hierarchy.length - 1;

                while (index >= 0) {
                    if (this.hierarchy[index].order === hierarchy_order) break;

                    this.hierarchy.pop()
                    index--;
                }
                localStorage.setItem('topics.hierarchy', JSON.stringify(this.hierarchy));
            }
            let index = this.hierarchy.length - 1;
            if (index >= 0 && this.hierarchy[index].topicDetails) {
                this.loadTopicDetails(this.hierarchy[index].topic, false);
            } else {
                this.loadTopics();
            }
        },
        getHierarchy() {
            return this.hierarchy;
        },
        getTopicById(topicId) {
            axios.get(`${API_URL}topic/${topicId}`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.topic = response.data.topic;
                        console.log(this.topic)
                        console.log(this.hierarchy)
                        this.hierarchy = [];
                        this.topic.hierarchy.forEach(el => this.hierarchy.push({
                            order: this.hierarchy.length,
                            topic: el,
                            topicDetails: true,
                        }));

                        localStorage.setItem('topics.hierarchy', JSON.stringify(this.hierarchy));
                        this.loadTopicDetails(this.topic);
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        loadTopicDetails(topic, appendHierarchy = true) {
            if (appendHierarchy) {
                let hierarchyOrder = {
                    order: this.hierarchy.length,
                    topic: topic,
                    topicDetails: true,
                };
                this.hierarchy.push(hierarchyOrder);
                localStorage.setItem('topics.hierarchy', JSON.stringify(this.hierarchy))
            }

            this.isTopicSummaries = false;
            this.isTopicDetail = true;
            this.selectedTopicId = topic.id;
            axios.get(`${API_URL}topic/${topic.id}`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.topicDetails.push(response.data.topic);
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        loadTopicSummaries(topicId) {
            this.isTopicDetail = false;
            this.topicDetails = [];
            this.isTopicSummaries = true;

            axios.get(`${API_URL}topic/children_summaries/${topicId}`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.topicSummaries = response.data;
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        isActiveHierarchy(hierarchy) {
            return hierarchy.order === this.hierarchy[this.hierarchy.length - 1].order
        },
        bookmarkTopic(topicId) {
            axios.post(`${API_URL}topic/bookmark`, {
                topic_id: topicId
            },{'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.topics.forEach((topic, index) => {
                            if (topicId === topic.id) {
                                this.topics[index].is_bookmarked = !topic.is_bookmarked;
                            }
                        });

                        this.$swal("Success", response.data.message, "success");

                    } else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        }
    },
    components: {
        TopicComponent,
        TopicQuizComponent,
        Preloader,
        TopicSummaryComponent,
        TopicConnectionsComponent,
        SummaryComponent
    }
}
</script>
