<template>
  <div
    class="form-field"
    :class="{
      'password-field': type === 'password',
      'error': hasError,
    }"
  >
    <label :for="id">{{ label }}</label>
    <input
      :type="type"
      class="form-control"
      :name="name"
      :placeholder="placeholder"
      v-model="value"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
      :accept="accept"
      :id="id"
    >
  </div>
</template>

<script>
export default {
  name: "input-component",
  props: {
    name: String,
    id: String,
    label: String,
    placeholder: String,
    type: {
      type: String,
      default: "text"
    },
    value:[String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: ""
    }
  },
}
</script>

<style scoped>
input[id="verification_document"] {
    padding-top: 15px !important;
}
</style>
