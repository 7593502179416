<template>
    <div class="modal fade" id="zoom-image-modal" ref="zoomImageModal" tabindex="-1" aria-labelledby="zoomImageModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <img id="zoom-image" style="width: 100% !important; height: 100% !important; max-width: unset !important;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">
            <image-component/>

            <div class="row" @click="handleImageClicks">
                <img src="https://assets.pharmanavi.de/assets/uploads/Weinsa%CC%88ure-Isomere_(drei).png" style="width: 10%"/>
                <img src="https://assets.pharmanavi.de/assets/uploads/tertbutylether.png" style="width: 10%"/>
                <img src="https://assets.pharmanavi.de/assets/uploads/mol7.jpg" style="width: 10%"/>
                <img src="https://assets.pharmanavi.de/assets/uploads/Knoevenagel.jpg" style="width: 10%"/>
            </div>
        </div>
    </div>
</template>
```
</docs>

<script>
/**
 * A component for zooming in images that are too small.
 * The div or element containing all images must have @click="handleImageClicks" added to it and the image component loaded above it
 * That is all required  for it to work
 * @displayName ImageComponent
 */
export default {
    name: "ImageComponent"
}
</script>

<style scoped>

</style>
