<template>
  <div class="">
    <div class="topic-quiz-component">
      <div class="topic">
        <div class="row">
          <div class="title col-sm-12 col-md-12 col-lg-12" style="max-width: 100%;">
            <h4 :title="title">{{title}}</h4>
          </div>
        </div>
        <br />
        <div class="row stats">
          <div class="col-4 col-sm-4 col-md-4 col-lg-4">
            <span class="questions">{{totalQuestions}} Fragen</span>
          </div>
          <div class="col-8 col-sm-8 col-md-8 col-lg-8 right">
            <span class="answered">{{answered}}</span> <span> beantwortet</span> <span class="percentage">{{ correctPercentage }}%</span> <span>richtig</span>
          </div>
        </div>
        <div class="progress-container">
          <div class="progress">
            <div
              class="progress-bar-correct"
              role="progressbar"
              :style="`width: ${correctPercentage}%`"
              :aria-valuenow="correctPercentage"
              :aria-valuemin="0"
              :aria-valuemax="100"
            ></div>
          </div>
        </div>
        <div class="row links mt-2">
          <div class="col-12 right">
            <router-link
              class="button"
              :class="{'disabled-link': totalQuestions < 12}"
              :to="{ name: 'topics.quiz', params: { topic: topicId, quiz: 'collection', collection: collectionId } }"
            >
              Übung starten
              <i class="fa-solid fa-arrow-right solid"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>    
</template>

<script>
  export default {
    name: 'question-collections-component',
    props: {
      title: {
        type: String,
        required: true,
      },
      totalQuestions: {
        type: Number,
        required: true,
      },
      answered: {
        type: Number,
        required: true,
      },
      correct: {
        type: Number,
        required: true,
      },
      collectionId: {
        type: Number,
        required: true,
      },
      topicId: {
        type: Number,
        required: true,
      }
    },
    computed: {
      correctPercentage() {
        if (parseInt(this.totalQuestions) === 0)
          return 0;

        return Math.round((this.correct / this.totalQuestions) * 100)
      },
      incorrectPercentage(){
        if (parseInt(this.totalQuestions) === 0)
          return 0;
        return Math.round(((this.answered - this.correct) / this.totalQuestions) * 100);
      }
    },
  }
</script>