<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">

                <div class="row mt-4 mb-4">
                    <div class="col">
                        <p class="text-center">
                            Wir haben die Angaben auf dieser Seite zuletzt im Mai 2023 aktualisiert und sorgfältig geprüft. Wir übernehmen jedoch keine Gewährleistung für die Vollständigkeit, Richtigkeit und Genauigkeit der hier gemachten Angaben.
                        </p>
                    </div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.name !== undefined && chemical.name !== null && chemical.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{chemical.name}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.chemical_type_name !== undefined && chemical.chemical_type_name !== null && chemical.chemical_type_name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Typ</h6>
                    </div>
                    <div><p>{{chemical.chemical_type_name}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.description !== undefined && chemical.description !== null && chemical.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="chemical.description"></div>-->
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.formula !== undefined && chemical.formula !== null && chemical.formula !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Summenformel</h6>
                    </div>
                    <component :is="formula"></component>
<!--                    <div @click="handleImageClick" v-html="chemical.formula"></div>-->
                    <br/> <br/>
                </div>

                <div class="row mb-3" v-if="chemical.image !== null && chemical.image !== ''">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <div class="col-lg-12">
                        <image-zoom-component :imageSrc="chemical.image" :imageAlt="chemical.image_subtitle"></image-zoom-component>
                    </div>
                </div>

                <div class="row" v-if="chemical.image_subtitle !== undefined && chemical.image_subtitle !== null && chemical.image_subtitle !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Bildunterschrift</h6>
                    </div>
                    <component :is="image_subtitle"></component>
<!--                    <div @click="handleImageClick" v-html="chemical.image_subtitle"></div>-->
                    <br/> <br/>
                </div>


                <div class="row" v-if="chemical.cas !== undefined && chemical.cas !== null && chemical.cas !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">CAS-Nummer</h6>
                    </div>
                    <div><p>{{chemical.cas}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.molar_mass !== undefined && chemical.molar_mass !== null && chemical.molar_mass !== '' && chemical.molar_mass !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Molekulargewicht</h6>
                    </div>
                    <div><p>{{ this.formatNumber(chemical.molar_mass) }} {{chemical.molar_mass_unit}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.used_as !== undefined && chemical.used_as !== null && chemical.used_as !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Verwendungszweck, Nachweis</h6>
                    </div>
                    <div v-html="chemical.used_as"></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.information !== undefined && chemical.information !== null && chemical.information !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">wichtige Hinweise</h6>
                    </div>
                    <component :is="information"></component>
<!--                    <div @click="handleImageClick" v-html="chemical.information"></div>-->
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.density !== undefined && chemical.density !== null && chemical.density !== '' && chemical.density !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Dichte</h6>
                    </div>
                    <div><p>{{ this.formatNumber(chemical.density) }} {{chemical.density_unit}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.melting_point !== undefined && chemical.melting_point !== null && chemical.melting_point !== '' && chemical.melting_point !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Schmelztemperatur</h6>
                    </div>
                    <div><p>{{ this.formatNumber(chemical.melting_point) }} {{chemical.melting_point_unit}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.desintegration_point !== undefined && chemical.desintegration_point !== null && chemical.desintegration_point !== '' && chemical.desintegration_point !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Zerfallstemperatur</h6>
                    </div>
                    <div><p>{{this.formatNumber(chemical.desintegration_point) }} {{chemical.desintegration_point_unit}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.boiling_point !== undefined && chemical.boiling_point !== null && chemical.boiling_point !== '' && chemical.boiling_point !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Siedepunkt</h6>
                    </div>
                    <div><p>{{ this.formatNumber(chemical.boiling_point) }} {{chemical.boiling_point_unit}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.water_solubility !== undefined && chemical.water_solubility !== null && chemical.water_solubility !== '' && chemical.water_solubility !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Wasserlöslichkeit</h6>
                    </div>
                    <div><p>{{ this.formatNumber(chemical.water_solubility) }} {{chemical.water_solubility_unit}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.general_solubility !== undefined && chemical.general_solubility !== null && chemical.general_solubility !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Löslichkeit</h6>
                    </div>
                    <component :is="general_solubility"></component>
<!--                    <div @click="handleImageClick"><p v-html="chemical.general_solubility"></p></div>-->
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.pks !== undefined && chemical.pks !== null && chemical.pks !== '' && chemical.pks !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">pKs</h6>
                    </div>
                    <div><p>{{ this.formatNumber(chemical.pks) }}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.logp !== undefined && chemical.logp !== null && chemical.logp !== '' && chemical.logp !== '0.00'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">logP</h6>
                    </div>
                    <div><p>{{ this.formatNumber(chemical.logp) }}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="chemical.disposal !== undefined && chemical.disposal !== null && chemical.disposal !== '' && chemical.disposal !== '<p><br></p>'">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Disposal</h6>
                    </div>
                    <component :is="disposal"></component>
<!--                    <div @click="handleImageClick" v-html="chemical.disposal"></div>-->
                    <br/> <br/>
                </div>

                <div class="row"  v-if="chemical.connections.length !== 0">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Chemikalien</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary"
                            v-for="chemical_value in chemical.connections"
                            :key="$route.fullPath"
                            :to="{ name: 'Chemical', params: {id: chemical_value.chemical.id} }"
                            reload
                        >
                            {{ chemical_value.chemical.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

                <div class="row" v-if="chemical.experiments.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Experimente</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="experiment in chemical.experiments"
                                     :key="'experiment'+ experiment.experiment.id"
                                     :to="{ name: 'Experiment', params: {id: experiment.experiment.id} }">{{ experiment.experiment.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

                <div class="row" v-if="chemical.protections.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte H+P-Sätze</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="protection in chemical.protections"
                                     :key="'protection'+ protection.protection.id"
                                     :to="{ name: 'Protection', params: {id: protection.protection.id} }">{{ protection.protection.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

                <div class="row" v-if="chemical.warnings.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Warnstufen</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="warning in chemical.warnings"
                                     :key="'warning'+ warning.warning.id"
                                     :to="{ name: 'Warning', params: {id: warning.warning.id} }">{{ warning.warning.name }}
                        </router-link>
                    </div>
                    <br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
import ImageZoomComponent from "@client/components/ImageZoomComponent.vue";

export default {
    name: "Chemical",
    data() {
        return {
            chemical: {
                id: 0,
                chemical_type_id: 0,
                chemical_type_name: '',
                name: '',
                description: '',
                formula: '',
                image: '',
                image_subtitle: '',
                cas: '',
                molar_mass: 0.0,
                molar_mass_unit: '',
                used_as: '',
                information: '',
                density: 0.0,
                density_unit: '',
                melting_point: 0.0,
                melting_point_unit: '',
                desintegration_point: 0.0,
                desintegration_point_unit: '',
                boiling_point: 0.0,
                boiling_point_unit: '',
                water_solubility: 0.0,
                water_solubility_unit: '',
                general_solubility: '',
                pks: 0.0,
                logp: 0.0,
                disposal: '',
                connections: [],
                experiments: [],
                warnings: [],
                protections: []
            }
        }
    },
    mounted() {
        this.loadChemical();
    },
    methods: {
        clearData() {
            this.chemical.id = 0;
            this.chemical.name = "";
            this.chemical.chemical_type_id = 0;
            this.chemical.description = "";
            this.chemical.formula = '';
            this.chemical.image = "";
            this.chemical.image_subtitle = '';
            this.chemical.cas = "";
            this.chemical.molar_mass = 0.0;
            this.chemical.molar_mass_unit = "";
            this.chemical.used_as = '';
            this.chemical.information = "";
            this.chemical.density = 0.0;
            this.chemical.density_unit = "";
            this.chemical.melting_point = 0.0;
            this.chemical.melting_point_unit = "";
            this.chemical.desintegration_point = 0.0;
            this.chemical.desintegration_point_unit = "";
            this.chemical.boiling_point = 0.0;
            this.chemical.boiling_point_unit = "";
            this.chemical.water_solubility = 0.0;
            this.chemical.water_solubility_unit = "";
            this.chemical.general_solubility = '';
            this.chemical.pks = 0.0;
            this.chemical.logp = 0.0;
            this.chemical.disposal = "";
            this.chemical.connections = [];
            this.chemical.experiments = [];
            this.chemical.warnings = [];
            this.chemical.protections = [];
        },
        loadChemical() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'

            this.clearData();
            axios.get(`${API_URL}chemicals/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setChemicalData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setChemicalData(data) {
            this.chemical.id = data.id;
            this.chemical.name = data.name;
            this.chemical.chemical_type_id = data.chemical_type_id;
            this.chemical.chemical_type_name = data.chemical_type.name;
            this.chemical.description = data.description;
            this.chemical.formula = data.formula;
            this.chemical.image = data.image;
            this.chemical.image_subtitle = data.image_subtitle;
            this.chemical.cas = data.cas;
            this.chemical.molar_mass = data.molar_mass;
            this.chemical.molar_mass_unit = data.molar_mass_u === null ? "" : data.molar_mass_u.value;

            this.chemical.used_as = data.used_as;
            this.chemical.information = data.information;
            this.chemical.density = data.density;
            this.chemical.density_unit = data.density_u === null ? "" : data.density_u.value;

            this.chemical.melting_point = data.melting_point;
            this.chemical.melting_point_unit = data.melting_point_u === null ? "" : data.melting_point_u.value;

            this.chemical.desintegration_point = data.desintegration_point;
            this.chemical.desintegration_point_unit = data.desintegration_point_u === null ? "" : data.desintegration_point_u.value;

            this.chemical.boiling_point = data.boiling_point;
            this.chemical.boiling_point_unit = data.boiling_point_u === null ? "" : data.boiling_point_u.value;

            this.chemical.water_solubility = data.water_solubility;
            this.chemical.water_solubility_unit = data.water_solubility_u === null ? "" : data.water_solubility_u.value;

            this.chemical.general_solubility = data.general_solubility;
            this.chemical.pks = data.pks;
            this.chemical.logp = data.logp;
            this.chemical.disposal = data.disposal;

            this.chemical.connections = data.connected_chemical;
            this.chemical.experiments = data.chemicals_experiment;
            this.chemical.protections = data.chemicals_protection;
            this.chemical.warnings = data.chemicals_warning;
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        description() {
            return {
                template: '<div v-html="\''+ this.addImageZoom(this.chemical.description) + '\'"></div>'
            }
        },
        formula() {
            return {
                template: '<div>'+ this.addImageZoom(this.chemical.formula) + '</div>'
            }
        },
        image_subtitle() {
            return {
                template: '<div>'+ this.addImageZoom(this.chemical.image_subtitle) + '</div>'
            }
        },
        information() {
            return {
                template: '<div>'+ this.addImageZoom(this.chemical.information) + '</div>'
            }
        },
        general_solubility() {
            return {
                template: '<div>'+ this.addImageZoom(this.chemical.general_solubility) + '</div>'
            }
        },
        disposal() {
            return {
                template: '<div>'+ this.addImageZoom(this.chemical.disposal) + '</div>'
            }
        }
    },
    components: {
        ImageComponent,
        VueImageZoomer,
        ImageZoomComponent
    },
    watch: {
        $route(to, from) {
            // Check if the route parameter has changed
            if (to.params.id !== from.params.id && to.name === 'Chemical') {
                // Perform any necessary actions here, such as fetching new data
                // or updating the component's state
                this.loadChemical()
            }
        }
    }
}
</script>

<style scoped>

</style>
