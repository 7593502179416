<template>
  <div class="progress-component">
    <p v-if="progressLabel.trim().length > 0" class="progress-label">
      {{progressLabel}}
    </p>
    <div class="progress">
      <div 
        class="progress-bar" 
        role="progressbar" 
        :style="'width: '+currentValue+'%;'"
        :aria-valuenow="currentValue" 
        :aria-valuemin="minValue" 
        :aria-valuemax="maxValue"
      ></div>
    </div>
  </div>
</template>

<docs>
```vue
  </template>
    <div class="row">
      <div class="col-4">
        <progress-component
          :min-value="0"
          :max-value="100"
          :current-value="10"
        />
      </div>
    </div>
  </template>
```
</docs>


<script>
/**
 * This is the progress component for displaying progress bars
 * @displayName ProgressComponent
 */
export default {
  name: "progress-component",
  props: {
    /**
     * The maximum value of the progress bar
     * @type {Number}
     * @default 100
     */
    maxValue: {
      type: Number,
      default: 100,
    },

    /**
     * The minimum value of the progress bar
     * @type {Number}
     * @default 0
     */
    minValue: {
      type: Number,
      default: 0,
    },

    /**
     * The current value to be indicated by the progress bar
     * @type {Number}
     * @required
     * @default 0
     */
    currentValue: {
      type: Number,
      required: true,
      default: 0,
    },

    /**
     * The label for the progress bar for example "Counter..."
     * @type {String}
     * @default ""
     */
    progressLabel: {
      type: String,
      default: "",
    }
  }
}
</script>