<template>
    <div class="col-sm-12 col-md-6 col-xl-4">
        <div class="topic-quiz-component">
            <div class="topic">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-4 icon d-flex justify-content-center align-content-center">
                        <img :src="icon" alt="Wichtige Fragen"/>
                    </div>
                    <div class="title col-sm-8 col-md-8 col-lg-8">
                        <h4 class="" style="cursor: auto;" title="Wichtige Fragen">Wichtige Fragen</h4>
                    </div>
                </div>
                <br/>
                <div class="row stats">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4">
                        <span class="questions">{{totalQuestions}} Fragen</span>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8 right">
                        <span class="answered">{{answered}}</span> <span> beantwortet</span> <span class="percentage">{{ correctPercentage }}%</span> <span>richtig</span>
                    </div>
                </div>


                <div class="progress-container">
                    <div class="progress">
                        <div
                            class="progress-bar-correct"
                            role="progressbar"
                            :style="`width: ${correctPercentage}%`"
                            :aria-valuenow="correctPercentage"
                            :aria-valuemin="0"
                            :aria-valuemax="100"
                        ></div>
                        <div
                            class="progress-bar-answered"
                            role="progressbar"
                            :style="`width: ${incorrectPercentage}%`"
                            :aria-valuenow="incorrectPercentage"
                            :aria-valuemin="0"
                            :aria-valuemax="100"
                        ></div>
                    </div>
                </div>

                <div class="row links mt-2">
                    <div class="col-12 col-lg-12 right">
                        <router-link
                            class="button"
                            to="/user_quiz"
                        >
                            Übung starten
                            <i class="fa-solid fa-arrow-right solid"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">
            <saved-question-quiz-component
                                  :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"
                                  :totalQuestions="200"
                                  :answered="10"
                                  :correct="2"
            />
            <saved-question-quiz-component
                                  :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"
                                  :totalQuestions="150"
                                  :answered="15"
                                  :correct="5"
            />
            <saved-question-quiz-component
                                  :icon="'https://assets.pharmanavi.de/assets/uploads/e5f87a044523be5b7a71680fc1546c66.png'"
                                  :totalQuestions="120"
                                  :answered="120"
                                  :correct="120"
            />

            <saved-question-quiz-component
                                  :totalQuestions="100"
                                  :answered="50"
                                  :correct="25"
             icon=""/>
        </div>
    </div>
</template>
```
</docs>

<script>

/**
 * For displaying quiz topics
 * @displayName SavedQuestionQuizComponent
 */
export default {
    name: "SavedQuestionQuizComponent",
    props: {
        /**
         * The link to the topic icon. Sub topics will use main topic link.
         * @values url
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Total number of questions for a topic
         * @values Number
         */
        totalQuestions: {
            type: Number,
            required: true
        },
        /**
         * Total number of questions answered for a topic
         * @values Number
         */
        answered: {
            type: Number,
            required: true
        },
        /**
         * Percentage of questions answered correctly for a topic
         * @values Number
         */
        correct: {
            type: Number,
            required: true
        }
    },
    computed: {
        correctPercentage() {
            if (parseInt(this.totalQuestions) === 0)
                return 0;

            return Math.round((this.correct / this.answered) * 100)
        },
        incorrectPercentage(){
            if (parseInt(this.totalQuestions) === 0)
                return 0;


            return Math.round(((this.answered - this.correct) / this.answered) * 100);
        }
    },
    components: {

    },
    methods: {

    }
}
</script>

<style scoped>

</style>
