<script>
import {defineComponent} from 'vue'
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";

export default defineComponent({
    name: "Summaries",
    data() {
        return {
            summaries: [],
        }
    },
    mounted() {
        this.loadSummaries();
    },
    methods: {
        loadSummaries() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}topic/hierarchy_summaries/${this.$route.params.topic}`, {
                headers: headers
            })
            .then(response => {
                if (response.status === 200) {
                    this.summaries = response.data.hierarchy_summaries;
                    this.summaries.sort((a, b) => {
                        return a.package_access === b.package_access ? 0 : a.package_access ? -1 : 1;
                    });
                }  else {
                    this.$swal("Error", response.data.message, "error");
                }
            })
            .catch(error => {

                this.$swal("Error", "An Error Occurred. Try again later!", "error");
            });
        },
    }
})
</script>

<template>
    <div class="container pt-3 search-container">
        <div class="row">
            <div class="col-12 col-sm-12 offset-md-3 col-md-6">
                <h1>Zusammenfassung</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-12 mx-auto">
                <template 
                    v-for="summary in summaries"
                    :key="'summary'+ summary.id"
                >
        
                    <router-link
                        v-if="summary.package_access"
                        class="search-link" 
                        :to="{ name: 'topics.summary', params: { topic: summary.id } }"
                    >
                        {{ summary.wording }}
                    </router-link>
                    <a href="#" v-else class="search-link" style="opacity: 0.7;" disabled="true">
                        {{ summary.wording }}
                        <span class="tag tag-secondary mt-1">
                            Premium
                        </span>
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
