<template>
    <div>
        <router-link :to="{ name: routeName, params: { topic: topicId } }">
            <div class="topic-component">
                <div class="row topic d-flex justify-content-center align-items-center">
                    <div class="col-sm-12 col-md-4 col-lg-4 icon" v-if="iconExists">
                        <img :src="iconLink" :alt="title" />
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-8 title">
                        <h4 class="text-center">{{ title }}</h4>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">
     </div>
    </div>
</template>
```
</docs>

<script>
/**
 * For displaying topics and sub topics
 * @displayName TopicConnectionsComponent
 */
export default {
    name: "TopicConnectionsComponent",
    props: {
        /**
         * The link to the topic icon. Sub topics will use main topic link.
         * @values url
         */
        icon: {
            type: String,
            required: true
        },
        /**
         * Name/Title of connection
         * @values string
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Id of topic
         * @values Number
         */
        topicId: {
            type: Number,
            required: true
        },
        /**
         * Name of route
         * @values String
         */
        routeName: {
            type: String,
            required: true
        }
    },
    computed: {
        async iconExists() {
            try {
                const response = await fetch(this.iconLink, { method: 'HEAD' });
                // Check if the response status is in the 200 range
                return response.ok

            } catch (error) {
                return false
            }
        },
        iconLink() {
            const iconsUrl = 'https://assets.pharmanavi.de/assets/uploads/app_icons/icons/topic_icons/';
            return iconsUrl + this.icon.replace(/ /g, '%20') + '.svg'
        }

    }
}
</script>

<style scoped></style>
