<template>
  <div class="premium-ad-box text-center" id="showBrowser">
    <div class="title">
      <h4>Dieser Inhalt steht nur mit PharmaNavi Premium zur Verfügung.</h4>
      <p>Damit hast du vollen Zugriff auf alle Inhalte und kommst so schneller ans Ziel.</p>
    </div>
  </div>
</template>

<script>
export default{
  name: 'PremiumRestrictedContent'
}
</script>