<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="teemischung.name !== undefined && teemischung.name !== null && teemischung.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{teemischung.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="teemischung.description !== undefined && teemischung.description !== null && teemischung.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="teemischung.description"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="teemischung.pictures !== undefined && teemischung.pictures !== null && teemischung.pictures !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <component :is="pictures"></component>
<!--                    <div @click="handleImageClick" v-html="teemischung.pictures"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="teemischung.drugs.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Drogen</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="drug in teemischung.drugs"
                                     :key="'drug'+ drug.drug.id"
                                     :to="{ name: 'Drug', params: {id: drug.drug.id} }">{{ drug.drug.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';

export default {
    name: "Teemischungen",
    data() {
        return {
            teemischung: {
                id: 0,
                name: '',
                pictures: '',
                description: '',
                drugs: [],
            },
        }
    },
    mounted() {
        this.loadTeemischung();
    },
    methods: {
        clearData() {
            this.teemischung.id = 0;
            this.teemischung.name = "";
            this.teemischung.description = "";
            this.teemischung.pictures = '';
            this.teemischung.drugs = [];
        },
        loadTeemischung() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}teemischungs/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setTeemischungData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setTeemischungData(data) {
            this.teemischung.id = data.id;
            this.teemischung.name = data.name;
            this.teemischung.description = data.description;
            this.teemischung.pictures = data.pictures;
            this.teemischung.drugs = data.drugs;
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        pictures() {
            return {
                template: '<div>'+ this.addImageZoom(this.teemischung.pictures) + '</div>'
            }
        },
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.teemischung.description) + '</div>'
            }
        }
    },
    components: {
        ImageComponent
    }
}
</script>

<style scoped>

</style>
