<template>
    <div class="survey-component">
        <div class="row">
            <div class="col">
                <h3 v-if="currentQuestion.length > 0" class="text-center">
                    {{ currentQuestion }}
                </h3>
            </div>
        </div>

        <select-item
            v-for="answer in formattedAnswers"
            :key="`select-survey-answer-${answer.value}`"
            :item="answer"
            :selected="selectedAnswers.includes(answer.value)"
            @item-selected="selectAnswer"
        />

        <div class="row mt-3 mb-3">
            <div class="col d-flex flex-column justify-content-center">
                <button
                    class="btn btn-primary mb-2"
                    @click.prevent="saveAndContinue"
                    :disabled="selectedAnswers.length <= 0"
                >
                    Weiter &nbsp;
                    <i class="fa fa-solid fa-arrow-right"></i>
                </button>
                <button
                    class="btn btn-secondary mb-3"
                    @click.prevent="moveToNext"
                >
                    Weiter ohne Auswahl &nbsp;
                    <i class="fa fa-solid fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";
import SelectItem from "@client/components/welcome-tour/selectItem.vue";

const router = useRouter();
const surveyQuestions = ref([]);
const currentQuestion = ref("");
const currentAnswers = ref([]);
const questionIndex = ref(0);
const selectedAnswers = ref([]);
const welcomePageCount = ref(3);

const updateWelcomeStateCount = async (count) => {
    const store = useStore();
    await store.commit("updateWelcomeMode", {
        isWelcomeMode: true,
        count: count,
    });
};

const formattedAnswers = computed(() => {
    return currentAnswers.value.map((answer) => ({
        name: answer.answer,
        value: answer.id,
    }));
});

const loadSurveyQuestions = async () => {
    axios
        .get(`${API_URL}survey_questions`, { headers: await authHeader() })
        .then((response) => {
            if (response.status === 200) {
                if (response.data.survey_questions.length > 0) {
                    surveyQuestions.value = response.data.survey_questions;
                    assignQuestion()
                }
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

const assignQuestion = () => {
    currentQuestion.value = surveyQuestions.value[questionIndex.value].question;
    currentAnswers.value = surveyQuestions.value[questionIndex.value].answers;
};

const selectAnswer = (answer) => {
    const index = selectedAnswers.value.indexOf(answer.value);
    if (index > -1) {
        selectedAnswers.value.splice(index, 1);
    } else {
        selectedAnswers.value.push(answer.value);
    }
};

const saveAndContinue = async () => {
    const data = {
        question_id: surveyQuestions.value[questionIndex.value].id,
        answers: selectedAnswers.value,
    };

    axios
        .post(`${API_URL}survey_questions/user_answer`, data, {
            headers: await authHeader(),
        })
        .then((response) => {
            if (response.status == 200) {
                moveToNext();
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

const moveToNext = () => {
    if (questionIndex.value + 1 >= surveyQuestions.value.length)
        return router.push({ name: "welcome.newsletter" });

    selectedAnswers.value = [];

    welcomePageCount.value += 1;
    questionIndex.value += 1;
    assignQuestion();
};

onMounted(async () => {
    await loadSurveyQuestions();
});

onBeforeMount(async () => {
    await updateWelcomeStateCount(welcomePageCount);
});
</script>
