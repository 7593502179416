import UserService from "../service/user.service";
import { createStore } from "vuex";

const authModule = {
    state() {
        return{
            isAuthenticated: false,
            isLoading: true,
            user: null,
            isAdmin: false,
            isContentCreator: false,
            isStudent: false,
            welcomeTour: {
                isWelcomeMode: false,
                count: 0,
            }
        };
    },
    actions: {
        getUserData({ commit }, email) {
            return UserService.getUserByEmail(email).then(
                response => {
                    commit("updateUserState", response.data);
                    return response;
                },
                error => {
                    commit("getUserByEmailError");
                    return error;
                }
            )
        },
        registerUserDevice({ commit }, data) {
            return UserService.registerUserDevice(data).then(
                response => {
                    return response;
                },
                error => {
                    return error;
                }
            )
        },
        errorOccurred({ commit }) {
            commit("errorOccurred")
        }
    },
    mutations: {
        updateUserState(state, user) {
            state.user = user.user;
            state.isAuthenticated = true;
            state.isLoading = false;

            state.user.roles.forEach((item) => {
               if (item.role.name === "admin") {
                   state.isAdmin = true;
               }

               if (item.role.name === "content") {
                   state.isContentCreator = true;
               }

                if (item.role.name === "student") {
                    state.isStudent = true;
                }
            });
        },
        getUserByEmailError(state) {
            state.isLoading = false;
            console.log("Error getting user by email");
        },
        errorOccurred(state) {
            state.isLoading = false;
        },
        updateWelcomeMode(state, data) {
            state.welcomeTour.isWelcomeMode = data.isWelcomeMode;
            state.welcomeTour.count = data.count;
        }
    }
};

const authStore = createStore(authModule);

export {authModule, authStore};
