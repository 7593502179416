<template>
    <div class="quiz-overview-component">
        <template v-if="overview.hasOwnProperty('questions')">
            <div class="row my-4">
                <div class="col">
                    <h1>Deine Antworten</h1>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="accordion" id="overview-accordion">
                        <div class="accordion-item" v-for="question in overview.questions"
                             :key="`overview-${question.question.id}`">
                            <h2
                                class="accordion-header d-flex flex-row align-items-center"
                                :id="`accordion-header-${question.question.id}`"
                            >
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="`#accordion-body-${question.question.id}`"
                                    aria-expanded="true"
                                    :aria-controls="`accordion-body-${question.question.id}`"
                                >
                                    <p v-html="truncateHtml(decodeHtml(question.question.wording), 50)"></p>
                                </button>
                            </h2>
                            <div
                                :id="`accordion-body-${question.question.id}`"
                                class="accordion-collapse collapse"
                                :aria-labelledby="`accordion-header-${question.question.id}`"
                            >
                                <div class="accordion-body">
                                    <saved-question-component
                                        :question="question.question"
                                        :quiz-or-exam-mode="false"
                                        :overview-mode="true"
                                        :overview-selected-answers="question.question.answers"
                                    />

                                    <div class="row">
                                        <div class="col text-center">
                        <span class="bookmark">
                  <i @click.prevent="unBookmarkQuestion(question.question.id)" class="fa-bookmark fa-solid text-secondary"></i>
                  </span>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row mt-3">
                <div class="col">
                    <preloader/>
                </div>
            </div>
        </template>
    </div>
</template>
<script>

import Preloader from '@client/components/Preloader.vue';
import SavedQuestionComponent from '@client/components/SavedQuestionComponent.vue';
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import {mapState} from "vuex";

export default {
    name: 'saved-question-overview-component',
    props: {
        overview: {
            type: Object,
            default: {},
        }
    },
    data() {
        return {
            // overview: {}
        }
    },
    methods: {
        unBookmarkQuestion(question_id) {
            const headers = authHeader();

            axios.post(`${API_URL}question/bookmark`, {
                user_id: this.user.id,
                question_id: question_id
            },{
                headers: headers
            }).then((response) => {
                if (response.status === 200) {
                    this.$emit('unbookmark-change', question_id);
                }
            }).catch(error => {
            });
        }
    },
    watch: {},
    computed: {
        ...mapState(['user']),
    },
    components: {
        Preloader,
        SavedQuestionComponent,
    }
}

</script>
