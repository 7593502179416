<template>
    <div class="container pt-3 search-container">
        <div class="row">
            <div class="col-12 col-sm-12 offset-md-3 col-md-6">
                <h1>Suche</h1>
            </div>
        </div>
        <div class="row">
            <form @submit.prevent="submitSearch">
                <div class="col-xs-12 col-sm-12 offset-md-3 col-md-6">
                    <div class="input-group mb-3">
                        <input v-model="input.searchText" type="text" class="form-control form-control-lg"
                               placeholder="nach Ion, Chemikalie, Gerät..." aria-label="Search text"
                               aria-describedby="search-button">
                        <button class="btn btn-outline-secondary" type="submit" id="search-button">Suche</button>
                    </div>
                </div>
            </form>
        </div>

        <div class="row">
            <div class="col-xs-12 col-sm-12 offset-md-3 col-md-6">
                <a-z-index-component :selected-range="input.range" @rangeSelected="searchRange"/>
            </div>
        </div>

        <br/>

        <div v-if="searching === false" class="row">
            <div class="col-md-6 col-sm-12 mx-auto" v-if="results != null">
                <template v-if="results.chemicals.length !== 0">
                    <h3 class="search-title" id="chemical">Chemikalien</h3>
                    <router-link class="search-link" v-for="result in results.chemicals"
                                 :key="'chemical'+ result.id" :to="{ name: 'Chemical', params: {id: result.id} }">
                        {{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.drugs.length !== 0">
                    <h3 class="search-title" id="drug">Drogen</h3>
                    <router-link class="search-link" v-for="result in results.drugs" :key="'drug'+ result.id"
                                 :to="{ name: 'Drug', params: {id: result.id} }">{{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.experiments.length !== 0">
                    <h3 class="search-title" id="experiment">Experimente</h3>
                    <router-link class="search-link" v-for="result in results.experiments"
                                 :key="'experiment'+ result.id"
                                 :to="{ name: 'Experiment', params: {id: result.id} }">{{ result.name }}
                        ({{ result.topic.wording }})
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.materials.length !== 0">
                    <h3 class="search-title" id="material">Materialien</h3>
                    <router-link class="search-link" v-for="result in results.materials"
                                 :key="'material'+ result.id" :to="{ name: 'Material', params: {id: result.id} }">
                        {{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.plant_families.length !== 0">
                    <h3 class="search-title" id="plant_family">Pflanzenfamilien</h3>
                    <router-link class="search-link" v-for="result in results.plant_families"
                                 :key="'plant_family'+ result.id"
                                 :to="{ name: 'PlantFamily', params: {id: result.id} }">{{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.plant_wirkstoffs.length !== 0">
                    <h3 class="search-title" id="plant_wirkstoff">Pflanzliche Wirkstoffe</h3>
                    <router-link class="search-link" v-for="result in results.plant_wirkstoffs"
                                 :key="'plant_wirkstoff'+ result.id"
                                 :to="{ name: 'PlantWirkstoff', params: {id: result.id} }">{{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.plants.length !== 0">
                    <h3 class="search-title" id="plant">Pflanzen</h3>
                    <router-link class="search-link" v-for="result in results.plants" :key="'plant'+ result.id"
                                 :to="{ name: 'Plant', params: {id: result.id} }">{{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.protections.length !== 0">
                    <h3 class="search-title" id="protection">H+P-Sätze</h3>
                    <router-link class="search-link" v-for="result in results.protections"
                                 :key="'protection'+ result.id"
                                 :to="{ name: 'Protection', params: {id: result.id} }">{{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.teemischungs.length !== 0">
                    <h3 class="search-title" id="teemischungen">Teemischungen</h3>
                    <router-link class="search-link" v-for="result in results.teemischungs"
                                 :key="'teemischungen'+ result.id"
                                 :to="{ name: 'Teemischungen', params: {id: result.id} }">{{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.warnings.length !== 0">
                    <h3 class="search-title" id="warning">Warnstufen</h3>
                    <router-link class="search-link" v-for="result in results.warnings" :key="'warning'+ result.id"
                                 :to="{ name: 'Warning', params: {id: result.id} }">{{ result.name }}
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.summaries.length !== 0">
                    <h3 class="search-title" id="summary">Zusammenfassung</h3>
                    <router-link class="search-link" v-for="result in results.summaries" :key="'summary'+ result.id"
                                 :to="{ name: 'topics.summary', params: {topic: result.id} }">{{ result.wording }}
                        <span v-if="result.parent !== undefined && result.parent !== null">({{ result.parent }})</span>
                    </router-link>
                    <br/>
                </template>

                <template v-if="results.learning_units.length !== 0">
                    <h3 class="search-title" id="learning_unit">Lernheiten</h3>
                    <router-link class="search-link" v-for="learning_unit in results.learning_units"
                                 :key="'learning_unit'+ learning_unit.id"
                                 :to="{ name: 'LearningUnit', params: {id: learning_unit.id} }">
                        {{ learning_unit.wording }}
                    </router-link>

                    <br/>
                </template>

                <div v-if="results.chemicals.length === 0 && results.drugs.length === 0 && results.experiments.length === 0 && results.materials.length === 0 && results.plant_families.length === 0 && results.plant_wirkstoffs.length === 0 && results.plants.length === 0 && results.protections.length === 0 && results.teemischungs.length === 0 && results.warnings.length === 0 && results.summaries.length === 0 && results.learning_units.length === 0">
                    <h4 class="text-center no-results">Für deine Suche haben wir leider keine Ergebnisse gefunden.</h4>
                </div>
            </div>

        </div>
        <div v-else class="row">
            <preloader/>
        </div>
    </div>
</template>

<script>
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import AZIndexComponent from '@client/components/AZIndexComponent.vue';
import Preloader from "@client/components/Preloader.vue";

export default {
    name: 'search',
    data() {
        return {
            searching: false,
            input: {
                searchText: "",
                range: ""
            },
            results: null
        }
    },

    mounted() {
        this.input.searchText = this.getCookie("searchText") === "" ? "" : this.getCookie("searchText");
        this.input.range = this.getCookie("searchRange") === "" ? "" : this.getCookie("searchRange");

        if (this.input.searchText !== "") {
            this.submitSearch();
        }

        if (this.input.range !== "") {
            this.searchRange(this.input.range);
        }
    },
    methods: {
        searchRange(value) {
            this.searching = true;
            this.input.range = value;

            var rangeData = {
                range: this.input.range
            };

            axios.post(`${API_URL}general/search_range`, rangeData, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        this.searching = false;
                        this.results = response.data;
                        this.setCookie("searchRange", this.input.range);
                        this.setCookie("searchText", "");

                        this.$nextTick(() => this.scrollToPreviousPosition());
                    } else {
                        this.searching = false;
                        this.results = [];
                    }
                })
                .catch(error => {
                    this.searching = false;
                    this.results = [];
                });
        },
        submitSearch() {
            this.searching = true;
            if (this.$matomo) {
                this.$matomo.trackEvent('Search', 'search', this.input.searchText);
            }

            var searchString = this.input.searchText === "" ? false : this.input.searchText;

            if (searchString !== false) {
                var searchData = {
                    name: searchString
                };

                axios.post(`${API_URL}general/search_content`, searchData, {'headers': authHeader()})
                    .then(response => {
                        if (response.status === 200) {
                            this.searching = false;
                            this.results = response.data;
                            this.setCookie("searchRange", "");
                            this.setCookie("searchText", this.input.searchText);
                            this.$nextTick(() => this.scrollToPreviousPosition());
                        } else {
                            this.searching = false;
                            this.results = [];
                        }
                    })
                    .catch(error => {
                        this.searching = false;
                        console.log(error)
                    });
            } else {
                this.searching = false;
                this.results = null;
            }
        },
        scrollToPreviousPosition() {
            if (this.$router.options.history.state.forward !== null) {
                var route = this.$router.options.history.state.forward;
                route = route.substring(1).split("/")[0];

                if (document.getElementById("#" + route)) {
                    document.getElementById("#" + route).scrollIntoView();
                }
            }
        }
    },
    components: {
        AZIndexComponent,
        Preloader
    },
}
</script>

<style scoped>

</style>
