import axios from "axios";
import {API_URL} from "../conf";
import authHeader from "../auth.header";

class UserService {
    getUserByEmail(email) {
        return axios.get(API_URL + "user/get_by_email/" + email, {headers: authHeader()});
    }

    async registerUserDevice(data) {
        let ip_address;
        await this.getClientIp().then(response => {
            ip_address = response.ip
        });

        return axios.post(API_URL + "user/register_user_device", {
            user_id: data.user_id,
            user_agent: data.user_agent,
            platform: data.platform,
            last_login: data.last_login,
            device_token: data.device_token,
            token: data.token,
            ip_address: ip_address
        }, {headers: authHeader()})
    }

    async getClientIp() {
        return (await fetch('https://api.ipify.org?format=json')).json()
    }
}

export default new UserService();
