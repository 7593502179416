<template>
    <div class="container">
        <!-- <h4 class="text-center text-danger">
            Hier kannst du demnächst die Schwarmintelligenz aller PharmaNavi-User anzapfen. Du kommst mit einem Problem einfach gerade nicht weiter? In einer Zusammenfassung oder Frage bei uns findest du etwas komisch? Poste es hier und wir können es demnächst alle gemeinsam diskutieren. Im Moment kannst du uns ein Feedback schicken, dass nicht für andere User sichtbar sein wird.
            <br/><br/>
            Beta-Version dieses Feature ist leider noch nicht verfügbar.
        </h4> -->
        <div class="row mt-5">
            <div class="col">
                <div class="text-center">
                    <form @submit.prevent="submitFeedBack">
                        <textarea name="" id="" cols="30" rows="10" placeholder="Feedback" v-model="feedBack"></textarea>
                        <br>
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import useTracker from "@client/composables/useTracker";
import router from "../router";
export default{
	name: 'feedback',
    data() {
        return {
			feedBack: '',
        }
    },
    computed: {
        ...mapState(['isAuthenticated', 'user'])
    },
    setup() {
        const { resetTrackedUserId } = useTracker();
        const logout = () => {

            // Reset the tracked user id
            resetTrackedUserId();

            localStorage.clear();

            window.location.reload();
        };

        return {
            logout,
        };
    },
    mounted() {

    },
    beforeMount() {

    },
    methods: {
        logToken () {
            console.log(localStorage.getItem("token"));
        },
		submitFeedBack() {

			if (this.$matomo) {
				this.$matomo.trackEvent('FeedBack', 'Submit');
			}

			// _paq.push(['trackEvent', 'Menu', 'Freedom']);

			const headers = authHeader();
            headers['content-type'] = 'application/json'

			const data = {
				comment: this.feedBack
			};

            axios.post(`${API_URL}feedback/store`,data, {
                headers: headers
            })
			.then(response => {
                console.log(response)
				if (response.status === 201 && response.data.message == 'created') {
					alert('Feedback gesendet!');
					this.feedBack = '';
				} else {
					alert('Feedback failed, try again!');
				}
			})
			.catch(error => {
				alert('Feedback failed, try again!');
			});
		}
    },
}
</script>

<style scoped>

</style>
