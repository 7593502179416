<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="plant.name !== undefined && plant.name !== null && plant.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{plant.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="plant.latin_name !== undefined && plant.latin_name !== null && plant.latin_name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">lateinischer Name</h6>
                    </div>
                    <div><p>{{ plant.latin_name }}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="plant.description !== undefined && plant.description !== null && plant.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="plant.description"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="plant.pictures !== undefined && plant.pictures !== null && plant.pictures !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <component :is="pictures"></component>
<!--                    <div @click="handleImageClick" v-html="plant.pictures"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="plant.plant_families.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Pflanzenfamilien</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="plant_family in plant.plant_families"
                                     :key="'plant_family'+ plant_family.plant_family.id"
                                     :to="{ name: 'PlantFamily', params: {id: plant_family.plant_family.id} }">{{ plant_family.plant_family.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';

export default {
    name: "Plant",
    data() {
        return {
            plant: {
                id: 0,
                name: '',
                latin_name: '',
                pictures: '',
                description: '',
                plant_families: [],
            },
        }
    },
    mounted() {
        this.loadPlant();
    },
    methods: {
        clearData() {
            this.plant.id = 0;
            this.plant.name = "";
            this.plant.latin_name = "";
            this.plant.description = "";
            this.plant.pictures = '';
            this.plant.plant_families = [];
        },
        loadPlant() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}plants/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setPlantData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setPlantData(data) {
            this.plant.id = data.id;
            this.plant.name = data.name;
            this.plant.latin_name = data.latin_name;
            this.plant.description = data.description;
            this.plant.pictures = data.pictures;
            this.plant.plant_families = data.plant_families
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        pictures() {
            return {
                template: '<div>'+ this.addImageZoom(this.plant.pictures) + '</div>'
            }
        },
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.plant.description) + '</div>'
            }
        }
    },
    components: {
        ImageComponent
    }
}
</script>

<style scoped>

</style>
