<template>
  <div class="stats-box">
    <div class="stats-progress">
      <div class="mt-2 progress" ref="progress"></div>
      <p class="progress-label">{{currentValue}}% erreicht</p>
    </div>
    <div class="stats-label">
      {{statLabel}}
    </div>
  </div>
</template>

<docs>
```vue
  <template>
    <div class="row mx-5">
      <div class="col-2">
        <stats-box :current-value="42" stat-label="Insgesamt" />
      </div>
      <div class="col-2">
        <stats-box :current-value="63.3" stat-label="Thema" />
      </div>
    </div>
  </template>
```
</docs>

<script>

import ProgressBar from 'progressbar.js'

/**
 * This is the component for statistics box with a circular progress indicator
 * @displayName StatsBox
 */
export default {
  name: 'stats-box',
  data() {
    return {
      progressBar: null,
    }
  },
  props: {
    /**
     * The current value of the progress circle
     * @type {Number}
     * @default 0
     */
    currentValue: {
      type: Number,
      required: true,
      default: 0,
    },

    /**
     * The label for the statistics box
     * @type {String}
     * @default ""
     */
    statLabel: {
      type: String,
      default: "",
    }
  },
  mounted() {
    this.progressBar = new ProgressBar.Circle(this.$refs.progress, {
      color: '#CB4099',
      trailColor: '#D6DBDE',
      strokeWidth: 10,
      trailWidth: 10,
      easing: 'easeInOut',
      duration: 1400,
      text: {
        autoStyleContainer: false
      },
    });
    this.progressBar.animate(this.animationValue())
  },
  methods: {
    animationValue() {
      return parseInt(this.currentValue) / 100
    }
  },
  watch: {
    currentValue(newValue, oldValue) {
      if (this.progressBar !== null) this.progressBar.animate(this.animationValue())
    }
  }
}
</script>

<style scoped>

</style>