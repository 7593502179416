<template>
    <div class="container">
        <div class="clever-reach-form">
            <form
                style="display: none"
                id="clever-reach-form"
                action="https://seu1.cleverreach.com/f/258367-233664/wcs/"
                method="post"
                target="_blank"
            >
                <input name="1255262" v-model="input.first_name" />
                <input name="1255263" v-model="input.last_name" />
                <input name="email" v-model="input.email" />
                <input
                    type="checkbox"
                    name="1257953[]"
                    value="zu Aktionen von PharmaNavi"
                    v-model="input.von"
                />
                <input
                    type="checkbox"
                    name="1257953[]"
                    value="zu Produkten des Deutschen Apotheker Verlags"
                    v-model="input.terms"
                />
            </form>

            <form>
                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-4">
                        <input-component
                            name="first_name"
                            id="first_name"
                            label="Vorname"
                            placeholder="Vorname"
                            type="text"
                            :value="input.first_name"
                            v-model="input.first_name"
                        />
                    </div>
                </div>

                <br />

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-4">
                        <input-component
                            name="last_name"
                            id="last_name"
                            label="Nachname"
                            placeholder="Nachname"
                            type="text"
                            :value="input.last_name"
                            v-model="input.last_name"
                        />
                    </div>
                </div>

                <br />

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-4">
                        <input-component
                            name="email"
                            id="email"
                            label="Email *"
                            placeholder="Emailadresse eingeben"
                            type="email"
                            :value="input.email"
                            v-model="input.email"
                        />
                    </div>
                </div>

                <br />

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-4">
                        <h6>
                            Zu welchen speziellen Themen möchtest du Infos
                            erhalten?
                        </h6>
                    </div>
                </div>

                <br />

                <div class="row">
                    <div class="col-12 col-md-6 col-xl-4 offset-md-4">
                        <div class="form-check terms-container">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="terms-check"
                                :value="input.von"
                                v-model="input.von"
                            />
                            <label class="form-check-label" for="terms-check">
                                <span>zu Aktionen von PharmaNavi</span>
                            </label>

                            <br /><br />

                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="news-letter-check"
                                :value="input.terms"
                                v-model="input.terms"
                            />
                            <label
                                class="form-check-label"
                                for="news-letter-check"
                            >
                                <span>
                                    zu Produkten des Deutschen Apotheker Verlags
                                </span>
                            </label>
                        </div>
                    </div>
                </div>

                <br />

                <div class="row mt-3 mb-3">
                    <div class="col d-flex flex-column justify-content-center">
                        <button
                            class="btn btn-primary mb-2"
                            @click.prevent="saveAndContinue"
                            :disabled="!validateEmail(input.email)"
                        >
                            Anmelden &nbsp;
                            <i class="fa fa-solid fa-arrow-right"></i>
                        </button>
                        <button
                            class="btn btn-secondary mb-3"
                            @click.prevent="moveToNext"
                        >
                            Weiter ohne den Newsletter zu abonnieren &nbsp;
                            <i class="fa fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";
import InputComponent from "@client/components/InputComponent.vue";

const router = useRouter();
const { proxy } = getCurrentInstance();

const input = ref({
    first_name: "",
    last_name: "",
    email: "",
    von: false,
    terms: false,
});
const formSubmitted = ref(false);

const saveAndContinue = () => {
    axios
        .post(`${API_URL}subscribe_news_letter`, {
            status: input.value.von,
            email: input.value.email,
        })
        .then((response) => {
            formSubmitted.value = false;

            if (response.status === 200) {
                document.getElementById("clever-reach-form").submit();
                moveToNext();
            } else {
                proxy.$swal("Info", response.data.message, "info");
            }
        })
        .catch((error) => {
            if (error.response.data.errors !== undefined) {
                if (error.response.data.errors.email !== undefined) {
                    proxy.$swal(
                        "Error",
                        "Die Emailadresse ist nicht möglich!",
                        "error",
                    );
                    this.formSubmitted = false;
                }
                return;
            }

            proxy.$swal(
                "Error",
                "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!",
                "error",
            );
            this.formSubmitted = false;
        });
    moveToNext();
};

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

const moveToNext = () => {
    return router.push({ name: "welcome.complete" });
};

onBeforeMount(async () => {
    const store = useStore();
    await store.commit("updateWelcomeMode", {
        isWelcomeMode: true,
        count: 5,
    });
});
</script>
