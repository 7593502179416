<template>
    <div class="tour-complete-wrapper">
        <div class="row">
            <div class="col">
                <div class="tour-complete-component">
                    <img
                        src="https://assets.pharmanavi.de/assets/uploads/app_icons/icons/done.svg"
                        alt=""
                    />
                    <h6 class="text-center">Geschaft!</h6>
                    <p class="description-text">
                        Perfekt, vielen Dank für deine Angaben. Jetzt viel Spaß
                        bem Erkunden von PharmaNavi.
                    </p>
                    <button
                        class="btn btn-primary btn-sm"
                        @click.prevent="hideWelcomeTour"
                    >
                        Los geht's
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";

const store = useStore();
const router = useRouter();

const hideWelcomeTour = async () => {
    await axios.patch(
        `${API_URL}user/hide_welcome_tour`,
        {},
        { headers: await authHeader() },
    );
    // reset the welcometour store state
    await store.commit("updateWelcomeMode", {
        isWelcomeMode: false,
        count: 0,
    });
    router.push({ name: "dashboard" });
};

onBeforeMount(async () => {
    // set the welcome state counters
    await store.commit("updateWelcomeMode", {
        isWelcomeMode: true,
        count: 6,
    });
});
</script>
