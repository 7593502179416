<template>
<div class="container-fluid">
    <br/><br/>
    <div class="row">
            <h3 class="text-center">Passwort vergessen</h3>
            <div class="col-12 col-sm-12 offset-md-4 col-md-4 offset-lg-4 col-lg-4">
                <input-component
                    name="email"
                    id="email"
                    label="Gib deine Email ein, um das Passwort zurückzusetzen."
                    placeholder="Email"
                    type="email"
                    :value="input.email"
                    v-model="input.email"
                />
                <br/>

                <div class="text-center">
                    <button class="btn btn-primary" v-bind:class="{ disabled: formSubmitted}" v-on:click="sendPasswordResetEmail">
                        Absenden
                    </button>
                </div>

            </div>
    </div>
</div>
</template>

<script>

import InputComponent from '@client/components/InputComponent.vue';
import {API_URL} from "@/conf.js";
import router from "../router";

export default {
    name: "ForgotPassword",
    data() {
        return {
            input: {
                email: "",
            },
            formSubmitted: false
        }
    },
    methods: {
        sendPasswordResetEmail() {
            this.formSubmitted = true;

            axios.post(`${API_URL}forgotten_password`, {
                email: this.input.email
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$swal("Geschafft!", response.data.message, "success")
                            .then((result) => {
                                if (result.isConfirmed) {
                                    router.replace({path: '/login'})
                                }
                            });
                        this.input.email = "";
                    } else {
                        this.$swal("Info", response.data.message, "info");
                    }

                    this.formSubmitted = false;
                }).catch(error => {
                this.$swal("Error", "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!", "error");
                this.formSubmitted = false;
            });
        }
    },
    components: {
        InputComponent
    }
}
</script>

<style scoped>

</style>
