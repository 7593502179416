<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="material.name !== undefined && material.name !== null && material.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{material.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="material.description !== undefined && material.description !== null && material.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="material.description"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="material.image_subtitle !== undefined && material.image_subtitle !== null && material.image_subtitle !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Bildunterschrift</h6>
                    </div>
                    <div><p>{{material.image_subtitle}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="material.image !== null && material.image !== ''">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <div class="col-lg-12">
                        <image-zoom-component :imageSrc="material.image" :imageAlt="material.image_subtitle"></image-zoom-component>
                    </div>
                    <br/>   <br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';
import ImageZoomComponent from "@client/components/ImageZoomComponent.vue";

export default {
    name: "Material",
    data() {
        return {
            material: {
                id: 0,
                name: '',
                description: '',
                image_subtitle: '',
                image: ''
            },
        }
    },
    mounted() {
        this.loadMaterial();
    },
    methods: {
        clearForm() {
            this.material.id = 0;
            this.material.name = "";
            this.material.description = "";
            this.material.image_subtitle = "";
            this.material.image = "";
        },
        loadMaterial() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}materials/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearForm();
                    if (response.status === 200) {
                        this.material.id =  response.data.id;
                        this.material.name = response.data.name;
                        this.material.description = response.data.description;
                        this.material.image = response.data.image;
                        this.material.image_subtitle = response.data.image_subtitle;
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.material.description) + '</div>'
            }
        }
    },
    components: {
        ImageZoomComponent,
        ImageComponent
    }
}
</script>

<style scoped>

</style>
