<template>
    <div class="container-fluid">
        <br/><br/>
        <div class="row">
                <h3 class="text-center"></h3>
        </div>
    </div>
</template>

<script>

import InputComponent from '@client/components/InputComponent.vue';
import {API_URL} from "@/conf.js";
import router from "../router";

export default {
    name: "VerifyStudent",
    data() {
        return {

        }
    },
    mounted() {
        this.verifyStudent();
    },
    methods: {
        verifyStudent() {

            axios.post(`${API_URL}verify_student`, {
                token: this.$route.params.token
            })
                .then(response => {
                    router.replace({path: '/profile'})
                }).catch(error => {
                router.replace({path: '/profile'})
                });
        }
    },
    components: {
        InputComponent
    }
}
</script>

<style scoped>

</style>
