/*$(document).ready(function() {
    $('#comment').on('summernote.change', function() {
        if ($('#comment').summernote('isEmpty')) {
            $('.btn-textarea').attr('disabled', true);
        } else {
            $('.btn-textarea').attr('disabled', false);
        }
    });
});*/

//Image upload progress bar
function _(el) {
    return document.getElementById(el);
}
function loader(image) {
    var getUrl = window.location;
    var saveUrl = getUrl.protocol+"//"+getUrl.host+"/comments/saveImageFile/";

    var formData = new FormData();
    formData.append("file", image);
    if (image.type === "image/png" || image.type === "image/jpg" || image.type === "image/jpeg" || image.type === "image/gif") {
        var ajax = new XMLHttpRequest();
        ajax.upload.addEventListener("progress", progressHandler, false);
        ajax.addEventListener("load", completeHandler, false);
        ajax.addEventListener("error", errorHandler, false);
        ajax.addEventListener("abort", abortHandler, false);
        ajax.open('POST', saveUrl);
        ajax.send(formData);
    }
}

function progressHandler(event) {
    var percent = (event.loaded / event.total) * 100;
    _("progressBar").value = Math.round(percent);
    _("status").innerHTML = Math.round(percent) + "% Hochgeladen...";
    $("#status").show().fadeOut(10000);
}

function completeHandler() {
    _("progressBar").value = 0; //will clear progress bar after successful upload
}

function errorHandler() {
    _("status").innerHTML = "Upload fehlgeschlagen.. Bitte erneut versuchen!";
    $("#status").show().fadeOut(10000);
}

function abortHandler() {
    _("status").innerHTML = "Upload abgebrochen.. Bitte versuchen Sie es mit einer anderen Datei";
    $("#status").show().fadeOut(10000);
}

//Image upload function
function uploadImage(image) {
    var getUrl = window.location;
    var saveUrl = getUrl.protocol+"//"+getUrl.host+"/comments/saveImageFile/";
    var file = new FormData();
    file.append("file", image);
    if (image.type === "image/png" || image.type === "image/jpg" || image.type === "image/jpeg" || image.type === "image/gif") {
        $('.btn-textarea').attr('disabled', true);
        $.ajax({
            url: saveUrl,
            type: 'POST',
            data: file,
            success: function (data) {
                var responseText = JSON.parse(data);
                if (responseText.length !== 1){
                    var fileLink = "https://assets.pharmanavi.de/assets/uploads/comments/"+responseText;
                    $(".textarea").summernote('insertImage', fileLink);
                    $("img").addClass("img-responsive");
                }
                $('.btn-textarea').attr('disabled', false);
            },
            cache: false,
            contentType: false,
            processData: false
        }).fail(function() {
            console.log("failed");
        });
    }
    else {
        $('#errorMsgAlert').slideDown().fadeOut(10000);
        document.getElementById('errorMsg').innerText = "Invalider Dateityp! Nur png, jpg, jpeg und gif sind erlaubt!";
    }
}

export function textArea() {
    $('.texteditor').summernote({
        placeholder: '...',
        height: 140,
        focus: false,
        undo: true,
        redo: true,
        shortcuts: false,
        codeviewFilter: false,
        toolbar: [
            ['lang', ['specialChar']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['style', ['style']],
            ['font', ['bold', 'italic', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['fontsize', ['fontsize']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph', 'style']],
            ['height', ['height']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'hr', 'math', 'specialchars', 'linker', 'responsivePicture']],
            ['view', ['fullscreen', 'codeview']],
            ['help', ['help']],
            ['cleaner', ['cleaner']]
        ],
        styleTags: [ 'p', 'h1', 'h2', 'h3', 'h4' ],
        lang: "de-DE",
        popover: {
            image:[
                ['custom', ['responsivePicture']],
                ['image', ['resizeFull']]
            ],
            table: [
                ['add', ['addRowDown', 'addRowUp', 'addColLeft', 'addColRight']],
                ['delete', ['deleteRow', 'deleteCol', 'deleteTable']],
            ]
        },
        callbacks: {
            onImageUpload: function(image) {
                loader(image[0]);
                uploadImage(image[0]);
            },
            onPaste: function (e) {
                var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                e.preventDefault();
                var div = $('<div />');
                div.append(bufferText);
                div.find('*').removeAttr('style');
                setTimeout(function () {
                    document.execCommand('insertHtml', false, div.html());
                }, 10);
            },
            onKeyup: function(e) {
                $('.textarea').summernote('formatPara');
            }
        }
    });
}
