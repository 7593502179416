<template>
    <div
         :class="{ 'col-sm-12 col-md-6 col-lg-6 col-xl-4': itemsCount > 3,
         'col-sm-12 col-md-6 col-lg-6': (itemsCount === 3 && (itemIndex === 0 || itemIndex === 1)) || itemsCount === 2,
         'col-sm-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3': itemsCount === 3 && (itemIndex === 2) }">
        <div class="video-component">
            <div class="row content">
                <div class="image" :class="{ 'col-12 col-sm-12 col-md-12 col-lg-12': itemsCount > 3, 'col-12 col-sm-12 col-md-6 col-lg-6': itemsCount < 4 }">
                    <img :src="image" :alt="title"/>
                    <router-link class="btn btn-secondary link" :to="{path: link}"><i class="fa-regular fa-circle-play"></i></router-link>
                </div>
                <div class="title" :class="{ 'col-12 col-sm-12 col-md-12 col-lg-12': itemsCount > 3, 'col-12 col-sm-12 col-md-6 col-lg-6': itemsCount < 4 }">
                    <br/>
                    <h6 class="text-left">{{this.truncateHtml(title, 39)}}</h6>
                    <p class="description" v-html="this.truncateHtml(description, 80)"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<docs>
```vue
<template>
    <div class="container">
        <div class="row">
            <video-component :title="'Title'"
                               :image="'https://assets.pharmanavi.de/assets/uploads/home-area-pharmanavi-1.png'"
                               :description="'This is a simple description.'"
                               :link="'/#'"
                               :itemsCount="4"
            />

            <video-component :title="'Title'"
                               :image="'https://assets.pharmanavi.de/assets/uploads/home-area-pharmanavi-1.png'"
                               :link="'/#'"
                               :description="'This is a simple description.'"
                               :itemsCount="3"
                               :itemIndex="0"
            />
        </div>
    </div>
</template>
```
</docs>

<script>
/**
 * For displaying videos with title, description and link
 * @displayName VideoComponent
 */
export default {
    name: "VideoComponent",
    props: {
        /**
         * The total number of items
         * @values url
         */
        itemsCount: {
            type: Number,
            required: true
        },
        /**
         * The total number of items
         * @values url
         */
        itemIndex: {
            type: Number,
            required: false
        },
        /**
         * The link to video thumbnail
         * @values url
         */
        image: {
            type: String,
            required: true
        },
        /**
         * Title of video
         * @values string
         */
        title: {
            type: String,
            required: true
        },
        /**
         * Description of video
         * @values string
         */
        description: {
            type: String,
            required: false
        },
        /**
         * Route to link to video
         * @values string
         */
        link: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
