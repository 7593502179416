<template>
  <div class="container quiz-container mt-4">
    <h1>Quiz <span v-if="topic.wording !== undefined">- {{ topic.wording }}</span> </h1>
    <template v-if="blocked">
      <section class="quiz-mode-selection my-4">
        <div class="row my-4">
          <div class="col text-center">
            <h3>Heute findet der erste Teil des Staatsexamens statt. Daher müssen wir unsere Prüfungsfragen im Moment blockieren. Später sind kannst du wieder wie gewohnt Üben.</h3>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="quiz-mode-selection my-4" v-if="topicId">
        <div class="row my-4" v-if="!subject.hasOwnProperty('id') && !quiz.hasOwnProperty('id')">
          <div class="col text-center">
            <i class="fas fa-info-circle"></i>
            <h3>Auswahlmodus</h3>
          </div>
          <div class="row mt-4">
            <div class="col d-flex align-items-center justify-content-center">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="test-mode" id="test-mode-quiz" v-model="quizMode" value="Quiz">
                <label class="form-check-label" for="test-mode-quiz">
                  <p>Quizmodus - direkte Rückmeldung (teils mit Erklärung) zu jeder Frage</p>
                </label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex align-items-center justify-content-center">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="test-mode" id="test-mode-exam" v-model="quizMode" value="Exam">
                <label class="form-check-label" for="test-mode-exam">
                  <p>Klausurmodus - erreichte Punktzahl und Erklärungen erst nach Beantwortung aller Fragen</p>
                </label>
              </div>
            </div>
          </div>
          <div class="row" v-if="isStex === true">
            <div class="col d-flex align-items-center justify-content-center">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="stex-terms" id="stex-terms" v-model="acceptStexTerms" value="Stex">
                <label class="form-check-label" for="stex-terms">
                  <p>Die Prüfungsaufgaben sind urheberrechtlich geschützt. Inhaber der Urheberrechte an den Aufgaben ist das Institut für medizinische und pharmazeutische Prüfungsfragen (IMPP) in 55116 Mainz. Ohne Zustimmung des IMPP ist jede Nutzung der Aufgaben außerhalb der engen Grenzen des Urheberrechtsgesetzes unzulässig.</p>
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col d-flex align-items-center justify-content-center">
              <button
                  class="btn btn-primary"
                  :class="{ disabled: isStex && !acceptStexTerms}"
                  @click.prevent="startQuiz"
              >
                Übung starten
                <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="row my-4" v-else>
          <div class="col" v-if="!complete">
            <question-component
                :question="question"
                :quiz-or-exam-mode="true"
                :exam-mode="this.quizMode === 'Exam'"
                :total-questions="total_questions"
                :question-number="current_count"
                :quiz-id="quiz.id"
                :previous-question-mode="previousQuestionMode"
                :user-question="user_question"
                @next-question="nextQuestion"
                @previous-question="previousQuestion"
                @track-success-rate="trackSuccessRate"
                @bookmark-change="bookmarkQuestion"
            />
          </div>
          <div class="col" v-else>
            <quiz-overview-component
                @restart-quiz="startQuiz"
                :overview="overview"
            />
          </div>
        </div>
      </section>
      <template v-else>
        <div class="row my-4" v-if="current_count == 0 && !previewMode">
          <div class="col">
            <button
                class="btn btn-primary"
                @click.prevent="startQuiz"
            >
              Starte ein zufälliges Quiz
              &nbsp;
              <i class="fa fa-arrow-right"></i>
            </button>

            <!-- <button
              class=" mx-2 btn btn-primary"
              @click.prevent="setPreviewMode"
            >
              Preview Question
            </button> -->
          </div>
        </div>
        <div v-else class="row my-4">
          <div class="col" v-if="previewMode">
            <template v-if="Object.entries(question).length <= 0">
              <input-component
                  name="question-id"
                  id="question-id"
                  label="Question ID"
                  placeholder="Question"
                  type="number"
                  :value="previewQuestionId"
                  v-model="previewQuestionId"
              />
              <button
                  class="mt-2 btn btn-primary"
                  @click.prevent="loadPreviewQuestion"
              >
                Preview
              </button>
            </template>
            <template v-else>
              <question-component
                  :question="question"
                  :quiz-or-exam-mode="false"
              />
              <button
                  class="mt-2 btn btn-primary"
                  @click.prevent="exitPreviewMode"
              >
                Exit
              </button>
            </template>
          </div>
          <div v-else class="col">
            <question-component
                :question="question"
                :quiz-or-exam-mode="true"
                :total-questions="total_questions"
                :question-number="current_count"
                :previous-question-mode="previousQuestionMode"
                :user-question="user_question"
                @next-question="nextQuestion"
                @previous-question="previousQuestion"
                @track-success-rate="trackSuccessRate"
                @bookmark-change="bookmarkQuestion"
            />
          </div>
        </div>
      </template>
    </template>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import useTracker from "@client/composables/useTracker";
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import QuestionComponent from "@client/components/QuestionComponent.vue";
import InputComponent from '@client/components/InputComponent.vue';
import QuizOverviewComponent from '@client/components/QuizOverviewComponent.vue';
import "katex/dist/katex.css";

export default{
  name: 'quiz',
  data() {
    return {
      question: {},
      topicId: null,
      topic: {},
      collectionId: null,
      quiz: {},
      quizMode: 'Quiz',
      isStex: false,
      acceptStexTerms: false,
      subject: {},
      total_questions: 12,
      current_count: 0,
      previewMode: false,
      previewQuestionId: 0,
      previousQuestionMode: false,
      complete: false,
      overview: {},
      blocked: false,
      user_question: null
    }
  },
  computed: {
    ...mapState(['isAuthenticated', 'user'])
  },
  setup() {
    const {trackTopicQuestion } = useTracker();

    return {
      trackTopicQuestion,
    };
  },
  mounted() {
    if (this.$route.params.topic) {
      this.topicId = parseInt(this.$route.params.topic)
        this.loadTopic(this.topicId);
    }

      if (this.$route.params.collection) {
          this.collectionId = parseInt(this.$route.params.collection)
      }

    if (this.$route.params.quiz && Number.isInteger(parseInt(this.$route.params.quiz))) {
      console.log('Here!!!');
        var headers = authHeader();
        headers['content-type'] = 'application/json';
      // Load quiz data
            axios.get(`${API_URL}quiz/${parseInt(this.$route.params.quiz)}`, {headers: headers})
      .then(response => {
        if (response.status === 200) {
          this.subject = response.data.subject
          this.quiz = response.data.quiz
          this.quizMode = this.quiz.mode
          this.total_questions = response.data.total_questions
          this.question = response.data.question
          this.current_count = response.data.attempted_questions
          this.user_question = response.data.user_question

          this.current_count++

          // Track viewing the question in it's topic custom dimension
          const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
          this.trackTopicQuestion('Attempt', this.subject.wording, topicWording)
        } else {
          console.log(response)
          alert('Something went wrong!')
        }
      })
      .catch(error => {
        console.log(error)
      });
    }

  },
  beforeMount() {
    this.checkBlockedExam();
  },
  methods: {
    logToken () {
      this.$matomo && this.$matomo.trackEvent('Token', 'logged');
    },
    loadTopic(topicId) {
        var headers = authHeader();
        headers['content-type'] = 'application/json';

        axios.get(`${API_URL}topic/get/${topicId}`, {'headers': headers})
            .then(response => {
                if (response.status === 200) {
                    this.topic = response.data;

                    if (this.topic.first_parent !== null && this.topic.first_parent.id === 10) {
                        this.isStex = true;
                    }
                }
            })
            .catch(error => {
                console.debug(error)
            });
    },
    loadQuizQuestion() {
      this.subject = null;
      this.question = {}
      if (this.current_count >= this.total_questions) {
        this.current_count = 0
        return
      }

        var headers = authHeader();
        headers['content-type'] = 'application/json';

      axios.get(`${API_URL}quiz/dummy/question`, {'headers': headers})
      .then(response => {
        if (response.status === 200) {
          this.question = response.data.question
          this.subject = response.data.topic
          this.current_count++

          // Track viewing the question in it's topic custom dimension
          const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
          this.trackTopicQuestion('Attempt', this.subject.wording, topicWording)
        }
      })
      .catch(error => {
        console.debug(error)
      });
    },
    startQuiz() {
      if (this.topicId) {
        this.complete = false
        this.quiz = {}
        this.current_count = 0
        this.question = {}
        this.overview = {}
        this.subjejct = {}

        var headers = authHeader();
        headers['content-type'] = 'application/json';

        axios.post(`${API_URL}quiz/start/${this.topicId}/${this.collectionId}`,{
          topic: this.topicId,
          mode: this.quizMode,
        }, {'headers': headers})
        .then(response => {
          if (response.status === 200) {
            this.subject = response.data.subject
            this.quiz = response.data.quiz
            this.quizMode = this.quiz.mode
            this.total_questions = response.data.total_questions
            this.question = response.data.question
            this.user_question = response.data.user_question
            this.current_count++

            // Track viewing the question in it's topic custom dimension
            const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
            this.trackTopicQuestion('Attempt', this.subject.wording, topicWording);
          }
        })
        .catch(error => {
          console.log(error)
        });
      } else {
        this.loadQuizQuestion();
      }
    },
    nextQuestion() {
        window.scrollTo(0, 0);

      if (!this.quiz.hasOwnProperty('id'))
        return this.loadQuizQuestion()

      if (this.current_count >= this.total_questions) {
        this.complete = true;
        return this.loadOverviewData();
      }

      this.previousQuestionMode = false;
      axios.get(`${API_URL}quiz/${this.quiz.id}/question/${this.current_count}/next`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.question = response.data.question
          this.previousQuestionMode = parseInt(this.question.pivot.status) === 0 ? false : true
          this.user_question = response.data.user_question
          this.current_count++
          // Track viewing the question in it's topic custom dimension
          const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
          this.trackTopicQuestion('Attempt', this.subject.wording, topicWording)
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    previousQuestion() {
        window.scrollTo(0, 0);

      if (!this.quiz.hasOwnProperty('id'))
        return this.loadQuizQuestion();

      // decrement the current count
      this.current_count -= 2

      axios.get(`${API_URL}quiz/${this.quiz.id}/question/${this.current_count}/next`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.question = response.data.question
          this.previousQuestionMode = true
          this.user_question = response.data.user_question
          this.current_count++
          // Track viewing the question in it's topic custom dimension
          const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
          this.trackTopicQuestion('Attempt', this.subject.wording, topicWording)
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    loadOverviewData() {
      axios.get(`${API_URL}quiz/${this.quiz.id}/overview`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.overview = response.data.overview
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    setPreviewMode() {
      this.previewMode = !this.previewMode
    },
    trackSuccessRate(status) {
      const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
      this.trackTopicQuestion(status, this.subject.wording, topicWording);
    },
    loadPreviewQuestion() {
      axios.get(`${API_URL}quiz/question/${this.previewQuestionId}`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.question = response.data.question
          this.subject = response.data.topic
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    exitPreviewMode() {
      this.previewMode = false
      this.previewQuestionId = 0
      this.question = {}
      this.subject = null
    },
    checkBlockedExam() {
      axios.get(`${API_URL}quiz/status/check_blocked_exam`, {'headers': authHeader()})
          .then(response => {
            if (response.status === 200) {
              this.blocked = response.data.blocked;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    bookmarkQuestion() {
      this.user_question = this.user_question === null ? {id: this.question.id} : null;
    }
  },
  components: {
    QuestionComponent,
    InputComponent,
    QuizOverviewComponent,
  }
}
</script>
