import useTracker from "./useTracker";

export default function useLogout() {

  function logout() {
    const { resetTrackedUserId } = useTracker();

    localStorage.clear();
    resetTrackedUserId();

    const appEnv = process.env.MIX_APP_ENV;
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("native_app="))
      ?.split("=")[1];
    const url = "https://staging-app.pharmanavi.de";

    if (appEnv === "production") 
      url = "https://pharmanavi.de/";

    if (cookieValue === "yes" || appEnv === "local")
      window.location.reload();
    else
      window.location.replace(url);
  }

  return logout;
}
