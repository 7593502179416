import { createStore } from "vuex";

export const store = createStore({
  state() {
    return {
      customDimension: {
        id: null,
        value: "",
      },
      isWelcomeTour: false,
      welcomeTourProgress: {
        pageCount: 0,
      }
    };
  },
  mutations: {
    updateSetCustomDimesion(state, customDimension) {
      state.customDimension.id = customDimension.id;
      state.customDimension.value = customDimension.value;
    }
  }
})
