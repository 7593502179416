<template>
    <div class="container faq">
        <div class="row">
            <div class="col-lg-12">

                <h2 class="text-center text-primary">FAQ-PharmaNavi</h2>

                <accordion-component>
                    <template v-slot:accordion-items>
                        <div class="accordion-item" v-for="faq in faqs" :key="faq.id">
                            <h2 class="accordion-header" :id="'panelsStayOpen-heading' + faq.id">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#panelsStayOpen-collapse' + faq.id" aria-expanded="false" :aria-controls="'panelsStayOpen-collapse' + faq.id">
                                    {{faq.question}}
                                </button>
                            </h2>
                            <div :id="'panelsStayOpen-collapse' + faq.id" class="accordion-collapse collapsed collapse" :aria-labelledby="'panelsStayOpen-headingOne' + faq.id">
                                <div class="accordion-body">
                                    <p>{{faq.answer}}</p>
                                </div>
                            </div>
                        </div>
                    </template>
                </accordion-component>

                <div class="videos">
                    <template v-for="video in video_faqs" :key="video.id">
                        <p>{{video.description}}</p>

                        <div v-if="video.vimeo_video_id != null" class="video-container mb30" style="position: relative; padding-bottom: 56.25%; padding-top: 30px; height: 0; overflow: hidden;">
                            <iframe v-bind:src="'https://player.vimeo.com/video/' + video.vimeo_video_id" width="640" height="399" frameborder="0"
                                    allow="autoplay; fullscreen" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" showinfo="false"></iframe>
                        </div>
                        <br/><br/>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import AccordionComponent from '@client/components/AccordionComponent.vue';


export default {
    name: "FAQ",
    data() {
        return {
            faqs: [],
            video_faqs: []
        }
    },
    mounted() {
        this.loadFaqs();
        this.loadVideoFaqs();
    },
    methods: {
        loadFaqs() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}faqs`, {
                headers: headers
            })
                .then(response => {
                    if (response.status === 200) {
                        this.faqs = response.data.faqs;
                    }  else {
                        this.$swal("Error", "Faq questions not loaded.", "error");
                    }
                })
                .catch(error => {
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        loadVideoFaqs() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}video_faqs`, {
                headers: headers
            })
                .then(response => {
                    if (response.status === 200) {
                        this.video_faqs = response.data.video_faqs;
                    }  else {
                        this.$swal("Error", "Video Faqs not loaded.", "error");
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        }
    },
    components: {
        AccordionComponent
    }
}
</script>


<style scoped>
.faq{
    padding-bottom: 50px;
}
.videos {
    padding-top: 50px;
}
</style>
