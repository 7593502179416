<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="drug.name !== undefined && drug.name !== null && drug.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{drug.name}}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="drug.latin_name !== undefined && drug.latin_name !== null && drug.latin_name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">lateinischer Name</h6>
                    </div>
                    <div><p>{{ drug.latin_name }}</p></div>
                    <br/> <br/>
                </div>

                <div class="row" v-if="drug.description !== undefined && drug.description !== null && drug.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="drug.description"></div>-->
                    <br/> <br/>
                </div>

                <div class="row" v-if="drug.uses !== undefined && drug.uses !== null && drug.uses !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Anwendung(en)</h6>
                    </div>
                    <component :is="uses"></component>
<!--                    <div @click="handleImageClick" v-html="drug.uses"></div>-->
                    <br/> <br/>
                </div>

                <div class="row" v-if="drug.pictures !== undefined && drug.pictures !== null && drug.pictures !== ''">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <div class="col-lg-12">
                        <component :is="pictures"></component>
<!--                        <div @click="handleImageClick" v-html="drug.pictures"></div>-->
                    </div>
                    <br/>  <br/>
                </div>

                <div class="row" v-if="drug.plants.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Pflanzen</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="plant in drug.plants"
                                     :key="'plant'+ plant.plant.id"
                                     :to="{ name: 'Plant', params: {id: plant.plant.id} }">{{ plant.plant.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

                <div class="row" v-if="drug.plant_families.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfte Pflanzen</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="plant_family in drug.plant_families"
                                     :key="'plant_family'+ plant_family.plant_family.id"
                                     :to="{ name: 'PlantFamily', params: {id: plant_family.plant_family.id} }">{{ plant_family.plant_family.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

                <div class="row" v-if="drug.plant_wirkstoffs.length !== 0">
                    <div class="col-lg-12">
                        <h6 class="mb-0 text-sm sub-heading">verknüpfter pflanzlicher Wirkstoff</h6>
                    </div>
                    <br/>

                    <div class="col-lg-12">
                        <router-link class="btn btn-primary" v-for="plant_wirkstoff in drug.plant_wirkstoffs"
                                     :key="'plant_wirkstoff'+ plant_wirkstoff.plant_wirkstoff.id"
                                     :to="{ name: 'PlantWirkstoff', params: {id: plant_wirkstoff.plant_wirkstoff.id} }">{{ plant_wirkstoff.plant_wirkstoff.name }}
                        </router-link>
                    </div>
                    <br/><br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';
import ImageZoomComponent from "@client/components/ImageZoomComponent.vue";

export default {
    name: "Drug",
    data() {
        return {
            drug: {
                id: 0,
                name: '',
                latin_name: '',
                pictures: '',
                description: '',
                uses: '',
                plants: [],
                plant_families: [],
                plant_wirkstoffs: []
            }
        }
    },
    mounted() {
        this.loadDrug();
    },
    methods: {
        clearData() {
            this.drug.id = 0;
            this.drug.name = "";
            this.drug.latin_name = "";
            this.drug.description = "";
            this.drug.pictures = '';
            this.drug.uses = '';
            this.drug.plants = [];
            this.drug.plant_families = [];
            this.drug.plant_wirkstoffs = [];
        },
        loadDrug() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}drugs/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setDrugData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setDrugData(data) {
            this.drug.id = data.id;
            this.drug.name = data.name;
            this.drug.latin_name = data.latin_name;
            this.drug.description = data.description;
            this.drug.pictures = data.pictures;
            this.drug.uses = data.uses;
            this.drug.plants = data.plants;
            this.drug.plant_families = data.plant_families;
            this.drug.plant_wirkstoffs = data.plant_wirkstoffs;
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        pictures() {
            return {
                template: '<div>'+ this.addImageZoom(this.drug.pictures) + '</div>'
            }
        },
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.drug.description) + '</div>'
            }
        },
        uses() {
            return {
                template: '<div>'+ this.addImageZoom(this.drug.uses) + '</div>'
            }
        }
    },
    watch: {
        drugId: function (newVal, oldVal) {
            this.drug.id = newVal;
            this.loadDrug();
        }
    },
    components: {
        ImageComponent,
        ImageZoomComponent
    }
}
</script>

<style scoped>

</style>
