<template>
    <div class="semester-component">
        <div class="row">
            <div class="col">
                <h3 class="text-center">
                    In welchem Fachsemester befindest du dich?
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <vue-scroll-picker
                    :options="formattedSemesters"
                    v-model="selectedSemester"
                />
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col d-flex flex-column justify-content-center">
                <button
                    class="btn btn-primary mb-2"
                    @click.prevent="saveAndContinue"
                    :disabled="!selectedSemester"
                >
                    Weiter &nbsp;
                    <i class="fa fa-solid fa-arrow-right"></i>
                </button>
                <button
                    class="btn btn-secondary mb-3"
                    @click.prevent="moveToNext"
                >
                    Weiter ohne Angabe des Semesters &nbsp;
                    <i class="fa fa-solid fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";
import { VueScrollPicker } from "vue-scroll-picker";

const router = useRouter();
const semesters = ref([]);
const selectedSemester = ref(null);

const loadSemesters = async () => {
    axios
        .get(`${API_URL}user/get_all_semesters`, {
            headers: await authHeader(),
        })
        .then((response) => {
            if (response.status === 200) {
                semesters.value = response.data;
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

const saveAndContinue = async () => {
    const data = {
        semester_id: selectedSemester.value,
    };

    axios
        .patch(`${API_URL}user/update_semester`, data, {
            headers: await authHeader(),
        })
        .then((response) => {
            if (response.status == 200) {
                // this.$router.push("/welcome/survey");
                moveToNext();
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

const moveToNext = () => {
    return router.push({ name: "welcome.survey" });
};

const formattedSemesters = computed(() => {
    return semesters.value.map((semester) => {
        return { name: semester.name, value: semester.id };
    });
});

onBeforeMount(async () => {
    // set the welcome state counters
    const store = useStore();
    await store.commit("updateWelcomeMode", {
        isWelcomeMode: true,
        count: 2,
    });
});

onMounted(() => {
    loadSemesters();
});
</script>
<style src="vue-scroll-picker/lib/style.css"></style>
<style>
.vue-scroll-picker .vue-scroll-picker-layer .vue-scroll-picker-layer-top {
    border-bottom: none;
}

.vue-scroll-picker .vue-scroll-picker-layer .vue-scroll-picker-layer-bottom {
    border-top: none;
}

.vue-scroll-picker .vue-scroll-picker-rotator .vue-scroll-picker-item {
    text-align: center;
    font: normal normal normal 20px/25px Poppins;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
}

.vue-scroll-picker
    .vue-scroll-picker-rotator
    .vue-scroll-picker-item.vue-scroll-picker-item-selected {
    font: normal normal bold 26px/25px Poppins;
    letter-spacing: 0px;
    color: #cb4099;
    text-transform: uppercase;
    /* line-height: 3em; */
}

.vue-scroll-picker-layer-selection {
    z-index: -1;
    background: #f6f6f6 0% 0% no-repeat padding-box;
}
</style>
