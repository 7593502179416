<template>
  <div class="container quiz-container mt-4">
    <h1>Quiz</h1>
    <template v-if="blocked">
      <section class="quiz-mode-selection my-4">
        <div class="row my-4">
          <div class="col text-center">
            <h3>Heute findet der erste Teil des Staatsexamens statt. Daher müssen wir unsere Prüfungsfragen im Moment blockieren. Später sind kannst du wieder wie gewohnt Üben.</h3>
          </div>
        </div>
      </section>
    </template>
    <template v-else>
      <section class="quiz-mode-selection my-4">
        <div class="row my-4">
          <div class="col" v-if="!complete">
            <saved-question-live-quiz-component
                :question="question"
                :quiz-or-exam-mode="true"
                :exam-mode="false"
                :total-questions="total_questions"
                :question-number="current_count"
                :quiz-id="quiz.id"
                :previous-question-mode="previousQuestionMode"
                :user-question="user_question"
                @next-question="nextQuestion"
                @previous-question="previousQuestion"
                @track-success-rate="trackSuccessRate"
                @bookmark-change="bookmarkQuestion"
                :key="question.id"
            />
          </div>
          <div class="col" v-else>
            <quiz-overview-component
                @restart-quiz="startQuiz"
                :overview="overview"
            />
          </div>
        </div>
      </section>
    </template>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import useTracker from "@client/composables/useTracker";
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import SavedQuestionLiveQuizComponent from "@client/components/SavedQuestionLiveQuizComponent.vue";
import InputComponent from '@client/components/InputComponent.vue';
import QuizOverviewComponent from '@client/components/QuizOverviewComponent.vue';
import "katex/dist/katex.css";

export default{
  name: 'UserQuiz',
  data() {
    return {
      question: {},
      quiz: {},
      subject: "",
      total_questions: 12,
      current_count: 0,
      previewMode: false,
      previewQuestionId: 0,
      previousQuestionMode: false,
      complete: false,
      overview: {},
      blocked: false,
      user_question: null
    }
  },
  computed: {
    ...mapState(['isAuthenticated', 'user'])
  },
  setup() {
    const {trackTopicQuestion } = useTracker();

    return {
      trackTopicQuestion,
    };
  },
  mounted() {
    if (this.$route.params.quiz && this.$route.params.quiz === "continue") {
      // Load quiz data
      axios.get(`${API_URL}user_quiz/${parseInt(this.$route.params.quiz)}`)
      .then(response => {
        if (response.status === 200) {
          this.subject = response.data.subject
          this.quiz = response.data.quiz
          this.total_questions = response.data.total_questions
          this.question = response.data.question
          this.current_count = response.data.attempted_questions
          this.user_question = response.data.user_question

          this.current_count++

          // Track viewing the question in it's topic custom dimension
          const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
          this.trackTopicQuestion('Attempt', this.subject.wording, topicWording);
        } else {
          console.log(response)
          alert('Something went wrong!')
        }
      })
      .catch(error => {
        console.log(error)
      });
    } else {
        this.startQuiz();
    }

  },
  beforeMount() {
    this.checkBlockedExam();
  },
  methods: {
    logToken () {
      this.$matomo && this.$matomo.trackEvent('Token', 'logged');
    },
    startQuiz() {
        this.complete = false
        this.quiz = {}
        this.current_count = 0
        this.question = {}
        this.overview = {}

        var headers = authHeader();
        headers['content-type'] = 'application/json';

        axios.post(`${API_URL}user_quiz/start`,{
            topic: this.topicId,
            mode: this.quizMode,
        }, {'headers': headers})
            .then(response => {
                if (response.status === 200) {
                    this.subject = response.data.subject
                    this.quiz = response.data.quiz
                    this.quizMode = this.quiz.mode
                    this.total_questions = response.data.total_questions
                    this.question = response.data.question
                    this.user_question = response.data.user_question
                    this.current_count++

                    // Track viewing the question in it's topic custom dimension
                    const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
                    this.trackTopicQuestion('Attempt', this.subject.wording, topicWording)
                }
            })
            .catch(error => {
                console.log(error)
            });
    },
    nextQuestion() {
      if (this.current_count >= this.total_questions) {
        this.complete = true;
        return this.loadOverviewData();
      }

      this.previousQuestionMode = false;
      axios.get(`${API_URL}user_quiz/${this.quiz.id}/question/${this.current_count}/next`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.question = response.data.question
          this.previousQuestionMode = parseInt(this.question.pivot.status) === 0 ? false : true
          this.user_question = response.data.user_question
          this.current_count++
          // Track viewing the question in it's topic custom dimension
            console.log(this.question)
          this.trackTopicQuestion('Attempt', this.subject, this.question.topic.wording)
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    previousQuestion() {
      // decrement the current count
      this.current_count -= 2

      axios.get(`${API_URL}user_quiz/${this.quiz.id}/question/${this.current_count}/next`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.question = response.data.question
          this.previousQuestionMode = true
          this.user_question = response.data.user_question
          this.current_count++
          // Track viewing the question in it's topic custom dimension
          const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
          this.trackTopicQuestion('Attempt', this.subject.wording, topicWording)
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    loadOverviewData() {
      axios.get(`${API_URL}user_quiz/${this.quiz.id}/overview`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.overview = response.data.overview
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    setPreviewMode() {
      this.previewMode = !this.previewMode
    },
    trackSuccessRate(status) {
      const topicWording = this.question.topics.length > 0 ? this.question.topics[0].wording : this.question.topic.wording;
      this.trackTopicQuestion(status, this.subject.wording, topicWording)
    },
    loadPreviewQuestion() {
      axios.get(`${API_URL}user_quiz/question/${this.previewQuestionId}`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.question = response.data.question
          this.subject = response.data.topic
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
    exitPreviewMode() {
      this.previewMode = false
      this.previewQuestionId = 0
      this.question = {}
      this.subject = null
    },
    checkBlockedExam() {
      axios.get(`${API_URL}quiz/status/check_blocked_exam`, {'headers': authHeader()})
          .then(response => {
            if (response.status === 200) {
              this.blocked = response.data.blocked;
            }
          })
          .catch(error => {
            console.log(error)
          });
    },
    bookmarkQuestion() {
      this.user_question = this.user_question === null ? {id: this.question.id} : null;
    }
  },
  components: {
      SavedQuestionLiveQuizComponent,
    InputComponent,
    QuizOverviewComponent,
  }
}
</script>
