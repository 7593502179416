<template>
    <div class="container-fluid login">
        <br /><br />
        <div class="row">
            <form @submit.prevent="submitCredentials">
                <h3 class="text-center">{{ heading }}</h3>
                <div
                    class="col-12 col-sm-12 offset-md-4 col-md-4 offset-lg-4 col-lg-4"
                >
                    <div
                        class="alert alert-dismissible"
                        v-bind:class="
                            error === true ? 'alert-danger' : 'alert-success'
                        "
                        v-if="message !== ''"
                    >
                        <strong>{{ message }}</strong>
                    </div>

                    <input-component
                        name="email"
                        id="email"
                        label="Email"
                        placeholder="Email"
                        type="email"
                        :value="input.email"
                        v-model="input.email"
                    />

                    <br />

                    <div class="form-field">
                        <label for="">Passwort *</label>
                    </div>

                    <div class="input-group mb-3 form-field">
                        <input
                            class="form-control"
                            id="password"
                            placeholder="Passwort eingeben"
                            type="password"
                            v-model="input.password"
                            style="border-right: unset"
                        />
                        <span
                            v-on:click="togglePassword"
                            id="show-password"
                            class="input-group-text"
                            style="
                                border-left: unset;
                                background-color: white;
                                cursor: pointer;
                            "
                            ><i
                                style="color: #cb4099"
                                class="far"
                                v-bind:class="[
                                    showPassword ? 'fa-eye' : 'fa-eye-slash',
                                ]"
                            ></i
                        ></span>
                    </div>

                    <br />

                    <router-link class="link" :to="{ name: 'forgot-password' }">
                        Passwort zurücksetzen
                    </router-link>

                    <br />

                    <div class="text-center">
                        <button
                            class="btn btn-primary"
                            v-bind:class="{ disabled: formSubmitted }"
                            v-on:click="submitCredentials"
                        >
                            Absenden
                        </button>
                    </div>

                    <br />

                    <div
                        class="text-center"
                        v-if="this.$route.params.email === ''"
                    >
                        <router-link class="link" :to="{ name: 'register' }">
                            Noch keinen Account? Registriere dich hier.
                        </router-link>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import InputComponent from "@client/components/InputComponent.vue";
import useTracker from "@client/composables/useTracker";
import { API_URL } from "@/conf.js";
import { PLATFORM } from "../../utils";
import router from "../router";

export default {
    name: "Login",
    data() {
        return {
            heading: "Login",
            message: "",
            error: false,
            showPassword: false,
        };
    },
    setup() {
        const { setMatomoUserId } = useTracker();

        return {
            setMatomoUserId,
            input: {
                email: "",
                password: "",
            },
            formSubmitted: false,
        };
    },
    mounted() {
        if (
            this.$route.params.email !== undefined &&
            this.$route.params.email !== ""
        ) {
            this.doubleOptIn(this.$route.params.email);
        }

        const passwordInput = document.getElementById("password");
        passwordInput.addEventListener("focusin", this.passwordFocusListener); //Non-IE
        passwordInput.onfocusin = this.passwordFocusListener; //IE
        passwordInput.addEventListener("focusout", this.passwordFocusListener); //Non-IE
        passwordInput.focusout = this.passwordFocusListener; //IE
    },
    methods: {
        submitCredentials() {
            this.formSubmitted = true;

            axios
                .post(`${API_URL}login`, {
                    email: this.input.email,
                    password: this.input.password,
                })
                .then((response) => {
                    this.formSubmitted = false;

                    if (response.status === 200) {
                        var first_login = response.data.first_login;
                        this.input.email = "";
                        this.input.password = "";

                        const token = response.data.authorization.token;

                        localStorage.setItem("token", token);

                        if (response.data.user.email !== undefined) {
                            localStorage.setItem(
                                "email",
                                response.data.user.email,
                            );
                            // window._paq.push(['setUserId', this.user.email]);
                            this.setMatomoUserId(response.data.user.email);
                        }

                        this.getUserData(localStorage.getItem("email")).then(
                            (response) => {
                                if (response.status === 200) {
                                    this.$store.dispatch("registerUserDevice", {
                                        user_id: response.data.user.id,
                                        user_agent: navigator.userAgent,
                                        platform: PLATFORM(navigator.userAgent),
                                        device_token: "Not Token",
                                        token: token,
                                    });


                                    if (response.data.user.show_welcome_tour)
                                        router.replace({ path: "/welcome" });
                                    else
                                        router.replace({ path: "/dashboard" });

                                } else {
                                }
                            },
                        );
                    } else {
                        this.input.password = "";
                        this.$swal("Info", response.data.message, "info");
                    }
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        this.$swal(
                            "Error",
                            "Ungültige Email oder ungültiges Passwort",
                            "error",
                        );
                    } else if (error.response.status === 502) {
                        this.$swal(
                            "Error",
                            error.response.data.message,
                            "error",
                        );
                    } else {
                        this.$swal(
                            "Error",
                            "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!",
                            "error",
                        );
                    }

                    this.formSubmitted = false;
                });
        },
        doubleOptIn(email) {
            this.heading = "Registrierung abgeschlossen!";
            this.error = false;
            axios
                .post(`${API_URL}double_opt_in`, {
                    email: email,
                })
                .then((response) => {
                    this.message = response.data.message;
                })
                .catch((error) => {
                    this.error = true;
                    if (error.response.status === 400) {
                        this.message = error.response.data.message;
                    } else {
                        this.message = error.response.data.message;
                    }
                });
        },
        getUserData(email) {
            return this.$store.dispatch("getUserData", email);
        },
        togglePassword(event) {
            var spanElement = event.target.offsetParent.lastChild;
            this.showPassword = !this.showPassword;

            var passwordInput = document.getElementById("password");
            if (passwordInput.type === "password") {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }

            spanElement.style.borderColor =
                spanElement.style.borderColor === "rgb(203, 64, 153)"
                    ? "#D6DBDE"
                    : "#CB4099";

            if (event.target.value !== "") {
                spanElement.style.borderColor = "#CB4099";
            }
        },
        passwordFocusListener() {
            var showPassword = document.getElementById("show-password");
            showPassword.style.borderColor =
                showPassword.style.borderColor === "rgb(203, 64, 153)"
                    ? "#D6DBDE"
                    : "#CB4099";

            if (event.target.value !== "") {
                showPassword.style.borderColor = "#CB4099";
            }
        },
    },
    watch: {
        $route(to, from) {
            var body = document.getElementsByTagName("body");
            if (body[0] !== undefined) {
                body[0].removeAttribute("style");
                body[0].classList.remove("modal-open");
            }
        },
    },
    components: {
        InputComponent,
    },
};
</script>

<style scoped>
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 50%;
    height: 50%;
    background-color: white;
}
</style>
