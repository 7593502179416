<template>
    <div class="university-component">
        <div class="row">
            <div class="col">
                <h3 class="text-center">
                    An welcher Universität studierst du?
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="search-bar mb-3 mt-3">
                    <span class="search-icon">
                        <i class="fa fa-solid fa-magnifying-glass"></i>
                    </span>
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Suchbegriff eingeben"
                        aria-label="Suchbegriff eingeben"
                    />
                </div>
            </div>
        </div>
        <select-item
            v-for="university in formattedUniversities"
            :key="`university-${university.id}-welcome-tour`"
            :item="university"
            :selected="university.value == selectedUniversityId"
            @item-selected="universitySelected"
        />

        <div class="row mt-3 mb-3">
            <div class="col d-flex flex-column justify-content-center">
                <button
                    class="btn btn-primary mb-2"
                    @click.prevent="saveAndContinue"
                    :disabled="!selectedUniversityId"
                >
                    Weiter &nbsp;
                    <i class="fa fa-solid fa-arrow-right"></i>
                </button>
                <button
                    class="btn btn-secondary mb-3"
                    @click.prevent="moveToNext"
                >
                    Weiter ohne Angabe der Universität &nbsp;
                    <i class="fa fa-solid fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SelectItem from "@client/components/welcome-tour/selectItem.vue";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";

const router = useRouter();
const universities = ref([]);
const selectedUniversityId = ref(null);

const loadUniversities = async () => {
    axios
        .get(`${API_URL}user/get_all_universities`, {
            headers: await authHeader(),
        })
        .then((response) => {
            if (response.status == 200) {
                universities.value = response.data;
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

const formattedUniversities = computed(() => {
    return universities.value.map((university) => ({
        name: university.name,
        value: university.id,
    }));
});

const universitySelected = (university) => {
    selectedUniversityId.value = university.value;
};

const saveAndContinue = async () => {
    const data = {
        university_id: selectedUniversityId.value,
    };

    axios
        .patch(`${API_URL}user/update_university`, data, {
            headers: await authHeader(),
        })
        .then((response) => {
            if (response.status == 200) {
                moveToNext();
            }
        })
        .catch((error) => {
            console.log(error);
        });
};

const moveToNext = () => {
    return router.push({ name: "welcome.semester" });
};

onBeforeMount(async () => {
    // set the welcome state counters
    const store = useStore();
    await store.commit("updateWelcomeMode", {
        isWelcomeMode: true,
        count: 1,
    });
});

onMounted(async () => {
    await loadUniversities();
});
</script>
