<template>
  <div>
    <div 
      class="alert alert-dismissible fade show" 
      :class="`alert-${status}`"
      role="alert"
    >
      {{ message }}.
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'notification-component',
  props: {
    status: {
      type: String,
      default: 'success',
    },
    message: {
      type: String,
      required:true,
    }
  },
  mounted() {
    const alertElement = document.querySelector('.alert');
    alertElement.addEventListener('closed.bs.alert', () => {
      this.$emit('alertClosed');
    });
  },
}

</script>