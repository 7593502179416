<template>
    <div class="row item-select">
        <div class="col">
            <div class="d-grid">
                <button
                    class="btn btn-select mb-2 mt-2"
                    :class="{ active: selected }"
                    @click.prevent="selectItem"
                >
                    {{ item.name }}
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emits = defineEmits(["itemSelected"]);

const props = defineProps({
    item: {
        type: Object,
        required: true,
        validator: (val) => (Object.hasOwn(val, 'name') && Object.hasOwn(val, 'value')),
    },
    selected: {
        type: Boolean,
        default: false,
    },
});

const selectItem = () => {
    emits("itemSelected", props.item);
};
</script>
