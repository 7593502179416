<template>
  <div class="streak-box">
    <div class="title">
      <h4>Wochenfortschritt</h4>
      <!-- <p>xx% erreicht</p> -->
      <progress-component
        :min-value="0"
        :max-value="100"
        :current-value="streakPercentage"
      />
      <div class="streak-days mt-4">
        <div v-for="day in streakDays"
          class="streak-day"
          :class="{'completed': completedDays.includes(day)}"
          :key="day"
        >
          <div class="streak-mark">
            <i v-if="completedDays.includes(day)" class="fa fa-check"></i>
          </div>
          <p>{{ day }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProgressComponent from './ProgressComponent.vue';
  export default {
    name: 'streak-box-component',
    setup() {
      const streakDays = ['Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.', 'So.']
      return {
        streakDays
      }
    },
    props: {
      completedDays: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      streakPercentage(){
        const totalDays = 7;
        const completedDaysCount = this.completedDays.length

        return (completedDaysCount / totalDays) * 100;
      }

    },
    components: {
      ProgressComponent
    },
  }
</script>