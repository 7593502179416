<script>
import {defineComponent} from 'vue'
import InputComponent from '@client/components/InputComponent.vue';
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import router from "../router";

export default defineComponent({
    name: "CleverReachOptOut",
    data() {
        return {
            input: {
                email: ""
            },
            formSubmitted: false
        }
    },
    methods: {
        submitNewsLetter() {
            axios.post(`${API_URL}unsubscribe_news_letter`, {
                email: this.input.email,
                status: false
            })
                .then(response => {
                    this.formSubmitted = false;

                    if (response.status === 200) {
                        document.getElementById('clever-reach-form').submit();
                        router.replace({path: '/dashboard'})
                    } else {
                        this.$swal("Info", response.data.message, "info");
                    }
                }).catch(error => {
                if (error.response.data.errors !== undefined) {
                    if (error.response.data.errors.email !== undefined) {
                        this.$swal("Error", "Die Emailadresse ist nicht möglich!", "error");
                        this.formSubmitted = false;
                    }
                    return;
                }

                this.$swal("Error", "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!", "error");
                this.formSubmitted = false;
            });
        }
    },
    components: {
        InputComponent
    }
})
</script>

<template>
    <div class="container">
        <div class="clever-reach-form">


            <form style="display: none" id="clever-reach-form" action="https://seu1.cleverreach.com/f/258367-233664/wcu/" method="post" target="_blank">
                <input name="email" type="email" v-model="input.email">
            </form>

            <form>
                <div class="row">
                    <div class="col-4 offset-md-3">
                        <input-component
                            name="email"
                            id="email"
                            label="Email *"
                            placeholder="Emailadresse eingeben"
                            type="email"
                            :value="input.email"
                            v-model="input.email"
                        />
                    </div>
                </div>

                <br/>

                <div class="row">
                    <div class="col-4 offset-md-3">
                        <div class="text-center">
                            <button class="btn btn-primary" v-bind:class="{ disabled: formSubmitted}" type="button" v-on:click="submitNewsLetter">
                                Anmelden
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>
</template>

<style scoped>

</style>
