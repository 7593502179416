<template>
    <div class="container-fluid registration">
        <br/><br/>
        <div class="row">
            <h3 class="text-center">Registrierung</h3>
            <div class="col-12 col-sm-12 offset-md-4 col-md-4 offset-lg-4 col-lg-4">
                <input-component
                    name="username"
                    id="username"
                    label="Username *"
                    placeholder="Usernamen eingeben"
                    type="text"
                    :value="input.username"
                    v-model="input.username"
                />

                <br/>

                <input-component
                    name="email"
                    id="email"
                    label="Email *"
                    placeholder="Emailadresse eingeben"
                    type="email"
                    :value="input.email"
                    v-model="input.email"
                />

                <br/>

                <div class="form-field">
                    <label for="">Passwort *</label>
                </div>

                <div class="input-group mb-3 form-field">
                    <input class="form-control" id="password"
                           placeholder="Passwort eingeben"
                           type="password" v-model="input.password" @input="checkPassword" style="border-right: unset;">
                    <span v-on:click="togglePassword" id="show-password" class="input-group-text" style="border-left: unset; background-color: white; cursor: pointer;"><i style="color: #CB4099" class="far fa-eye-slash"></i></span>
                </div>

                <span class="password-description"
                      v-bind:class="{ is_valid : validation.password.contains_eight_characters }">- mindestens 8 Zeichen</span>
                <br/>
                <span class="password-description" v-bind:class="{ is_valid : validation.password.valid_password }">- Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen müssen enthalten sein</span>

                <br/><br/>

                <div class="form-field">
                    <label for="">Passwort bestätigen *</label>
                </div>

                <div class="input-group mb-3 form-field">
                    <input class="form-control" id="confirmPassword"
                           placeholder="Passwort erneut eingeben"
                           type="password" v-model="input.confirm_password" style="border-right: unset;">
                    <span v-on:click="togglePassword" id="show-confirm-password" class="input-group-text" style="border-left: unset; background-color: white; cursor: pointer;"><i style="color: #CB4099" class="far fa-eye-slash"></i></span>
                </div>

                <br/>

                <div
                    class="form-check terms-container"
                >
                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="terms-check"
                        :value="input.terms"
                        v-model="input.terms"
                    >
                    <label
                        class="form-check-label" for="terms-check">
                            <span>Ich habe die <a href="https://pharmanavi.de/agb/" target="_blank">AGB</a> und die <a
                                href="https://pharmanavi.de/datenschutz/" target="_blank">Datenschutzerklärung</a> gelesen und erkläre mich damit einverstanden.</span>
                        <span>*</span>
                    </label>

                    <br/><br/>

                    <input
                        class="form-check-input"
                        type="checkbox"
                        id="news-letter-check"
                        :value="input.news_letter"
                        v-model="input.news_letter"
                        @change="toggleNewsLetter($event)"
                    >
                    <label
                        class="form-check-label" for="news-letter-check">
                            <span>
                                Du möchtest immer mit den aktuellen Neuigkeiten von PharmaNavi versorgt werden und keine Infos verpassen? Dann melde dich für unseren Newsletter an.
                            </span>
                    </label>

                    <br/><br/>

                    <div class="news-letter-options">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="news-letter-activate"
                            :value="input.news_letter_activate"
                            v-model="input.news_letter_activate"
                        >
                        <label
                            class="form-check-label" for="news-letter-activate">
                            <span>
                                zu Aktionen von PharmaNavi
                            </span>
                        </label>

                        <br/><br/>

                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="news-letter-product"
                            :value="input.news_letter_product"
                            v-model="input.news_letter_product"
                        >
                        <label
                            class="form-check-label" for="news-letter-product">
                            <span>
                                zu Produkten des Deutschen Apotheker Verlags
                            </span>
                        </label>
                    </div>
                </div>

                <br/>

                <div class="text-center">
                    <button class="btn btn-primary" v-bind:class="{ disabled: formSubmitted}" v-on:click="submitData">
                        Absenden
                    </button>
                </div>

                <br/>

                <div class="text-center">
                    <router-link
                        class="link"
                        :to="{ name: 'login' }"
                    >
                        Schon registriert? Hier geht's zum Login.
                    </router-link>
                </div>

                <br/><br/>

            </div>

        </div>
    </div>
</template>

<script>

import InputComponent from '@client/components/InputComponent.vue';
import useTracker from "@client/composables/useTracker";
import {API_URL} from "@/conf.js";
import {PLATFORM} from "../../utils";
import router from "../router";

export default {
    name: "Register",
    setup() {
        const {setMatomoUserId} = useTracker();
        return {
            setMatomoUserId,
        }
    },
    data() {
        return {
            input: {
                first_name: "",
                last_name: "",
                username: "",
                email: "",
                password: "",
                confirm_password: "",
                terms: false,
                news_letter: false,
                news_letter_activate: false,
                news_letter_product: false
            },
            validation: {
                password: {
                    password_length: 0,
                    contains_eight_characters: false,
                    contains_number: false,
                    contains_uppercase: false,
                    contains_special_character: false,
                    valid_password: false
                }
            },
            formSubmitted: false
        }
    },
    mounted() {
        const passwordInput = document.getElementById("password");
        passwordInput.addEventListener('focusin', this.passwordFocusListener); //Non-IE
        passwordInput.onfocusin = this.passwordFocusListener; //IE
        passwordInput.addEventListener('focusout', this.passwordFocusListener); //Non-IE
        passwordInput.focusout = this.passwordFocusListener; //IE

        const passwordConfirmInput = document.getElementById("confirmPassword");
        passwordConfirmInput.addEventListener('focusin', this.confirmPasswordFocusListener); //Non-IE
        passwordConfirmInput.onfocusin = this.confirmPasswordFocusListener; //IE
        passwordConfirmInput.addEventListener('focusout', this.confirmPasswordFocusListener); //Non-IE
        passwordConfirmInput.focusout = this.confirmPasswordFocusListener; //IE
    },
    methods: {
        submitData() {

            if (!this.input.terms) {
                this.$swal("Info", "Please agree to terms and conditions.", "info");
                return;
            }

            if (!this.validation.password.valid_password) {
                this.$swal("Info", "Das Passwort ist ungültig.", "info");
                return;
            }

            this.formSubmitted = true

            if (this.input.password !== this.input.confirm_password) {
                this.formSubmitted = false;
                this.$swal("Error", "Die Passwörter stimmen nicht überein!", "error");
                return;
            }

            axios.post(`${API_URL}register`, {
                first_name: this.input.first_name,
                last_name: this.input.last_name,
                username: this.input.username,
                email: this.input.email,
                password: this.input.password,
                password_confirmation: this.input.confirm_password,
                terms: this.input.terms,
                news_letter_activate: this.input.news_letter_activate,
                news_letter_product: this.input.news_letter_product
            })
                .then(response => {
                    this.formSubmitted = false;

                    if (response.status === 200) {
                        this.clearForm();
                        this.$swal("Geschafft!", "Erster Schritt der Registrierung erfolgreich, bitte prüfe deine Mails und bestätige deine Registrierung.", "success")
                            .then((result) => {
                                /*if (result.isConfirmed) {
                                    router.replace({path: '/login'})
                                }*/
                            });
                    } else {
                        this.input.password = "";
                        this.input.confirm_password = "";
                        this.$swal("Info", response.data.message, "info");
                    }
                }).catch(error => {
                if (error.response.data.errors !== undefined) {
                    if (error.response.data.errors.email !== undefined) {
                        this.input.password = "";
                        this.input.confirm_password = "";
                        this.$swal("Error", "Die Emailadresse ist nicht möglich!", "error");
                        this.formSubmitted = false;
                    }
                    return;
                }

                this.input.password = "";
                this.input.confirm_password = "";
                this.$swal("Error", "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!", "error");
                this.formSubmitted = false;
            });
        },
        clearForm() {
            this.input.first_name = "";
            this.input.last_name = "";
            this.input.username = "";
            this.input.email = "";
            this.input.password = "";
            this.input.confirm_password = "";
        },
        checkPassword() {
            this.validation.password.password_length = this.input.password.length;
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            this.validation.password.contains_eight_characters = this.validation.password.password_length > 8;

            this.validation.password.contains_number = /\d/.test(this.input.password);
            this.validation.password.contains_uppercase = /[A-Z]/.test(this.input.password);
            this.validation.password.contains_special_character = format.test(this.input.password);

            this.validation.password.valid_password = this.validation.password.contains_eight_characters === true &&
                this.validation.password.contains_special_character === true &&
                this.validation.password.contains_uppercase === true &&
                this.validation.password.contains_number === true;
        },
        togglePassword(event) {
            var inputField = event.target.offsetParent.firstChild;
            var spanElement = event.target.offsetParent.lastChild;

            if (inputField.type === "password") {
                inputField.type = "text";
            } else {
                inputField.type = "password";
            }

            if (event.target.classList.contains("fa-eye-slash")) {
                event.target.classList.remove("fa-eye-slash");
                event.target.classList.add("fa-eye");
            } else {
                event.target.classList.remove("fa-eye");
                event.target.classList.add("fa-eye-slash");
            }

            spanElement.style.borderColor = spanElement.style.borderColor === 'rgb(203, 64, 153)' ? '#D6DBDE' : '#CB4099';

            if (event.target.value !== "") {
                spanElement.style.borderColor = '#CB4099';
            }
        },
        passwordFocusListener(event) {
            var showPassword = document.getElementById("show-password");
            showPassword.style.borderColor = showPassword.style.borderColor === 'rgb(203, 64, 153)' ? '#D6DBDE' : '#CB4099';

            if (event.target.value !== "") {
                showPassword.style.borderColor = '#CB4099';
            }
        },
        confirmPasswordFocusListener(event) {
            var showPassword = document.getElementById("show-confirm-password");
            showPassword.style.borderColor = showPassword.style.borderColor === 'rgb(203, 64, 153)' ? '#D6DBDE' : '#CB4099';

            if (event.target.value !== "") {
                showPassword.style.borderColor = '#CB4099';
            }
        },
        toggleNewsLetter(event) {
            this.input.news_letter_activate = !this.input.news_letter_activate;
            this.input.news_letter_product = !this.input.news_letter_product;
        }
    },
    components: {
        InputComponent
    }
}
</script>

<style scoped>

</style>
