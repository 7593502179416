<template>
  <div class="container">
    <template v-if="pkg && pkg.hasOwnProperty('id')">
      <div class="row mt-5">
        <div class="col offset-md-3">
          <h3>Abonnement</h3>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col offset-md-3">
          <h4>Paket</h4>
          <p>{{ pkg.name }}</p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col offset-md-3">
          <h4>Zahlungspläne</h4>
        </div>
      </div>
      <div class="row mt-4" v-if="pkg.paypal_plans.length < 1">
        <div class="col-md-4 offset-md-3">
          <p>Bitte schauen Sie noch einmal vorbei, sobald unsere PayPal Pläne fertig sind</p>
        </div>
      </div>
      <template v-else>
        <div class="row mt-4" v-for="plan in pkg.paypal_plans">
          <div class="col-md-4 offset-md-3">
            <div class="package-plan">
              <p class="sub-heading-3 white-text">
                {{ plan.name }}
              </p>
              <p class="white-text">
                {{ plan.description }}
              </p>
              <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#subscriptionModal" @click="selectPlan(plan)">
                Zur Buchung
              </button>
            </div>
          </div>
        </div>
      </template>

      <div class="row mt-5">
        <div class="col-md-4 offset-md-3">
          <router-link
            :to="{ name: 'profile' }"
            class="btn btn-secondary-outline w-100 mb-2"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Zurück zum Profil
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- Billing Address Modal -->
          <div class="modal fade" ref="subscriptionModal" id="subscriptionModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="subscriptionModal" aria-hidden="true">
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="exampleModalLabel">Schön, dass du PharmaNavi voll ausschöpfen möchtest!</h4>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="row mt-3 mb-5" v-if="selectedPlan">
                    <div class="col">
                      <p>{{ selectedPlan.description }}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <input-component
                        name="first-name"
                        id="first-name"
                        label="Vorname"
                        placeholder="Vorname"
                        type="text"
                        :value="input.firstName"
                        v-model="input.firstName"
                        :has-error="validation_errors.firstName.status"
                      />
                      <p class="text-danger" v-if="validation_errors.firstName.status">
                        {{ validation_errors.firstName.message }}
                      </p>
                    </div>
                    <div class="col-md-6">
                      <input-component
                        name="last-name"
                        id="last-name"
                        label="Nachname"
                        placeholder="Nachname"
                        type="text"
                        :value="input.lastName"
                        v-model="input.lastName"
                        :has-error="validation_errors.lastName.status"
                      />
                      <p class="text-danger" v-if="validation_errors.lastName.status">
                        {{ validation_errors.lastName.message }}
                      </p>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col">
                      <input-component
                        name="street-and-house"
                        id="street-and-house"
                        label="Straße und Hausnummer"
                        placeholder="Straße und Hausnummer"
                        type="text"
                        :value="input.streetAndHouseNumber"
                        v-model="input.streetAndHouseNumber"
                        :has-error="validation_errors.streetAndHouseNumber.status"
                      />
                      <p class="text-danger" v-if="validation_errors.streetAndHouseNumber.status">
                        {{ validation_errors.streetAndHouseNumber.message }}
                      </p>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col">
                      <input-component
                        name="additional-address-info"
                        id="additional-address-info"
                        label="zusätzliche Adressinfo (optional)"
                        placeholder="zusätzliche Adressinfo (optional)"
                        type="text"
                        :value="input.additionalAddressInfo"
                        v-model="input.additionalAddressInfo"
                        :has-error="validation_errors.additionalAddressInfo.status"
                      />
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <input-component
                        name="postal-code"
                        id="postal-code"
                        label="Postleitzahl"
                        placeholder="Postleitzahl"
                        type="text"
                        :value="input.postalCode"
                        v-model="input.postalCode"
                        :has-error="validation_errors.postalCode.status"
                      />
                      <p class="text-danger" v-if="validation_errors.postalCode.status">
                        {{ validation_errors.postalCode.message }}
                      </p>
                    </div>
                    <div class="col-md-6">
                      <input-component
                        name="city"
                        id="city"
                        label="Stadt"
                        placeholder="Stadt"
                        type="text"
                        :value="input.city"
                        v-model="input.city"
                        :has-error="validation_errors.city.status"
                      />
                      <p class="text-danger" v-if="validation_errors.city.status">
                        {{ validation_errors.city.message }}
                      </p>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col">
                      <select-component
                        :options="countries"
                        label="Land"
                        :value="input.country"
                        v-model="input.country"
                      />
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col">
                      <button type="button" @click.prevent="submitSubscriptionData" class="btn btn-primary w-100" :disabled="submittingSubscriptionData">
                        <span v-if="submittingSubscriptionData">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Bitte warten...
                        </span>
                        <span v-else>
                          Weiter zu PayPal
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                </div>
              </div>
            </div>
          </div>

          <!-- Subscription Order Summary Modal -->
          <div v-if="paypalSubscription && subscriptionData" class="modal fade" ref="confirm-subscription-order-modal" id="confirm-subscription-order-modal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="subscriptionModal" aria-hidden="true">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title" text-center>
                    Zusammenfassung
                  </h3>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <h4 class="text-center mt-3">{{ selectedPlan.name }}</h4>
                  <p class="text-center mt-3">
                    {{ selectedPlan.description }}
                    <br>
                    Kündigung jederzeit online möglich.
                  </p>

                  <p class="text-center mt-3">
                    Für den Account {{ subscriptionData.user.email }}
                  </p>

                  <p class="text-center mt-3">
                    <!-- <span v-if="typeof paypalSubscription.subscriber.shipping_address.name !== 'undefined'">

                    </span>
                    <span v-else>
                      {{ usersettings.approved_subscription.subscriber.name.given_name }} {{ usersettings.approved_subscription.subscriber.name.surname }}
                    </span> -->

                    <br>
                    <template v-if="paypalSubscription.subscriber.shipping_address.address.address_line_1 != null">
                      {{ paypalSubscription.subscriber.shipping_address.address.address_line_1 }}
                      <br>
                    </template>
                    <template v-if="paypalSubscription.subscriber.shipping_address.address.address_line_2 != null">
                      {{ paypalSubscription.subscriber.shipping_address.address.address_line_2 }}
                      <br>
                    </template>
                    <template v-if="paypalSubscription.subscriber.shipping_address.address.postal_code != null">
                      {{ paypalSubscription.subscriber.shipping_address.address.postal_code }}
                    </template>
                    <template v-if="paypalSubscription.subscriber.shipping_address.address.admin_area_2 != null">
                      &nbsp;
                      {{ paypalSubscription.subscriber.shipping_address.address.admin_area_2 }}
                      <br>
                    </template>
                    <template v-if="paypalSubscription.subscriber.shipping_address.address.country_code != null">
                      {{ paypalSubscription.subscriber.shipping_address.address.country_code }}
                      <br>
                    </template>
                  </p>
                  <form
                    method="post" id="activate_paypal_subscription">
                    <div class="checkbox text-center">
                      <p>
                        <label>
                          <input type="checkbox" v-model="acceptTerms">
                          Ich stimme den <a
                            href="https://www.pharmanavi.de/agb/"
                            target="_blank">AGB</a> und den
                          <a href="https://www.pharmanavi.de/widerrufsbelehrung/"
                              target="_blank">Widerrufsbedingungen</a>
                          zu.
                        </label>
                      </p>
                    </div>
                    <div class="checkbox text-center">
                      <p>
                        <label>
                          <input type="checkbox" v-model="acceptPaymentTerms">
                          Ja, ich möchte sofort Zugang zu dem digitalen Inhalt und weiß, dass mein Widerrufsrecht mit dem Zugang erlischt.
                        </label>
                      </p>
                    </div>

                    <div class="row mt-5">
                      <button type="button" @click.prevent="activateSubscription" class="btn btn-primary w-100" :disabled="activatingSubscription || !acceptPaymentTerms || !acceptTerms">
                        <span v-if="activatingSubscription">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Bitte warten...
                        </span>
                        <span v-else>
                          Zahlungspflichtig buchen
                        </span>
                      </button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>

          <!-- Subscription Order confirmation Modal -->
          <div v-if="paypalSubscription && subscriptionData" class="modal fade" ref="confirm-subscription-payment-modal" id="confirm-subscription-payment-modal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="subscriptionConfirmationModal" aria-hidden="true">
            <div class="modal-dialog modal-md">
              <div class="modal-content">
                <div class="modal-header">

                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <h3 id="confirmation-text" class="text-center">
                    Wir verifizieren deine Buchung. Dies kann einen
                    Moment dauern...
                  </h3>

                  <p class="text-center mt-3">
                    Für den Account {{ subscriptionData.user.email }}
                  </p>

                  <div class="row mt-5">
                  <div class="col">
                    <div class="d-flex justify-content-center">
                      <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="row mt-5" v-else>
      <div class="col">
        <div class="d-flex justify-content-center">
          <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {API_URL} from "@/conf.js";
import InputComponent from "@client/components/InputComponent.vue"
import SelectComponent from "@client/components/SelectComponent.vue"
import authHeader from "@/auth.header.js";

export default {
  name: 'subscription',
  data() {
    return {
      pkg: null,
      selectedPlan: null,
      submittingSubscriptionData: false,
      activatingSubscription: false,
      paypalSubscription: null,
      subscriptionData: null,
      acceptTerms: false,
      orderConfirmationModal: null,
      paymentPendingModal: null,
      acceptPaymentTerms: false,
      pending_interval: null,
      countries: [
        {
          text: 'Deutschland',
          value: 'DE',
          selected: true,
        },
        {
          text: 'Österreich',
          value: 'AT'
        },
        {
          text: 'Schweiz',
          value: 'CH'
        }
      ],
      input: {
        firstName: '',
        lastName: '',
        streetAndHouseNumber: '',
        additionalAddressInfo: '',
        postalCode: '',
        city: '',
        country: 'DE',
      },
      validation_errors: {
        firstName: {
          status: false,
          message: '',
        },
        lastName: {
          status: false,
          message: '',
        },
        streetAndHouseNumber: {
          status: false,
          message: '',
        },
        additionalAddressInfo: {
          status: false,
          message: '',
        },
        postalCode: {
          status: false,
          message: '',
        },
        city: {
          status: false,
          message: '',
        },
        country: {
          status: false,
          message: '',
        },
      }
    }
  },
  beforeMount() {
      const cookieValue = document.cookie
          .split("; ")
          .find((row) => row.startsWith("native_app="))
          ?.split("=")[1];

      if (cookieValue === "yes") {
          this.$router.go(-1);
      }

    this.loadPackage();

    const params = this.$route.query
    if (params.subscription_id !== undefined && params.ba_token !== undefined && params.token !== undefined) {
      this.loadSubscription(params.subscription_id);
    }
  },
  mounted() {

  },
  methods: {
    loadPackage() {
      axios.get(`${API_URL}packages/${this.$route.params.pkg}/show_package`)
      .then(response => {
        if(response.status === 200) {
          this.pkg = response.data.package
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    loadSubscription(subscription_id) {

      axios.get(`${API_URL}subscriptions/subscription/${subscription_id}`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          if (response.data.paypal_subscription.status !== 'APPROVED')
            return;

          this.paypalSubscription = response.data.paypal_subscription
          this.subscriptionData = response.data.subscription

          this.selectedPlan = this.pkg.paypal_plans.find(plan => plan.plan === this.paypalSubscription.plan_id);

          this.$nextTick(() => {
            this.orderConfirmationModal = new bootstrap.Modal(this.$refs['confirm-subscription-order-modal'], {
              backdrop: 'static',
              keyboard: false,
            });
            this.paymentPendingModal = new bootstrap.Modal(this.$refs['confirm-subscription-payment-modal'], {
              backdrop: 'static',
              keyboard: false,
            });

            this.orderConfirmationModal.show();
          });
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    activateSubscription() {
      this.activatingSubscription = true
      axios.patch(`${API_URL}subscriptions/${this.paypalSubscription.id}/activate`, {'headers': authHeader()})
      .then(response => {
        this.activatingSubscription = false
        if (response.status === 201 && response.data.message =='activated') {
          this.orderConfirmationModal.hide();
          this.paymentPendingModal.show();

          this.pending_interval = setInterval(this.pingPendingPaymentStatus, 20000)
        } else {
          console.log(response)
          this.$swal("Error", 'Irgendetwas ist schiefgelaufen! Bitte versuchen Sie es erneut!', "error")
        }
      })
      .catch(error => {
        this.activatingSubscription = false
        this.$swal("Error", 'Irgendetwas ist schiefgelaufen! Bitte versuchen Sie es erneut!', "error")
      });
    },
    pingPendingPaymentStatus() {
      console.log('Checking...')
      axios.get(`${API_URL}subscriptions/${this.paypalSubscription.id}/payment_status`, {'headers': authHeader()})
      .then(response => {
        console.log(response)
        if (response.data.status == 'COMPLETED') {
          clearInterval(this.pending_interval)
          this.paymentPendingModal.hide();
          clearInterval(this.setInterval)
          // Redirect back to profile
          window.location.replace(window.location.origin + '/profile');
        }
      })
      .catch(error => {
        clearInterval(this.pending_interval)
        console.log(error)
        this.$swal("Error", 'Irgendetwas ist schiefgelaufen! Bitte versuchen Sie es erneut!', "error")
      });
    },
    selectPlan(plan) {
      this.selectedPlan = plan
    },
    validateBillingAddress() {

      let errors_found = false

      // First name validation
      if (this.input.firstName.trim().length <= 0) {
        this.validation_errors.firstName.status = true
        this.validation_errors.firstName.message = 'Bitte geben Sie Ihren Vornamen an!';
        errors_found = true;
      } else {
        this.validation_errors.firstName.status = false
        this.validation_errors.firstName.message = ''
      }

      // Last name validation
      if (this.input.lastName.trim().length <= 0) {
        this.validation_errors.lastName.status = true
        this.validation_errors.lastName.message = 'Bitte geben Sie Ihren Nachnamen an!'
        errors_found = true
      }  else {
        this.validation_errors.lastName.status = false
        this.validation_errors.lastName.message = ''
      }

      // Street and House number validation
      if (!(/\d/.test(this.input.streetAndHouseNumber) && /[a-zA-Z]/.test(this.input.streetAndHouseNumber))) {
        this.validation_errors.streetAndHouseNumber.status = true
        this.validation_errors.streetAndHouseNumber.message = 'Bitte gib eine Straße und Hausnummer ein!'
        errors_found = true
      } else {
        this.validation_errors.streetAndHouseNumber.status = false
        this.validation_errors.streetAndHouseNumber.message = ''
      }

      // Validate the postal code
      if (!/^\d+$/.test(this.input.postalCode) || (this.input.postalCode.length < 4 || this.input.postalCode.length > 5)) {
        this.validation_errors.postalCode.status = true
        this.validation_errors.postalCode.message = 'Bitte gib eine korrekte Postleitzahl ein!'
        errors_found = true
      } else {
        this.validation_errors.postalCode.status = false
        this.validation_errors.postalCode.message = ''
      }

      // City validation
      if (this.input.city.trim().length <= 0) {
        this.validation_errors.city.status = true
        this.validation_errors.city.message = 'Bitte geben Sie Ihre Stadt an!';
        errors_found = true;
      } else {
        this.validation_errors.city.status = false
        this.validation_errors.city.message = ''
      }



      return !errors_found
    },
    submitSubscriptionData() {
      if (!this.validateBillingAddress())
        return;

      this.input.plan = this.selectedPlan.plan
      // Perform submission of subscription data and create subscription.
      this.submittingSubscriptionData = true;

      axios.post(`${API_URL}subscribe/${this.pkg.id}`, this.input, {'headers': authHeader()})
      .then(response => {
        if (response.status === 201) {
          // redirect to approval link
          const approval_link = response.data.approval_link
          window.location.replace(approval_link.href)
        } else {
          this.$swal("Error", 'Irgendetwas ist schiefgelaufen! Bitte versuchen Sie es erneut!', "error")
        }
      }).catch(error => {
        this.submittingSubscriptionData = false;
        this.$swal("Error", 'Irgendetwas ist schiefgelaufen! Bitte versuchen Sie es erneut!', "error")
        console.log(error)
      })
    }
  },
  components: {
    InputComponent,
    SelectComponent,
  },
  computed: {
    subscriptionPlan() {
      if (!this.pkg || !this.paypalSubscription)
        return null;

      return this.pkg.paypal_plans.find(plan => plan.plan === this.paypalSubscription.plan_id);
    }
  }
}
</script>
