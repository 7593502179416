<template>
  <div class="container">
    <div class="row mt-5">
      <div class="col offset-md-3">
        <h3>E-Mail-Adresse ändern</h3>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-4 offset-md-3">
        <input-component
          name="current_mail"
          id="current_mail"
          label="bisherige Mailadresse"
          placeholder="bisherige Mailadresse"
          type="email"
          :value="input.currentMail"
          v-model="input.currentMail"
          :has-error="false"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-4 offset-md-3">
        <input-component
          name="new_mail"
          id="new_mail"
          label="neue Mailadresse"
          placeholder="neue Mailadresse"
          type="email"
          :value="input.newMail"
          v-model="input.newMail"
          :has-error="false"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-4 offset-md-3">
        <input-component
          name="confirm_new_mail"
          id="confirm_new_mail"
          label="neue Mailadresse bestätigen"
          placeholder="neue Mailadresse bestätigen"
          type="email"
          :value="input.confirmNewMail"
          v-model="input.confirmNewMail"
          :has-error="false"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-4 offset-md-3">
        <VueClientRecaptcha
          :value="captchaText"
          @getCode="getCaptchaCode"
          @isValid="checkValidCaptcha"
        />
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-md-4 offset-md-3">
        <input-component
          name="captcha_text"
          id="captcha_text"
          label="Gib den Captcha-Text ein"
          placeholder="Gib den Captcha-Text ein"
          type="email"
          :value="captchaText"
          v-model="captchaText"
          :has-error="false"
        />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-md-4 offset-md-3 text-center">
        <button class="btn btn-primary w-100" v-on:click="updateMail" :disabled="submitting || !captchaTextValid">
          E-Mail aktualisieren
        </button>
      </div>
    </div>

  </div>
</template>

<script>

  import { ref } from 'vue';
  import useTracker from "@client/composables/useTracker";
  import InputComponent from '@client/components/InputComponent.vue';
  import authHeader from "@/auth.header.js";
  import {API_URL} from "@/conf.js";

  import VueClientRecaptcha from 'vue-client-recaptcha'

  export default {
    name: 'email-update',
    setup() {
        const {resetTrackedUserId} = useTracker();
        const logout = () => {

            // Reset the tracked user id
            resetTrackedUserId();
            localStorage.clear();

            const appEnv = process.env.MIX_APP_ENV

            window.location.reload();
        };


        /* pass value to captcha  */
        const captchaText = ref(null);
        const captchaTextValid = ref(false)

        const getCaptchaCode = (value) => {
          /* you can access captcha code */
        };
        const checkValidCaptcha = (value) => {
          /* expected return boolean if your value and captcha code are same return True otherwise return False */
          captchaTextValid.value = value;
        };

        return {
          logout,
          captchaText,
          captchaTextValid,
          getCaptchaCode,
          checkValidCaptcha,
        };
    },
    data() {
      return {
        input: {
          currentMail: '',
          newMail: '',
          confirmNewMail: '',
        },
        submitting: false,
      }
    },
    methods: {
      updateMail() {
        if (!this.validateInput())
          return;

        this.submitting = true;
        axios.patch(`${API_URL}user/update_mail`, {
          current_mail: this.input.currentMail,
          new_mail: this.input.newMail,
          confirm_new_mail: this.input.confirmNewMail,
        }, {'headers': authHeader()})
        .then(response => {
            this.submitting = false
            if (response.status === 200) {
              this.$swal("Erfolg!", response.data.message, "success")
              .then((value) => {
                this.logout();
              });

            } else {
              this.$swal('Irgendetwas ist schief gelaufen', response.data.message, 'error');
            }
        })
        .catch(error => {
          this.submitting = false;
          console.log(error.response)
          if (error.response) {
            if (error.response.status = 409) {
              this.$swal("Validierungsfehler!", error.response.data.message, "error");
            } else {
              this.$swal('Irgendetwas ist schief gelaufen', error.response.data.message, 'error');
            }
          }
        });
        
      },
      validateInput() {
        if (!this.isEmail(this.input.currentMail) || this.input.currentMail !== localStorage.getItem("email")) {
          this.$swal("Validierungsfehler!", "Bitte geben Sie eine gültige aktuelle Konto-E-Mail-Adresse ein.", "error");
          return false;
        }

        if (!this.isEmail(this.input.newMail) || this.input.newMail === this.input.currentMail) {
          this.$swal('Validierungsfehler!', 'Bitte geben Sie eine gültige neue E-Mail-Adresse ein.', 'error');
          return false;
        }

        if (!this.isEmail(this.input.confirmNewMail) || this.input.confirmNewMail !== this.input.newMail) {
          this.$swal('Validierungsfehler!', 'Bitte geben Sie die neue E-Mail-Adresse zweimal ein.', 'error');
          return false;
        }


        return true;
      },
      isEmail(email) {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      }
    },
    components: {
      InputComponent,
      VueClientRecaptcha,
    }
  }
</script>