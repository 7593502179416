<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="row" v-if="warning.name !== undefined && warning.name !== null && warning.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{warning.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="warning.description !== undefined && warning.description !== null && warning.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="warning.description"></div>-->
                    <br/><br/>
                </div>

                <div class="row" v-if="warning.image_title !== undefined && warning.image_title !== null && warning.image_title !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Bildunterschrift</h6>
                    </div>
                    <div><p>{{warning.image_title}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="warning.image !== undefined && warning.image !== null && warning.image !== ''">
                    <div class="col-12">
                        <h6 class="mb-0 text-sm sub-heading">Abbildung</h6>
                    </div>
                    <div class="col-12">
                        <image-zoom-component :imageSrc="warning.image" :imageAlt="warning.image_subtitle"></image-zoom-component>
                    </div>
                    <br/><br/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';
import ImageZoomComponent from "@client/components/ImageZoomComponent.vue";

export default {
    name: "Warning",
    data() {
        return {
            warning: {
                id: 0,
                name: '',
                description: '',
                image_title: '',
                image: ''
            },
        }
    },
    props: {
        warningId: Number
    },
    mounted() {
        this.loadWarning();
    },
    methods: {
        clearData() {
            this.warning.id = 0;
            this.warning.name = "";
            this.warning.description = "";
            this.warning.image_title = "";
            this.warning.image = "";
        },
        loadWarning() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}warnings/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    if (response.status === 200) {
                        this.clearData();
                        this.warning.id =  response.data.id;
                        this.warning.name = response.data.name;
                        this.warning.description = this.convertHtmlEntity(this.decodeHtml(response.data.description));
                        this.warning.image_title = response.data.image_title;
                        this.warning.image = response.data.image;
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {

                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.warning.description) + '</div>'
            }
        }
    },
    components: {
        ImageZoomComponent,
        ImageComponent
    }
}
</script>

<style scoped>

</style>
