export const PLATFORM = (agent) => {
    var platform = "";

    if (agent.match('/\bAndroid\b/')) {
        platform = "Android";

        var version_matches = agent.match('/(Android)(.*?)(\))/');
        platform += version_matches[2].split(";").join(" ");

        if (agent.match('/\bwv\b/')) {
            platform += " App";
        } else {
            platform = "Browser auf " + platform;
        }
    } else if (agent.match('/\biPhone\b/')) {
        var version_matches = agent.match('/(iPhone;)(.*?)(\\))/');
        platform = (version_matches.length >= 2) ? version_matches[2].replace("like Mac OS X", "") : "iPhone";
    } else if (agent.match('/\biPad\b/')) {
        platform = "iPad";
        var version_matches = agent.match('/(iPad)(.*?)(\\))/');
        platform += (version_matches.length >= 2) ? version_matches[2].replace("like Mac OS X", "") : "";
    } else if (agent.match('/\bWindows\b/')) {
        platform = "Browser auf Windows";
    } else if (agent.match('/\bMac OS X\b/')) {
        platform = "Browser auf Mac OS X";
    } else {
        platform = agent;
    }

    return platform;
}
