<template>
    <div>
        <TmpNavBar/>

        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12 col-lg-4">
                    <iframe src="https://player.vimeo.com/video/767198524?h=224035e9df" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="width: 100%"></iframe>
                </div>

                <div class="col-sm-12 col-lg-4">
                    <iframe src="https://player.vimeo.com/video/767198650?h=2bf71f62d5" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="width: 100%"></iframe>
                </div>
                <div class="col-sm-12 col-lg-4">
                    <iframe src="https://player.vimeo.com/video/767198633?h=58cec8db25" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen style="width: 100%"></iframe>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import TmpNavBar from "@client/components/TmpNavBar";

export default {
    name: "Videos",
    components: {
        TmpNavBar
    }
}
</script>

<style scoped>

</style>
