<template>
  <div class="dashboard-container">
    <div class="dashboard-desktop d-none d-md-none d-lg-block">
<!--        <div class="container">
            <div class="row">
                <voucher-component/>
            </div>
        </div>-->
      <div class="container mt-5">
        <div class="row continue-section">
          <div class="row">
            <h3>Direkt weiter lernen</h3>
          </div>
          <div class="row continue-quiz-topics mt-4">
            <div class="col-xs-12 col-sm-6 col-md-6  col-xxl-3" v-if="pending_quiz instanceof Object && pending_quiz.hasOwnProperty('id')">
              <div class="continue-quiz-box d-flex flex-row justify-content-center align-items-center">
                <h4>Quiz fortsetzen</h4>
                &nbsp;<template v-if="pending_quiz.collection_id !== null">
                  <router-link
                      :to="{ name: 'topics.quiz', params: { topic: pending_quiz.topic_id, quiz: 'collection', collection: pending_quiz.collection_id } }"
                      type="button"
                      class="d-flex justify-content-center align-items-center"
                  >
                      <i class="fa fa-arrow-right"></i>
                  </router-link>
              </template>
                  <template v-else>
                      <router-link
                          :to="{ name: 'topics.quiz', params: { topic: pending_quiz.topic_id, quiz: pending_quiz.id } }"
                          type="button"
                          class="d-flex justify-content-center align-items-center"
                      >
                          <i class="fa fa-arrow-right"></i>
                      </router-link>
                  </template>

              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6  col-xxl-3">
              <div class="continue-quiz-box d-flex flex-row justify-content-center align-items-center">
                <h4>Zufälliges Quiz</h4>
                &nbsp;
                <router-link
                  :to="{ name: 'topics.quiz' }"
                  type="button"
                  class="d-flex justify-content-center align-items-center"
                >
                  <i class="fa fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
              <div class="col-xs-12 col-sm-6 col-md-6  col-xxl-3" v-if="saved_questions > 0">
                  <div class="continue-quiz-box d-flex flex-row justify-content-center align-items-center">
                      <h4>Wichtige Fragen</h4>
                      &nbsp;
                      <router-link
                          :to="{ name: 'SavedQuestions' }"
                          type="button"
                          class="d-flex justify-content-center align-items-center"
                      >
                          <i class="fa fa-arrow-right"></i>
                      </router-link>
                  </div>
              </div>
          </div>
        </div>

        <div class="row selected-topics-section">
          <div class="row mt-4">
            <h3>Dieses Semester wichtig</h3>
          </div>
          <div class="row mt-4">

            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-2" v-for="topic in selected_topics">
              <router-link
                class="button"
                :to="'topics#' + topic.id"
              >
                <topic-component-minimal
                  :topic-name="topic.wording"
                />
              </router-link>
            </div>
            <div
              class="col d-flex justify-content-center align-items-center load-more-arrow"
            >
              <router-link
                :to="{ name: 'topics.pinned' }"
              >
                Alle gemerkten Fächer &nbsp;
                <i class="fa fa-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-mobile d-lg-none">
      <div class="container">
        <section class="stats-section">
          <div class="row mt-5">
            <div class="col-12 offset-sm-2 col-sm-8">
              <streak-box-component :completed-days="streakDays" />
            </div>
              <div class="col-12 offset-sm-2 col-sm-8">
                  <br/>

                  <div class="row">
                      <div class="col-6 col-lg-6">
                          <stats-box :current-value="percentileOverallPerformance" stat-label="Insgesamt" />
                      </div>
                      <div class="col-6 col-lg-6">
                          <stats-box :current-value="percentileSelectedTopicsPerformance" stat-label="Thema" />
                      </div>
                  </div>
              </div>
              <div class="col-12 offset-sm-2 col-sm-8" v-if="user !== null && user.show_premium_ad === true">
                  <br/>
                  <premium-ad-component/>
              </div>
          </div>
        </section>
        <section class="continue-section">
<!--            <div class="row mt-3">
                <div class="offset-sm-2 col">
                <voucher-component/>
                </div>
            </div>-->

          <div class="row mt-5">
            <div class="offset-sm-2 col">
              <h3>Direkt weiter lernen</h3>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12 offset-sm-2 col-sm-8">
              <div class="continue-quiz-box d-flex flex-row justify-content-center align-items-center">
                <h4>Zufälliges Quiz</h4>
                &nbsp;
                <router-link
                  :to="{ name: 'topics.quiz' }"
                  type="button"
                  class="d-flex justify-content-center align-items-center"
                >
                  <i class="fa fa-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
            <div class="row mt-4" v-if="saved_questions > 0">
                <div class="col-12 offset-sm-2 col-sm-8">
                    <div class="continue-quiz-box d-flex flex-row justify-content-center align-items-center">
                        <h4>Wichtige Fragen</h4>
                        &nbsp;
                        <router-link
                            :to="{ name: 'SavedQuestions' }"
                            type="button"
                            class="d-flex justify-content-center align-items-center"
                        >
                            <i class="fa fa-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="selected-topics-section mb-5">
          <div class="row mt-5">
            <div class="col offset-sm-2">
              <h3>Dieses Semester wichtig</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-6 offset-sm-2 col-sm-4 my-4" v-for="topic in selected_topics">
              <router-link
                class="button"
                :to="'topics#' + topic.id"
              >
                <topic-component-minimal
                  :topic-name="topic.wording"
                />
              </router-link>
            </div>
          </div>
          <div class="row mt-2 mb-5">
            <div
              class="col d-flex justify-content-center align-items-center load-more-arrow"
            >
              <router-link
                :to="{ name: 'topics.pinned' }"
              >
                Alle gemerkten Fächer &nbsp;
                <i class="fa fa-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TopicComponent from '@client/components/TopicComponent.vue';
import TopicComponentMinimal from '@client/components/TopicComponentMinimal.vue';
import StreakBoxComponent from '@client/components/StreakBoxComponent.vue'
import StatsBox from '@client/components/StatsBox.vue'
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import ProgressComponent from "@client/components/ProgressComponent.vue";
import PremiumAdComponent from "@client/components/PremiumAdComponent.vue";
import VoucherComponent from "@client/components/VoucherComponent.vue";

export default{
  data() {
    return {
      streakDays: [],
        overallPerformance: {
            total_questions: 0,
            total_score: 0,
        },
        selectedTopicsPerformance: {
            total_questions: 0,
            total_score: 0,
        },
      loading: false,
      selected_topics: [],
      overall_performance: {},
      selected_topics_performance: {},
      pending_quiz: {},
      saved_questions: 0
    }
  },
  computed: {
    ...mapState(['isAuthenticated', 'user']),
      percentileOverallPerformance() {
          if (!this.overall_performance.total_questions) {
              return 0;
          }

          return Math.floor((parseInt(this.overall_performance.total_score) / parseInt(this.overall_performance.total_questions)) * 100);
      },
      percentileSelectedTopicsPerformance() {
          if (!this.selected_topics_performance.total_questions) {
            return 0
          }

          return Math.floor((parseInt(this.selected_topics_performance.total_score) / parseInt(this.selected_topics_performance.total_questions)) * 100);
      }
  },
  mounted() {
    // Load user profile data
    this.loadData()
  },
  beforeMount() {

  },
  methods: {
    loadData() {
      this.loading = true

      axios.get(`${API_URL}stats/dashboard`, {'headers': authHeader()})
      .then(response => {
        if (response.status === 200) {
          this.selected_topics = response.data.selected_topics.slice(0, 4);
          this.streakDays = response.data.weekly_streak;
          this.overall_performance = response.data.overall_performance;
          this.selected_topics_performance = response.data.selected_topics_performance;
          this.pending_quiz = response.data.pending_quiz;
          this.saved_questions = response.data.saved_questions;
          this.$emit('updateStats', {
            'streak': this.streakDays,
            'overall_performance': this.overall_performance,
            'selected_topics_performance': this.selected_topics_performance,
          });
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
  },
  components: {
    ProgressComponent,
    TopicComponent,
    TopicComponentMinimal,
    StreakBoxComponent,
    StatsBox,
    PremiumAdComponent,
    VoucherComponent
  }
}
</script>

<style scoped>

</style>
