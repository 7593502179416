<script>
export default {
    name: "image-zoom-component",
    props: {
        imageSrc: String,
        imageAlt: String
    }
}
</script>

<template>
    <i class="fa-regular fa-circle-xmark text-primary image-zoom-toggle" style="display: none" @click="disableZoom"></i>
        <div class="zoom" @mousemove="zoomImage" @mouseleave="mouseLeftZoom" @touchmove="zoomImage" @touchstart="zoomStarted" @click="toggleZoom" :style="{ backgroundImage: `url(${imageSrc})` }">
            <img class="img-zoom" :src="imageSrc" :alt="imageAlt"/>
        </div>
</template>

<style scoped>

</style>
