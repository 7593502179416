<template>
  <div class="form-field">
    <label v-if="showLabel" for="">{{label}}</label>
    <div
      class="select-box"
      :tabindex="tabindex"
      @blur="open = false"
    >
      <div
        class="selected"
        :class="{open: open}"
        @click="open = !open"
      >
        <template v-if="selected">
          {{selected.text}}
        </template>
        <template v-else>
          --- {{label}} ---
        </template>
      </div>
      <div
        class="options"
        :class="{hidden: !open}"
      >
        <div
          class="option"
          :class="{active: selected == option}"
          v-for="option of options"
          :key="option.value"
          @click="selected=option; open=false; $emit('update:modelValue', option.value)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "select-component",
  props: {
    options: {
      type: Array,
      required: true,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      type:[String, Number],
      required: false,
      default: "",
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      selected: null,
      open: false,
    }
  },
  methods : {
      organizeSelectData() {
          for (var key in this.options) {
              if (this.options[key].value === this.value) {
                  this.selected = this.options[key];
              }
          }
      }
  },
  mounted() {
   this.organizeSelectData();
  }
}
</script>
