<template>
  <div class="question-container image-responsive">
    <div v-if="Object.entries(question).length > 0" class="question">
      <div class="row">
        <div class="col question-wording" v-html="decodeHtml(question.wording)"></div>
      </div>
      <div class="row">
        <div
          class="col-12 col-lg-8 question-answers"
        >
          <form @submit.prevent>
            <div
              class="d-flex"
              v-for="answer in question.answers"
              :key="answer.id"
            >
                <i class="fa-solid fa-arrow-right"></i>

              <label
                class=""
                :for="`${question.id}-${answer.id}`"
                v-html="decodeHtml(answer.wording)"
              >
              </label>
            </div>
          </form>
        </div>
        <Transition name="slide-fade">
          <div class="col-12 col-lg-4 question-explanation image-responsive" v-if="question.explanation.trim().length > 0">
            <div class="d-flex flex-column">
                <div class="card card-correct">
                    <div class="card-header">
                        <span>Erklärung</span>
                    </div>
                    <div  class="card-body" v-html="decodeHtml(question.explanation)"></div>
                </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressComponent from '@client/components/ProgressComponent.vue';
import QuestionExplanationComponent from '@client/components/QuestionExplanationComponent.vue';

import "katex/dist/katex.css";
import katex from "katex";
window.katex = katex;

import {mapState} from "vuex";

export default {
  name: 'saved-question-component',
  props: {
    question: {
      type: Object,
      default: {}
    },
    questionNumber: {
      type: Number,
      default: 1
    },
    totalQuestions: {
      type: Number,
      default: 1
    },
    quizOrExamMode: {
      type: Boolean,
      default: true
    },
    examMode: {
      type: Boolean,
      default: false,
    },
    quizId: {
      type:Number,
      default: 0,
    },
    overviewMode: {
      type: Boolean,
      default: false
    },
    overviewSelectedAnswers: {
      type: Array,
      default: [],
    },
    previousQuestionMode: {
      type: Boolean,
      default: false,
    },
    userQuestion: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedAnswers: [],
      evaluationMode: false,
      evaluationResult: true,
    }
  },
  mounted() {

  },
    methods: {

  },
  watch: {
    overviewMode: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // Set evaluation mode
          this.evaluationMode = true
          //this.evaluationResult = parseInt(this.question.pivot.status) == 2 ? true : false;
        }
      }
    },
    overviewSelectedAnswers: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && this.overviewMode) {
          this.selectedAnswers = Array.from(newVal)
        }
      }
    },
    question: {
      immediate: true,
      handler(val) {
        if (val !== null && this.previousQuestionMode) {
          this.evaluationMode = true
          //this.evaluationResult = parseInt(this.question.pivot.status) == 2? true : false;

          this.selectedAnswers = Array.from(this.question.answers)
        }
      }
    }
  },
  computed: {
    ...mapState(['isAuthenticated', 'user']),
  },
  components: {
    ProgressComponent,
    QuestionExplanationComponent,
  }
}
</script>

<style>
  .question-answers .fa-arrow-right {
      color: #891E35;
      margin-right: 10px;
  }
</style>
