<template>
    <div class="container lab-container">
        <div class="row image-responsive">
            <image-component/>
            <div class="col-lg-12">
                <div class="row" v-if="protection.name !== undefined && protection.name !== null && protection.name !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Name</h6>
                    </div>
                    <div><p>{{protection.name}}</p></div>
                    <br/><br/>
                </div>

                <div class="row" v-if="protection.description !== undefined && protection.description !== null && protection.description !== ''">
                    <div class="col">
                        <h6 class="mb-0 text-sm sub-heading">Beschreibung</h6>
                    </div>
                    <component :is="description"></component>
<!--                    <div @click="handleImageClick" v-html="protection.description"></div>-->
                    <br/><br/>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import ImageComponent from '@client/components/ImageComponent.vue';

export default {
    name: "Protection",
    data() {
        return {
            protection: {
                id: 0,
                name: '',
                description: '',
            },
        }
    },
    mounted() {
        this.loadProtection();
    },
    methods: {
        clearData() {
            this.protection.id = 0;
            this.protection.name = "";
            this.protection.description = "";
        },
        loadProtection() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}protections/get/${this.$route.params.id}`, {
                headers: headers
            })
                .then(response => {
                    this.clearData();
                    if (response.status === 200) {
                        this.setProtectionData(response.data);
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        },
        setProtectionData(data) {
            this.protection.id = data.id;
            this.protection.name = data.name;
            this.protection.description = data.description;
        },
        handleImageClick(e) {
            this.handleImageClicks(e);
        }
    },
    computed: {
        description() {
            return {
                template: '<div>'+ this.addImageZoom(this.protection.description) + '</div>'
            }
        }
    },
    components: {
        ImageComponent
    }
}
</script>

<style scoped>

</style>
