<template>
    <div class="container-fluid">
        <br/><br/>
        <div class="row">
                <h3 class="text-center">Passwort zurücksetzen</h3>
                <div class="col-12 col-sm-12 offset-md-4 col-md-4 offset-lg-4 col-lg-4">
                    <div class="form-field">
                        <label for="">Neues Passwort</label>
                    </div>

                    <div class="input-group mb-3 form-field">
                        <input class="form-control" id="password"
                               placeholder="Neues Passwort eingeben"
                               type="password" v-model="input.password" @input="checkPassword" style="border-right: unset;">
                        <span v-on:click="togglePassword" id="show-password" class="input-group-text" style="border-left: unset; background-color: white; cursor: pointer;"><i style="color: #CB4099" class="far fa-eye-slash"></i></span>
                    </div>

                    <br/>

                    <div class="form-field">
                        <label for="">Neues Passwort wiederholen</label>
                    </div>

                    <div class="input-group mb-3 form-field">
                        <input class="form-control" id="confirmPassword"
                               placeholder="Neues Passwort wiederholen"
                               type="password" v-model="input.confirmPassword" style="border-right: unset;">
                        <span v-on:click="togglePassword" id="show-confirm-password" class="input-group-text" style="border-left: unset; background-color: white; cursor: pointer;"><i style="color: #CB4099" class="far fa-eye-slash"></i></span>
                    </div>

                    <br/>

                    <div class="text-center">
                        <button class="btn btn-primary" v-bind:class="{ disabled: formSubmitted}"
                                v-on:click="resetPassword">
                            Absenden
                        </button>
                    </div>

                </div>
        </div>
    </div>
</template>

<script>

import InputComponent from '@client/components/InputComponent.vue';
import {API_URL} from "@/conf.js";
import router from "../router";

export default {
    name: "ResetPassword",
    data() {
        return {
            input: {
                password: "",
                confirmPassword: "",
            },
            validation: {
                password: {
                    password_length: 0,
                    contains_eight_characters: false,
                    contains_number: false,
                    contains_uppercase: false,
                    contains_special_character: false,
                    valid_password: false
                }
            },
            formSubmitted: false
        }
    },
    mounted() {
        const passwordInput = document.getElementById("password");
        passwordInput.addEventListener('focusin', this.passwordFocusListener); //Non-IE
        passwordInput.onfocusin = this.passwordFocusListener; //IE
        passwordInput.addEventListener('focusout', this.passwordFocusListener); //Non-IE
        passwordInput.focusout = this.passwordFocusListener; //IE

        const passwordConfirmInput = document.getElementById("confirmPassword");
        passwordConfirmInput.addEventListener('focusin', this.confirmPasswordFocusListener); //Non-IE
        passwordConfirmInput.onfocusin = this.confirmPasswordFocusListener; //IE
        passwordConfirmInput.addEventListener('focusout', this.confirmPasswordFocusListener); //Non-IE
        passwordConfirmInput.focusout = this.confirmPasswordFocusListener; //IE
    },
    methods: {
        resetPassword() {

            if (this.input.password !== this.input.confirmPassword) {
                this.$swal("Error", "Die Passwörter stimmen nicht überein!", "error");
                return;
            }

            if (!this.validation.password.valid_password) {
                this.$swal("Info", "Das Passwort ist ungültig.", "info");
                return;
            }

            this.formSubmitted = true;

            axios.post(`${API_URL}reset_password`, {
                password: this.input.password,
                code: this.$route.params.code
            })
                .then(response => {
                    if (response.status === 200) {
                        this.$swal("Geschafft!", response.data.message, "success")
                            .then((result) => {
                                if (result.isConfirmed) {
                                    router.replace({path: '/login'})
                                }
                            });
                        this.input.password = "";
                        this.input.confirmPassword = "";
                    } else {
                        this.$swal("Info", response.data.message, "info");
                    }

                    this.formSubmitted = false;
                }).catch(error => {
                this.$swal("Error", "Ein Fehler ist aufgetreten, bitte versuche es später noch einmal!", "error");
                this.formSubmitted = false;
            });
        },
        checkPassword() {
            this.validation.password.password_length = this.input.password.length;
            const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            this.validation.password.contains_eight_characters = this.validation.password.password_length > 8;

            this.validation.password.contains_number = /\d/.test(this.input.password);
            this.validation.password.contains_uppercase = /[A-Z]/.test(this.input.password);
            this.validation.password.contains_special_character = format.test(this.input.password);

            this.validation.password.valid_password = this.validation.password.contains_eight_characters === true &&
                this.validation.password.contains_special_character === true &&
                this.validation.password.contains_uppercase === true &&
                this.validation.password.contains_number === true;
        },
        togglePassword(event) {
            var inputField = event.target.offsetParent.firstChild;
            var spanElement = event.target.offsetParent.lastChild;

            if (inputField.type === "password") {
                inputField.type = "text";
            } else {
                inputField.type = "password";
            }

            if (event.target.classList.contains("fa-eye-slash")) {
                event.target.classList.remove("fa-eye-slash");
                event.target.classList.add("fa-eye");
            } else {
                event.target.classList.remove("fa-eye");
                event.target.classList.add("fa-eye-slash");
            }

            spanElement.style.borderColor = spanElement.style.borderColor === 'rgb(203, 64, 153)' ? '#D6DBDE' : '#CB4099';

            if (event.target.value !== "") {
                spanElement.style.borderColor = '#CB4099';
            }
        },
        passwordFocusListener(event) {
            var showPassword = document.getElementById("show-password");
            showPassword.style.borderColor = showPassword.style.borderColor === 'rgb(203, 64, 153)' ? '#D6DBDE' : '#CB4099';

            if (event.target.value !== "") {
                showPassword.style.borderColor = '#CB4099';
            }
        },
        confirmPasswordFocusListener(event) {
            var showPassword = document.getElementById("show-confirm-password");
            showPassword.style.borderColor = showPassword.style.borderColor === 'rgb(203, 64, 153)' ? '#D6DBDE' : '#CB4099';

            if (event.target.value !== "") {
                showPassword.style.borderColor = '#CB4099';
            }
        }
    },
    components: {
        InputComponent
    }
}
</script>

<style scoped>

</style>
