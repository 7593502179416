<script>
import {defineComponent} from 'vue'
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";
import QuestionsCollectionsComponent from '@client/components/QuestionsCollectionsComponent.vue';

export default defineComponent({
    name: "Collections",
    data() {
        return {
            collections: []
        }
    },
    mounted() {
        this.loadCollections();
    },
    methods: {
        loadCollections() {
            const headers = authHeader();
            headers['content-type'] = 'application/json'
            axios.get(`${API_URL}collections/get_by_topic/${this.$route.params.topic}`, {
                headers: headers
            })
                .then(response => {
                    if (response.status === 200) {
                        this.collections = response.data;
                    }  else {
                        this.$swal("Error", response.data.message, "error");
                    }
                })
                .catch(error => {

                    this.$swal("Error", "An Error Occurred. Try again later!", "error");
                });
        }
    },
    components: {
        QuestionsCollectionsComponent,
    }
})
</script>

<template>
    <div class="container pt-3 search-container">
        <div class="row">
            <div class="col-12 col-sm-12 offset-md-3 col-md-6">
                <h1>Übungsklausur</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-xl-4" v-for="collection in collections">
                <!-- <router-link class="search-link" v-for="collection in collections"
                             :key="'experiment'+ collection.id" :to="{ name: 'topics.quiz', params: { topic: collection.topic_id, quiz: 'collection', collection: collection.id } }">{{ collection.title }}
                </router-link> -->
                <questions-collections-component 
                    :topic-id="collection.topic_id"
                    :collection-id="collection.id"
                    :title="collection.title"
                    :total-questions="collection.questions.length"
                    :answered="collection.user_performance.attempted"
                    :correct="collection.user_performance.correct"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
