<template>
  <div class="quiz-overview-component">
    <template v-if="overview.hasOwnProperty('quiz') && overview.hasOwnProperty('questions')">
      <div class="row mt-3">
        <div class="col">
          <p>Dein Forschritt</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6 mb-5">
          <progress-component
            :min-value="0"
            :max-value="100"
            :current-value="progressValue"
            :progress-label="progressLabel"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn-primary" type="button" @click="$emit('restartQuiz')">
            Quiz Wiederholen
          </button>
        </div>
      </div>
      <div class="row my-4">
        <div class="col">
          <h1>Deine Antworten</h1>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="accordion" id="overview-accordion">
            <div class="accordion-item" v-for="question in overview.questions" :key="`overview-${question.id}`">
              <h2 
                class="accordion-header d-flex flex-row align-items-center"
                :class="{'success-header': parseInt(question.pivot.status) == 2}"
                :id="`accordion-header-${question.id}`"
              >
                <div 
                  class="header-icon"
                  :class="{'success-icon': parseInt(question.pivot.status) == 2}"
                >
                    <i class="fa fa-check"></i>
                  </div>
                <button 
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse" 
                  :data-bs-target="`#accordion-body-${question.id}`" 
                  aria-expanded="true" 
                  :aria-controls="`accordion-body-${question.id}`"
                >
                  <p v-html="truncateHtml(decodeHtml(question.wording), 50)"></p>
                </button>
              </h2>
              <div 
                :id="`accordion-body-${question.id}`" 
                class="accordion-collapse collapse"
                :aria-labelledby="`accordion-header-${question.id}`"
              >
                <div class="accordion-body">
                  <question-component
                    :question="question"
                    :quiz-or-exam-mode="false"
                    :overview-mode="true"
                    :overview-selected-answers="question.selected_answers"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row mt-3">
        <div class="col">
          <preloader />
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import ProgressComponent from '@client/components/ProgressComponent.vue';
import Preloader from '@client/components/Preloader.vue';
import QuestionComponent from '@client/components/QuestionComponent.vue';

export default {
  name: 'quiz-overview-component',
  props: {
    overview: {
      type:Object,
      default: {},
    }
  },
  data() {
    return {
      // overview: {}
    }
  },
  methods: {
    
  },
  watch: {
    
  },
  computed: {
    progressValue() {
      if (!this.overview.hasOwnProperty('quiz') || !this.overview.hasOwnProperty('questions'))
        return 0;
      
      return (this.overview.quiz.score / this.overview.questions.length) * 100;
    },
    progressLabel() {
      if (!this.overview.hasOwnProperty('quiz') || !this.overview.hasOwnProperty('questions'))
        return '';
      
      return `${this.overview.quiz.score} von ${this.overview.questions.length} Fragen richtig`
    }
  },
  components: {
    ProgressComponent,
    Preloader,
    QuestionComponent,
  }
}

</script>