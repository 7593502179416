<template>
  <div 
    class="card"
    :class="{'card-correct': correct, 'card-wrong': !correct}"
  >
    <div class="card-header">
      <span v-if="correct">Richtig</span>
      <span v-else>Falsch</span>
    </div>
    <div v-if="explanation.trim().length > 0" class="card-body" v-html="decodeHtml(explanation)"></div>
  </div>
</template>

<script>
  export default {
    props: {
      explanation: {
        type: String,
        required: true,
      },
      correct: {
        type: Boolean,
        default: false,
      }
    }
  }
</script>