import { store } from "@client/store/index.js";
import { authStore } from "@/store";

const CUSTOM_DIMENSIONS = {
    visits: 1,
    topic: 2,
}

export default function useTracker() {

    async function trackPageView(title, url = "") {

        // Track the page view
        // First set the correct user id
        if (authStore.state.isAuthenticated && authStore.state.user !==  null) {
            let email = authStore.state.user.email;

            if (authStore.state.user.package.is_default == 0 && authStore.state.user.package.name == 'PharmaNavi Premium')
                window._paq.push(['setCustomDimension', CUSTOM_DIMENSIONS.visits, 'Premium']);
            else 
                window._paq.push(['setCustomDimension', CUSTOM_DIMENSIONS.visits, 'Member']);

            
            window._paq.push(['setUserId', email]);
        } else {
            window._paq.push(['setCustomDimension', CUSTOM_DIMENSIONS.visits, 'Visitor']);
        }
        // Set custom url where applicable
        if (url.length > 0) window._paq.push(['setCustomUrl', window.location.origin + url])


        // Track the page view
        window._paq.push(['trackPageView', title])
        window._paq.push(['deleteCustomDimension', CUSTOM_DIMENSIONS.visits])

    }

    // We will use the topic name as the custom dimension type.
    async function trackTopicQuestion(action = 'Attempt', subjectName= "", topicName = "") { 
        if (topicName.trim().length <= 0 || subjectName.trim().length <= 0) return;

        window._paq.push(['setCustomDimension', CUSTOM_DIMENSIONS.topic, subjectName])
        if (authStore.state.isAuthenticated && authStore.state.user !== null) {
            let email = authStore.state.user.email
            window._paq.push(['setUserId', email]);
        }
        window._paq.push(['trackEvent', 'Question', action, topicName])
        window._paq.push(['deleteCustomDimension', CUSTOM_DIMENSIONS.topic])
    }

    async function trackTopicSummary(subjectName = "", topicName = "") {
        if (topicName.trim().length <= 0 || subjectName.trim().length <= 0) return;

        window._paq.push(['setCustomDimension', CUSTOM_DIMENSIONS.topic, subjectName])
        if (authStore.state.isAuthenticated && authStore.state.user !== null) {
            let email = authStore.state.user.email
            window._paq.push(['setUserId', email]);
        }
        window._paq.push(['trackEvent', 'Summary', 'View', topicName])
        window._paq.push(['deleteCustomDimension', CUSTOM_DIMENSIONS.topic])
    }

    async function setMatomoUserId(email = "") {
        if (email !== undefined && email.trim().length > 0) {
            window._paq.push(['setUserId', email]);
        }
    }

    async function resetTrackedUserId() {
        window._paq.push(['resetUserId']);
        window._paq.push(['appendToTrackingUrl', 'new_visit=1']);
        window._paq.push(['trackPageView']);
        window._paq.push(['appendToTrackingUrl', '']);
    }

    return {
        trackPageView,
        resetTrackedUserId,
        setMatomoUserId,
        trackTopicQuestion,
        trackTopicSummary,
    }
}