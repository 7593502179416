<template>
    <div>
        <preloader v-if="pageLoaded === false && isLoading === false" />
        <template v-else>
            <template v-if="isAuthenticated">
                <div class="desktop-header-container d-flex flex-row">
                    <div v-if="showStats" class="stats-section d-none d-lg-none d-xl-block">
                        <h3>Deine Erfolge</h3>
                        <div class="stats-boxes d-flex flex-row justify-content-between">
                            <streak-box-component :completed-days="streakDays" />
                            <stats-box :current-value="percentileOverallPerformance" stat-label="Insgesamt" />
                            <stats-box :current-value="percentileSelectedTopicsPerformance" stat-label="Thema" />
                            <premium-ad-component v-if="user !== null && user.show_premium_ad === true"/>
                        </div>
                    </div>
                    <div v-else class="navigation-header">
                        <h2 v-if="this.$route.meta.navigation !== undefined"><i class="fa-solid fa-arrow-left" @click="handleNavigateBack"></i> {{ this.$route.meta.navigation }}</h2>
                        <h4 v-if="subHeading != null">{{subHeading}}</h4>
                    </div>
                    <div class="logo-section" :class="this.$route.meta.navigation !== undefined ? 'd-none d-sm-none d-md-block' : ''">
                        <router-link :to="{ name: 'dashboard' }">
                            <img src="https://assets.pharmanavi.de/assets/uploads/Logo_2023/final_logo_no_background.png" alt="Logo">
                        </router-link>
                    </div>
                </div>
                <div class="mobile-header-container d-flex flex-row justify-content-between">
                    <div>
                        <i class="fa-solid fa-bars menu-icon" v-on:click="toggleMenu"></i>
                    </div>
                    <div class="d-flex flex-row align-items-center">
                        <div class="img-container mb-4">
                            <img
                                class="img-circle"
                                :src="(profile.avatar !== null && profile.avatar.trim().length > 0 && profile.avatar !== '0') ? `https://assets.pharmanavi.de/assets/uploads/avatars/${profile.avatar}` : 'https://assets.pharmanavi.de/assets/uploads/avatars/chimiste.jpg'"
                                alt="Profile"
                            >
                        </div>
                        <p class="greeting-text "><span class="nav-username">Hey <span>{{ (profile.username !== null && profile.username.trim().length) > 0 ? profile.username : "User" }}</span></span> &nbsp;Schön, dass du da bist!</p>
                    </div>
                    <div class="mobile-logo">
                        <router-link :to="{ name: 'dashboard' }">
                            <img src="https://assets.pharmanavi.de/assets/uploads/Logo_2023/favicon-PharmaNavi_2023.png" alt="Logo">
                        </router-link>
                    </div>
                </div>
                <div class="body-content">
                    <side-nav-component
                        @load-main-topics="loadMainTopics"
                    />
                    <div
                        class="view-content mb-5 mb-lg-0"
                        id="view-content"
                        :class="{'dashboard-display': showStats}"
                    >
                        <router-view  v-slot="{ Component }"
                            @navigation-sub-heading="getSubHeading"
                            @update-stats="updateStats"
                        >
                            <component ref="view" :is="Component" />
                        </router-view>
                    </div>
                    <mobile-navigation />
                </div>
            </template>
            <template v-else>
                <router-view/>
            </template>
        </template>

    </div>
</template>

<script>

import {mapState} from "vuex";
import SideNavComponent from "@client/components/SideNavComponent.vue";
import StatsBox from '@client/components/StatsBox.vue'
import StreakBoxComponent from '@client/components/StreakBoxComponent.vue'
import MobileNavigation from "@client/components/MobileNavigation.vue";
import Preloader from "@client/components/Preloader.vue";
import PremiumAdComponent from "@client/components/PremiumAdComponent.vue";
import {PLATFORM} from "../utils";
import router from "./router";
import * as Sentry from "@sentry/vue";

import useTracker from "@client/composables/useTracker";
import _ from 'lodash'
const { trackPageView } = useTracker();

export default {
    setup() {
        const debouncedTrackPageView = _.debounce((title, fullPath) => trackPageView(title, fullPath), 3000);
        return {
            pageLoaded: false,
            debouncedTrackPageView,
        }
    },
    data() {
        return {
            streakDays: [],
            overallPerformance: {
                total_questions: 0,
                total_score: 0,
            },
            selectedTopicsPerformance: {
                total_questions: 0,
                total_score: 0,
            },
            subHeading: null
        }
    },
    mounted() {
        this.pageLoaded = true
    },
    computed: {
        ...mapState(['isLoading', 'isAuthenticated', 'user']),
        showStats() {
            return this.$route.name === 'dashboard'
        },
        percentileOverallPerformance() {
            if (!this.overallPerformance.total_questions) {
                return 0;
            }

            return Math.floor((parseInt(this.overallPerformance.total_score) / parseInt(this.overallPerformance.total_questions)) * 100);
        },
        percentileSelectedTopicsPerformance() {
            if (!this.selectedTopicsPerformance.total_questions) {
                return 0;
            }

            return Math.floor((parseInt(this.selectedTopicsPerformance.total_score) / parseInt(this.selectedTopicsPerformance.total_questions)) * 100);
        },

        profile() {
          return this.$store.state.user
        }

    },
    beforeMount() {
        if (localStorage.getItem("email") !== undefined && localStorage.getItem("email") !== null) {
            this.getUserData(localStorage.getItem("email")).then((response) => {
                if (response.status === 200) {
                    this.$store.dispatch("registerUserDevice", {
                        user_id: response.data.user.id,
                        user_agent: navigator.userAgent,
                        platform: PLATFORM(navigator.userAgent),
                        device_token: "Not Token",
                        token: localStorage.getItem("token")
                    });

                    this.pageLoaded = true

                    if (window.location.pathname === "" || window.location.pathname === "/") {
                        router.replace({path: '/dashboard'})
                    }

                } else {
                    this.pageLoaded = true
                }
            }).catch(error => {
                this.pageLoaded = true
            });
        } else {
            this.pageLoaded = true
        }

    },
    methods: {
        getUserData(email) {
            return this.$store.dispatch("getUserData", email);
        },
        hasRouteHistory () { return window.history.length > 2 },
        handleNavigateBack() {
            if (this.$route.name === "topics") {
                var hierarchy = this.$refs.view.getHierarchy();

                if (hierarchy.length > 1) {
                    var previous = hierarchy[hierarchy.length - 2]
                    this.$refs.view.setHierarchy(previous.order);
                } else if (hierarchy.length === 1) {
                    this.$refs.view.setHierarchy(false);
                } else {
                    this.navigateBack()
                }
            } else {
               this.navigateBack()
            }
        },
        navigateBack() {
            if (this.hasRouteHistory()) {
                this.$router.go(-1);
            } else {
                this.$router.push('/dashboard')
            }
        },
        loadMainTopics() {
            this.$refs.view.setHierarchy(false);
        },
        getSubHeading(subHeading) {
            this.subHeading = subHeading;
        },
        updateStats(stats) {
            this.streakDays = stats.streak;
            this.overallPerformance = stats.overall_performance;
            this.selectedTopicsPerformance = stats.selected_topics_performance;
        },
        toggleMenu() {
            const sidebar = document.getElementById('sidebar');
            sidebar.style.display = sidebar.style.display === 'unset' ? 'none' : 'unset';

            const viewContent = document.getElementById('view-content');
            viewContent.style.display = sidebar.style.display === 'unset' ? 'none' : 'unset';
        }
    },
    watch: {
        $route(to, from) {
            this.subHeading = null;

            const sidebar = document.getElementById('sidebar');
            if (sidebar !== null && sidebar.style.display === 'unset') {
                sidebar.style.display = '';
            }

            const viewContent = document.getElementById('view-content');
            if (viewContent !== null && viewContent.style.display === 'none') {
                viewContent.style.display = 'unset';
            }

            this.debouncedTrackPageView(to.meta.title, to.fullPath);
        }
    },
    components: {
        SideNavComponent,
        StatsBox,
        StreakBoxComponent,
        MobileNavigation,
        Preloader,
        PremiumAdComponent
    },
}
</script>
<style>
#main-preloader-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

#main-preloader {
    display: block;
    margin: auto;
}
</style>
