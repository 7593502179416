<template>
  <div class="question-container image-responsive">
    <div class="row">
      <div class="col-12 col-md-6 mb-5" v-if="quizOrExamMode">
        <progress-component
          :min-value="0"
          :max-value="100"
          :current-value="questionNumberPercentage"
        />
      </div>
    </div>
    <div v-if="Object.entries(question).length > 0" class="question">
      <div class="row">
        <div class="col question-wording" v-html="decodeHtml(question.wording)"></div>
      </div>
      <div class="row">
        <div
          class="col-12 question-answers"
          :class="{'col-lg-8': evaluationMode}"
        >
          <form @submit.prevent>
            <div
              class="form-check answer-container"
              :class="{
                'evaluation-mode': evaluationMode,
                'wa': evaluationMode && !isCorrectAnswer(answer.id) && isSelectedAnswer(answer.id),
                'ra': evaluationMode && isCorrectAnswer(answer.id)
              }"
              v-for="answer in question.answers"
              :key="answer.id"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :id="`${question.id}-${answer.id}`"
                :value="answer.id"
                v-model="selectedAnswers"
              >
              <label
                class="form-check-label"
                :for="`${question.id}-${answer.id}`"
                v-html="decodeHtml(answer.wording)"
              >
              </label>
            </div>
          </form>
        </div>
        <Transition name="slide-fade">
          <div v-if="evaluationMode" class="col-12 col-lg-4 question-explanation image-responsive">
            <div class="d-flex flex-column">
              <question-explanation-component
                :explanation="question.explanation"
                :correct="evaluationResult"
              />
            </div>
          </div>
        </Transition>
      </div>
      <div class="row" v-if="!overviewMode">
        <div class="col">
          <div class="d-grid gap-5 d-flex justify-content-around justify-content-md-end mt-4">
            <button class="btn btn-secondary">
              Frage: {{question.id}}
            </button>
              <button class="btn btn-secondary" type="button" v-if="questionNumber > 1" @click.prevent="previousQuestion">
                  <i class="fa-solid fa-arrow-left"></i>
                  Zurück
              </button>
              <button v-if="evaluationMode && quizOrExamMode" class="btn btn-primary" type="button" @click.prevent="nextQuestion">
                  Fortsetzen
                  <i class="fa-solid fa-arrow-right"></i>
              </button>
              <button v-else class="btn btn-primary" type="button" @click.prevent="checkAnswer">
                  überprüfen
              </button>
          </div>
        </div>
          <div class="row">
              <div class="col text-center">
                  <i class="fa-regular fa-comment" v-on:click="showComments"></i>
                  <span style="padding: 5px"> </span>
                  <span class="bookmark">
                  <i @click.prevent="bookmarkQuestion" class="fa-bookmark" :class="userQuestion !== null ? 'fa-solid' : 'fa-regular'"></i>
                  </span>
              </div>
          </div>
          <div class="row" v-show="commentVisible">
              <div class="col">
                  <h1>Frage kommentieren</h1>
                  <textarea class="form-control comment-texteditor texteditor" name="comment"
                            id="comment"></textarea>
                  <br/>
                  <div class="text-right">
                      <button class="btn btn-primary" v-bind:class="{ disabled: !canSubmitComment}" type="button" @click.prevent="createComment">
                          Absenden
                      </button>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <div class="container-fluid" v-else>
      <div class="row">
        <div class="col">
          <div class="d-flex justify-content-center">
            <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import ProgressComponent from '@client/components/ProgressComponent.vue';
import QuestionExplanationComponent from '@client/components/QuestionExplanationComponent.vue';
import authHeader from "@/auth.header.js";
import {API_URL} from "@/conf.js";

import "katex/dist/katex.css";
import katex from "katex";
window.katex = katex;

import {textArea} from "@/textarea.js";
import {mapState} from "vuex";

export default {
  name: 'saved-question-live-quiz-component',
  props: {
    question: {
      type: Object,
      default: {}
    },
    questionNumber: {
      type: Number,
      default: 1
    },
    totalQuestions: {
      type: Number,
      default: 1
    },
    quizOrExamMode: {
      type: Boolean,
      default: true
    },
    examMode: {
      type: Boolean,
      default: true,
    },
    quizId: {
      type:Number,
      default: 0,
    },
    overviewMode: {
      type: Boolean,
      default: false
    },
    overviewSelectedAnswers: {
      type: Array,
      default: [],
    },
    previousQuestionMode: {
      type: Boolean,
      default: false,
    },
    userQuestion: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedAnswers: [],
      evaluationMode: false,
      evaluationResult: false,
      comments: [],
      commentVisible: false,
      comment: '',
      canSubmitComment: false
    }
  },
  mounted() {
        (($) => {
            textArea();

            $('#comment').on('summernote.change', () => {
                this.comment = $("#comment").summernote("code");
                this.canSubmitComment = this.comment !== '';
            });
        })(jQuery);
  },
    methods: {
    nextQuestion() {
      this.evaluationMode = false
      this.evaluationResult = false
      this.selectedAnswers = [];
      this.$emit('next-question')
    },
    previousQuestion() {
      this.evaluationMode = false
      this.evaluationResult = false
      this.selectedAnswers = [];
      this.$emit('previous-question')
    },
    checkAnswer() {
      const headers = authHeader();
      const input = {
        question_id: this.question.id,
        selected_answers: this.selectedAnswers
      };
      const instance = this

      const quiz_id = parseInt(this.quizId) > 0 ? `/${parseInt(this.quizId)}` : '';

      axios.post(`${API_URL}user_quiz/check${quiz_id}`, input, {
        headers: headers
      })
      .then(response => {
        if (response.status === 200) {
          if (!this.examMode) {
            this.evaluationMode = true
            this.evaluationResult = response.data.result
          }

          this.$emit('track-success-rate', this.evaluationResult === true ? 'Correct' : 'Wrong')
          if (this.examMode) {
            this.nextQuestion()
          }
        } else {
          // alert('Ein technischer Fehler ist aufgetreten, bitte versuche es erneut.');
          this.submitting = false;
          setTimeout(() => {
            instance.error_message = '';
          }, 10000);
        }
      })
      .catch(error => {
          console.log(error)
        // alert('Ein technischer Fehler ist aufgetreten, bitte versuche es erneut.');
        this.submitting = false;
        setTimeout(() => {
          instance.error_message = '';
        }, 10000);
      });
    },
    isSelectedAnswer(id) {
      return this.selectedAnswers.includes(id)
    },
    isCorrectAnswer(id) {
      const question_answers = this.question.questions_answers

      let answer = question_answers.find(item => item.answer_id === id);

      return answer.correct === 1
    },
    loadComments() {
        const headers = authHeader();

        axios.get(`${API_URL}`, {
            headers: headers
        }).then((response) => {

        }).catch(error => {
            this.$swal("Error", "Comment not created", "error");
        });
    },
    createComment() {

        if (this.comment === '') {
            return;
        }

        this.canSubmitComment = false;

        const headers = authHeader();

        axios.post(`${API_URL}comments/create`, {
            user_id: this.user.id,
            question_id: this.question.id,
            comment: this.comment
        },{
            headers: headers
        }).then((response) => {
            if (response.status === 200) {
                $('#comment').summernote("reset");
                this.comment = '';
                this.canSubmitComment = false;
                this.$swal("Success", "Kommentar gespeichert", "success");
            } else {
                this.canSubmitComment = true;
                this.$swal("Info", "Kommentar konnte nicht gespeichert werden", "info");
            }
        }).catch(error => {
            this.canSubmitComment = true;
            this.$swal("Error", "Kommentar konnte nicht gespeichert werden", "error");
        });
    },
    showComments() {
        this.commentVisible = !this.commentVisible;
    },
    bookmarkQuestion() {
        const headers = authHeader();

        axios.post(`${API_URL}question/bookmark`, {
            user_id: this.user.id,
            question_id: this.question.id
        },{
            headers: headers
        }).then((response) => {
            if (response.status === 200) {
                this.$emit('bookmark-change', true);
            }
        }).catch(error => {
        });
    }
  },
  watch: {
    overviewMode: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          // Set evaluation mode
          this.evaluationMode = true
          this.evaluationResult = parseInt(this.question.pivot.status) == 2 ? true : false;
        }
      }
    },
    overviewSelectedAnswers: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && this.overviewMode) {
          this.selectedAnswers = Array.from(newVal)
        }
      }
    },
    question: {
      immediate: true,
      handler(val) {
        if (val !== null && this.previousQuestionMode) {
          this.evaluationMode = true
          this.evaluationResult = parseInt(this.question.pivot.status) == 2? true : false;

          this.selectedAnswers = Array.from(this.question.selected_answers)
        }
      }
    }
  },
  computed: {
    ...mapState(['isAuthenticated', 'user']),
    questionNumberPercentage() {
      return (this.questionNumber / this.totalQuestions) * 100
    }
  },
  components: {
    ProgressComponent,
    QuestionExplanationComponent,
  }
}
</script>

<style>
  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>
