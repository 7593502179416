<template>
    <div>
        <TmpNavBar/>
        <div class="container" style="max-width: 1000px;">
            <iframe src="https://test.davmedien.de/wp-admin/admin-ajax.php?action=h5p_embed&id=4" width="958" height="836" frameborder="0" allowfullscreen="allowfullscreen" title="Memory Indikatoren"></iframe>
        </div>
    </div>
</template>

<script>
import "@/h5p-resizer.js"
import TmpNavBar from "@client/components/TmpNavBar";

export default {
    name: "Game",
    components: {
        TmpNavBar
    }
}
</script>

<style scoped>

</style>
