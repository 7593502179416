<template>
  <div class="container summary-container">
    <div class="row image-responsive mt-5" v-if="topic.hasOwnProperty('id') && topic.hasOwnProperty('summary') && topic.summary != null">
        <image-component/>
      <div class="col">
          <component :is="wording"></component>
<!--        <div class="summary-wording" v-html="decodeHtml(topic.summary.wording)" @click="handleImageClick"></div>-->
      </div>
    </div>
    <div class="row" v-else-if="topic.hasOwnProperty('id') && topic.hasOwnProperty('package_accessible_summary') && topic.package_accessible_summary == false">
      <div class="col-xs-12 col-sm-12 col-md-6 mt-5 offset-md-3 col-lg-4 offset-lg-4">
        <premium-restricted-content />
      </div>
    </div>
    <div class="row" v-else>
      <div class="col">
        <preloader />
      </div>
    </div>
  </div>
</template>
<script>
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import Preloader from "@client/components/Preloader.vue";
import useTracker from "@client/composables/useTracker";
import ImageComponent from '@client/components/ImageComponent.vue';
import PremiumRestrictedContent from '@client/components/PremiumRestrictedContent.vue';

export default {
  name: 'summary-component',
  setup() {
    const {trackTopicSummary } = useTracker();

    return {
      trackTopicSummary,
    };
  },
  data() {
    return {
      topicId: null,
      topic: {},
      subject: {},
    }
  },
  mounted() {
    if (this.$route.params.topic) {
      this.topicId = parseInt(this.$route.params.topic)
      this.loadSummary()
    }
  },
  methods: {
    loadSummary() {
      axios.get(`${API_URL}topic/${this.topicId}`, {'headers': authHeader()})
      .then(response => {
        console.log(response.data);
        if (response.status === 200) {
          this.topic = response.data.topic
          this.subject = response.data.subject

          // Track the summary view event.
          this.trackTopicSummary(this.subject.wording, this.topic.wording)
        }
      })
      .catch(error => {
        console.log(error)
      });
    },
      handleImageClick(e) {
        this.handleImageClicks(e);
      }
  },
    computed: {
        wording() {
            return {
                template: '<div>'+ this.addImageZoom(this.decodeHtml(this.topic.summary.wording)) + '</div>'
            }
        }
    },
  components: {
    Preloader,
    ImageComponent,
    PremiumRestrictedContent,
  }
}
</script>
