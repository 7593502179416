<template>
  <div id="main-preloader-div">
    <img alt="Pharmanavi Preloader" src="/images/preloader.gif" id="main-preloader"/>
  </div>
</template>

<script>
export default {
  name: 'preloader'
}
</script>